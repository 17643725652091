import styles from './DoubleArrowButtons.module.scss';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';

const DoubleArrowButtons = ({
  prevBtnHandler = () => {},
  nextBtnHandler = () => {},
  defaultActive = 'next',
  prevDisabled = false,
  nextDisabled = false,
}) => {
  const [activeBtn, setActiveBtn] = useState(defaultActive == 'next' ? 2 : 1);

  useEffect(() => {
    setActiveBtn((old) => (nextDisabled ? 1 : old));
  }, [nextDisabled]);

  useEffect(() => {
    setActiveBtn((old) => (prevDisabled ? 2 : old));
  }, [prevDisabled]);

  return (
    <div className={styles.buttonBorder}>
      <div className={styles.buttonContainer}>
        <IconButton
          className={`${styles.prevBtn} ${
            activeBtn == 1 && !prevDisabled ? styles.isActive : styles.inActive
          }`}
          disabled={prevDisabled}
          onClick={(e) => {
            setActiveBtn(1);
            prevBtnHandler(e);
          }}
        >
          <ArrowBack />
        </IconButton>

        <IconButton
          className={`${styles.nextBtn} ${
            activeBtn == 2 && !nextDisabled ? styles.isActive : styles.inActive
          }`}
          disabled={nextDisabled}
          onClick={(e) => {
            setActiveBtn(2);
            nextBtnHandler(e);
          }}
        >
          <ArrowForward />
        </IconButton>
        <div className={styles.buttonActiveIndicator}></div>
      </div>
    </div>
  );
};

export default DoubleArrowButtons;
