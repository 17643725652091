import styles from './LinkButton.module.scss';
import { Link } from '../../../components/ui';
import { Typography } from '@mui/material';

const LinkButton = ({
  children,
  textProps = {},
  noPadding = false,
  noPaddingX = false,
  noPaddingY = false,
  btnType = 'dark',
  ...props
}) => {
  return (
    <div
      className={`${styles.LinkMainContainer} ${
        noPadding ? styles.noPadding : ''
      } ${noPaddingX ? styles.noPaddingX : ''} ${
        noPaddingY ? styles.noPaddingY : ''
      }`}
    >
      <div>
        <Link {...props}>
          <Typography
            variant="button1"
            component={'span'}
            color={btnType == 'dark' ? 'primary.main' : 'white'}
            {...textProps}
            noWrap
          >
            {children}
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default LinkButton;
