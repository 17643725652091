import { validateCode } from '../../components/body/checkout/sections/checkoutUtils';
import { statesWithId } from '../../components/body/support/forms/shared/FormsData';
import toTitleCase from './toTitleCase';

export const convertToAddressString = (data) => {
  return `${toTitleCase(data.firstname || '')} ${toTitleCase(
    data.lastname || ''
  )}, ${data?.street ? data.street[0] : ''}, ${
    data?.street && data?.street[1]
      ? `${data?.street[1]}, ${data.city}`
      : data.city
  }, ${data?.region?.region || statesWithId[data?.region_id]?.name} ${
    data.postcode
  }, ${data?.country_id == 'US' ? 'United States' : 'Canada'}`;
};

export const convertFormattedAddressToString = (data) => {
  return `${data.firstName} ${data.lastName}, 
  ${data?.street ? data.street : ''}, 
  ${data?.street1 ? `${data?.street1}, ${data.city}` : data.city}, 
  ${data?.region} ${validateCode(data.zip, data.zip, 5)}, ${'United States'}`;
};
