import { add, compareDesc } from 'date-fns';

/*
    # Function returns a valid NOD.

    if date <= today
        return tomorrow
*/

const getValidNOD = (date) => {
  let today = new Date(new Date().toDateString());
  if (!date) return today;
  let nod = new Date(new Date(date).toDateString());
  let result = compareDesc(today, nod);
  if (result > -1) return nod;
  else return add(today, { days: 1 });
};

export { getValidNOD };
