import dynamic from 'next/dynamic';
import { Router, useRouter } from 'next/router';
import styles from './Layout.module.scss';
import {
  getIpAddressInfo,
  ipAddressInfo,
  customerInfo,
} from '../../src/features/user/userState';

import { isCartOpen, showCart } from '../../src/features/ui/uiState';
import { getCart } from '../../src/features/cart/cartState';

import Content from '../content/Content';
import Contact from '../contact/Contact';
import { useAppDispatch, useAppSelector } from '../../src/app/hooks';
import { useEffect, useState } from 'react';
import { FBPageView } from '../../commons/helpers/Analytics/fbPixel';
import { GAPageView } from '../../commons/helpers/Analytics/googleAnalytics';
import AlertBanner from '../Banner/alertBanner';
import Backdrop from '../global/backdrop/Backdrop';
import Header from '../mui5/header/header';
import Footer from '../mui5/footer/footer';
import { config } from '../../config';
import {
  getAdBlockerStatus,
  getIsAdBlocked,
} from '../../src/features/app/appState';
import {
  AdBlockerScript,
  AdUnBlockerScripts,
} from '../../commons/components/scripts';
import { Head } from '../global';
import TopHeader from '../mui5/header/topHeader';
import MiniCartDrawer from '../mui5/mini-cart/MiniDrawer/MiniDrawer';
import { cryptoJSSha1 } from '../../commons/helpers/apiCall/chipers';
import { getStatesListByCountry } from '../../src/features/app/appState';
import { vwoHelper } from '../../commons/helpers/Analytics/vwo/vwoHelper';
import cookie from 'cookie';
import { supportedCountryList } from '../../utils/Utils';
import { ContainedButton } from '../../commons/hoc';

const Layout = ({ children }) => {
  let routerPath = useRouter();
  const dispatch = useAppDispatch();
  const ipaddress = useAppSelector(ipAddressInfo);
  var { id, email } = useAppSelector(customerInfo);
  if (typeof id == 'undefined' || typeof email == 'undefined') {
    email = '';
    id = '';
  } else {
    email = cryptoJSSha1(email);
  }
  const isAdBlockerEnabled = useAppSelector(getIsAdBlocked);
  const cartOpen = useAppSelector(isCartOpen);
  const { quote } = useAppSelector(getCart);

  const closeCart = () => {
    dispatch(showCart(false));
  };

  useEffect(() => {
    try {
      dispatch(getIpAddressInfo());
      dispatch(getAdBlockerStatus());
      supportedCountryList.forEach((country) => {
        dispatch(getStatesListByCountry(country));
      });
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (routerPath.pathname == '/cart') {
      closeCart();
    }
  }, [cartOpen, quote]);

  let showContact = false;
  // if we want to show alert on the banner then we can use
  const alertBanner = config.alert_banner === 'true';

  useEffect(() => {
    if (isAdBlockerEnabled) {
      window.isAdBlockerEnabled = true;
    } else {
      window.isAdBlockerEnabled = false;
    }
  }, [isAdBlockerEnabled]);

  useEffect(() => {
    if (ipaddress !== null) {
      FBPageView();
    }
  }, [ipaddress]);

  useEffect(() => {
    const handleRouteChange = () => {
      FBPageView();
      GAPageView();
      try {
        ire('identify', { customerId: id, customerEmail: email });
      } catch (exception) {
        console.log(exception);
      }
      try {
        RISKX.go(routerPath.pathname);
      } catch (exception) {
        console.log(exception);
      }
      closeCart();
    };
    routerPath.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      routerPath.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [routerPath.events]);

  const isDark = ['/', '/products', '/support'].includes(routerPath.pathname);
  const hideNav = ['/checkout', '/b3119605ae72840f6f119ed26c00a605'].includes(
    routerPath.pathname
  );
  const isGradient = ['/cart'].includes(routerPath.pathname);
  const isResource = ['/resources/[category]/[blog]'].includes(
    routerPath.pathname
  );

  const promoContent = {
    mainContent: 'Promo info can go here as needed.',
    linkContent: 'Can link to pages like this',
    promoLink: '#',
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className={styles.container}>
      <a className={styles.skipLink} href="#site-content">
        <ContainedButton btnType="light">Skip To Main Content</ContainedButton>
      </a>
      {isAdBlockerEnabled && <AdBlockerScript />}
      {isAdBlockerEnabled === false && <AdUnBlockerScripts />}
      {/* {routerPath.pathname === '/' && alertBanner && (
        <AlertBanner
          path={Router.pathname === '/'}
          setCloseButton={setCloseButton}
          CloseButton={closeButton}
        />
      )} */}
      {!isResource && <Head hasCanonical={routerPath.pathname} />}

      {!hideNav && (
        <>
          <MiniCartDrawer />
          <TopHeader showSideNav={isNavOpen} />
          <Header
            isDark={!isDark}
            showSideNav={isNavOpen}
            menuClicked={setIsNavOpen}
            gradient={isGradient}
          />
        </>
      )}
      <div id="site-content"></div>
      <Content>{children}</Content>
      {!hideNav && <Footer />}
      {showContact && <Contact />}
      <Backdrop />
    </div>
  );
};

export default Layout;
