import React from 'react';
import styles from './Dropdown.module.scss';

const Dropdown = (
  {
    name = '',
    id = '',
    options = [],
    tkey = '',
    pkey = 'value',
    selectedOption = '',
    selectLabel = false,
    addNewOption = false,
    label = '',
    error = false,
    // defaultValue = "",
    disable = '',
    selectDisable = true,
    addNewText = 'Add New',
    optionsOrder = [1, 2, 3],
    ...rest
  },
  ref
) => {
  const optionsInScope = {
    1:
      //? Set default option to PLACE_HOLDER_OPTION to force select the disabled option
      selectLabel && (
        <option
          key="PLACE_HOLDER_OPTION"
          value="PLACE_HOLDER_OPTION"
          disabled={selectDisable}
        >
          Select {label}
        </option>
      ),
    2: options.map((item, index) => (
      <option key={index} value={item[pkey]} disabled={item['disabled']}>
        {item[tkey]}
      </option>
    )),
    3: addNewOption && (
      <option key="ADD_NEW_OPTION" value="ADD_NEW_OPTION">
        {addNewText}
      </option>
    ),
  };

  return (
    <select
      ref={ref}
      className={`${styles.selectDD} ${error ? styles.inputError : ''}`}
      name={name}
      id={id}
      key={id}
      {...rest}
    >
      {optionsOrder.map((order) => optionsInScope[order])}
    </select>
  );
};

const forwardDropdown = React.forwardRef(Dropdown);

export default forwardDropdown;
