import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import expireReducer from 'redux-persist-expire';
import appReducer from '../features/app/appState';
import uiReducer from '../features/ui/uiState';
import cartReducer from '../features/cart/cartState';
import surveyReducer from '../features/survey/surveyState';
import userReducer from '../features/user/userState';
import yoptoReducer from '../features/yopto/yoptoState';
import orderReducer from '../features/order/orderState';
import subscriptionReducer from '../features/subscription/subscriptionState';
import { config } from '../../config';
import newsletterReducer from '../features/newsletter/newsletterState';
import resourceReducer from '../features/resource/resourceState';

import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

const reducers = combineReducers({
  app: appReducer,
  ui: uiReducer,
  cart: cartReducer,
  survey: surveyReducer,
  user: userReducer,
  order: orderReducer,
  subscription: subscriptionReducer,
  yotpo: yoptoReducer,
  newsletter: newsletterReducer,
  resource: resourceReducer,
});

const migrations = {
  0: (state) => {
    return {
      _persist: state._persist,
    };
  },
  [config.redux.persist.version]: (state) => {
    return {
      _persist: state._persist,
      ...reducers,
    };
  },
};

const persistConfig = {
  key: 'root',
  // # only required to change version if updating the existing reducers.
  version: config.redux.persist.version,
  blacklist: ['ui', 'app'],
  storage,
  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer('_persist', {
      // (Required) Seconds after which store will be expired
      expireSeconds: config.redux.persist.validity,
      autoExpire: true,
    }),
    // You can add more `expireReducer` calls here for different reducers
    // that you may want to expire
  ],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

const reduxSyncConfig = {
  // broadcastChannelOption: { type: 'native' },
  blacklist: [
    'persist/PERSIST',
    'persist/REHYDRATE',
    'persist/PURGE',
    'persist/FLUSH',
  ],
};

const store = configureStore({
  reducer: persistedReducer,
  devTools: ['dev', 'stage'].includes(config.environment),
  middleware: [thunk, createStateSyncMiddleware(reduxSyncConfig)],
});

export const persistor = persistStore(store, {}, function () {
  persistor.persist();
});

initMessageListener(store);

export default store;
