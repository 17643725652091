import {
  HOMEPAGE_IMG_1,
  HOMEPAGE_IMG_2,
  HOMEPAGE_IMG_MOBILE_1,
  HOMEPAGE_IMG_MOBILE_2,
  HOMEPAGE_IMG_TAB_1,
  HOMEPAGE_IMG_TAB_2,
} from '../../src/assets/images/homepage';

export const ORDER_PHONENO = '888-488-3112';
export const SUPPORT_PHONENO = '833-888-1533';
export const BEST_DEAL = 'BEST DEAL';
export const SUBSCRIBESAVE = 'SUBSCRIBE & SAVE 15%';
export const SINGLEORDER = 'SINGLE ORDER';
export const AUTOMATICALLY_RENEWS_19 =
  'Automatically renews at $19.95 monthly until cancelled';
export const AUTOMATICALLY_RENEWS_79 =
  'Automatically renews at $79.95 monthly until cancelled';
export const AUTOMATICALLY_RENEWS_21 =
  'Automatically renews at $21.95 monthly until cancelled';
export const AUTOMATICALLY_RENEWS_34 =
  'Automatically renews at $34.95 monthly until cancelled';
export const AUTOMATICALLY_RENEWS_29 =
  'Automatically renews at $$29.95 monthly until cancelled';
export const ORDER_NOW = 'Buy Now';
export const CHOOSE_NOW = 'Shop Now';
export const FOOD_DRUG_ADMIN =
  '* These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease. Individual results may vary.';

export const BANNER_DATA = [
  {
    isProduct: false,
    id: 1,
    title1: '100% Drug-Free Supplements',
    title2: 'Our Mission is <span class="noWarp">Your Best Life</span>',
    rating: null,
    reviews: null,
    description:
      'Over one million customers have chosen our 100% drug-free health supplements to help them feel their best.',
    productOfferText: null,
    primaryBtnUrl: '',
    secondaryBtnUrl: '/products/relief-factor',
    img: HOMEPAGE_IMG_1,
    mobileImage: HOMEPAGE_IMG_MOBILE_1,
    tabImage: HOMEPAGE_IMG_MOBILE_1,
    // tabImage: HOMEPAGE_IMG_TAB_1,
  },
  // {
  //   isProduct: true,
  //   id: 2,
  //   title1: 'Relief Factor',
  //   title2: 'Relief Factor',
  //   rating: 5,
  //   reviews: 56,
  //   description:
  //     'Relief Factor is a unique combination of botanical ingredients and fish oil. Formulated by physicians to provide ongoing relief from all kinds of pain and discomfort.',
  //   productOfferText: 'Starting at $19.95',
  //   primaryBtnUrl: '',
  //   secondaryBtnUrl: '/products/relief-factor',
  //   img: HOMEPAGE_IMG_2,
  //   mobileImage: HOMEPAGE_IMG_MOBILE_2,
  //   tabImage: HOMEPAGE_IMG_TAB_2,
  // },
];
