import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../app/store';
import { fetchSurveyList, postSurvey } from './surveyApi';

export const SurveyState = {
  isPending: false,
  product: '',
  list: [],
  oldOrderedConfirmData: {},
};

const initialState = {
  isPending: false,
  product: '',
  list: [],
  oldOrderedConfirmData: [],
};

export const getSurveyOptions = createAsyncThunk(
  'GET_SURVEY_LIST',
  async (product) => {
    const res = await fetchSurveyList(product);
    return res;
  }
);

export const insertSurvey = createAsyncThunk(
  'SUBMIT_SURVEY',
  async ({ optionsSelected, orderId }) => {
    const res = await postSurvey({ orderId, optionsSelected });
    return res;
  }
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setSurveyItem: (state) => {
      state.isPending = true;
    },
    resetSurveyState: (state, aciton) => {
      return initialState;
    },
    checkSurveyState: (state, action) => {
      state.oldOrderedConfirmData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyOptions.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(getSurveyOptions.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(insertSurvey.fulfilled, (state, action) => {
        state.isPending = false;
        state.list = [];
        state.product = '';
        return state;
      });
  },
});

export const { setSurveyItem, resetSurveyState, checkSurveyState } =
  surveySlice.actions;

export const getSurveyStatus = (state) => state?.survey?.isPending;
export const getSurveyCode = (state) => state?.survey?.product;
export const getSurveyList = (state) => state?.survey?.list;
export const getoldOrderedConfirmData = (state) =>
  state.survey.oldOrderedConfirmData;

export default surveySlice.reducer;
