const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

export default function isMac() {
  const platform = window.navigator.platform;
  return (
    macosPlatforms.indexOf(platform) !== -1 ||
    iosPlatforms.indexOf(platform) !== -1
  );
}
