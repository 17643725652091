import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styles from './SurveyModal.module.scss';
import { useAppSelector } from '../../../../src/app/hooks';
import { getUI } from '../../../../src/features/ui/uiState';
import { CheckboxComponent } from '../../../../commons/components/ui';
import { ContainedButton, OutlinedButton } from '../../../../commons/hoc';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '100000 !important',
  },
  modalView: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    // outline: "none",
    // width: "100%",
    // maxWidth: "300px",
    borderRadius: '20px',
    maxHeight: '90%',
    overflow: 'auto',
  },
}));

const SurveyModal = ({
  list = [],
  showSurvey = false,
  submitSelectedOptions,
  title,
}) => {
  const classes = useStyles();
  const ui = useAppSelector(getUI);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    // TODO: Find an alternate way to stop this from conflicting.
    // ! Leave UI reducer isolated
    if (showSurvey && ui?.awaitingApis == 0) handleOpen();
    else handleClose();
  }, [showSurvey, ui]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason != 'backdropClick' && reason != 'escapeKeyDown') setOpen(false);
  };

  const handleOnChange = (event, item) => {
    let status = event.target.checked;
    if (error) setError('');
    if (selectedOptions[item] && !status) {
      delete selectedOptions[item];
    } else {
      selectedOptions[item] = true;
    }
  };

  const handleSubmit = () => {
    if (Object.keys(selectedOptions).length == 0 || error) {
      setError('Please select atleast 1 option!');
    } else {
      setError('');
      handleClose();
      submitSelectedOptions(Object.keys(selectedOptions));
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={`${styles.modalViewBox} ${classes.modalView}`}>
          <div className={styles.modalInnerContainer}>
            <div className={styles.modalTitle}>{title}</div>
            <div className={styles.checkboxContainer}>
              {list?.map((item, index) => (
                <CheckboxComponent
                  key={index}
                  className={styles.text}
                  onChange={(event) => handleOnChange(event, item)}
                >
                  {item}
                </CheckboxComponent>
                // <FormControlLabel
                //   key={index}
                //   label={item}
                //   control={
                //     <Checkbox
                //       className={styles.surveyCheckbox}
                //       onChange={(event) => handleOnChange(event, item)}
                //     />
                //   }
                // />
              ))}
            </div>
            <div className={styles.submitErrorText}>{error}</div>
            <div className={styles.modalButtonsContainer}>
              <div>
                <OutlinedButton
                  onClick={handleClose}
                  className={styles.modalClose}
                >
                  <div className={styles.modalCloseContent}>Close</div>
                </OutlinedButton>
              </div>
              <div>
                <ContainedButton btnType="dark" onClick={handleSubmit}>
                  Submit
                </ContainedButton>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default SurveyModal;
