import { config } from '../../../config';
import { resourceApiCall } from '../../../commons/helpers/apiCall';

export async function fetchPostList() {
  const result = await resourceApiCall({
    endpoint: `postcollections`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchAllPost() {
  const result = await resourceApiCall({
    endpoint: `posts`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchPostSingle(id) {
  const result = await resourceApiCall({
    endpoint: `posts?slug=${id}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchAllPostWithPagination(postDetails) {
  const result = await resourceApiCall({
    endpoint: `postcollections?page=${postDetails.pageNo}&per_page=${postDetails.pageItemCount}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchPostListByCatgory(postDetails) {
  const result = await resourceApiCall({
    endpoint: `postcollections?categories=${postDetails.category}&page=${postDetails.pageNo}&per_page=${postDetails.pageItemCount}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}
export async function fetchPostListByTag(postDetails) {
  const result = await resourceApiCall({
    endpoint: `postcollections?tags=${postDetails.tag}&page=${postDetails.pageNo}&per_page=${postDetails.pageItemCount}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchAllCatgory() {
  const result = await resourceApiCall({
    endpoint: 'categories',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchAllTags() {
  const result = await resourceApiCall({
    endpoint: 'tags',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}
