import React, { useRef, useEffect, useState } from 'react';
import { Typography, IconButton } from '@mui/material';

import { CounterButton, OutlinedButton } from '../../../../hoc';
import Image from 'next/image';

import styles from './CartProductCard.module.scss';

export default function CartProductCard({
  item,
  product,
  counterRef,
  handleIncrementCounter,
  handleDecrementCounter,
  handleUpgrade,
  handleRemove,
  handleInfo,
  isLast,
  isCartPage = false,
  category,
  qty,
  imgData,
  productName,
  showQuantityInfo,
  quantityInfoMsg,
  incrementDisabled,
  decrementDisabled,
  cartLoading,
}) {
  const quantityRef = useRef(null);
  useEffect(() => {
    if (showQuantityInfo.status == true) {
      const span = quantityRef.current;
      span.className = `${styles.quantityUpdateInfo} ${styles.fadeIn} ${styles.fast}`;
      setTimeout(function () {
        span.classList.remove(`${styles.fadeIn}`);
        span.className = `${styles.quantityUpdateInfo} ${styles.fadeOut} ${styles.fast}`;
      }, 3000);
    }
  }, [showQuantityInfo]);

  return (
    <div className={styles.productCartWrapper}>
      <div
        className={styles.productCartContainer}
        style={{
          borderBottom: isCartPage
            ? '1px solid rgb(0, 65, 82, 0.2)'
            : !isLast
            ? '1px solid rgb(0, 65, 82, 0.2)'
            : null,
        }}
      >
        <div
          className={styles.productCartTabDetailsContainer}
          // style={{ paddingBottom: item.isSubscription && '26px' }}
        >
          <div className={styles.productCartImgContainer}>
            <Image
              src={imgData}
              layout="fill"
              placeholder="blur"
              blurDataURL={imgData}
              className={styles.productImg}
            />
          </div>
          <div
            className={styles.subscribedProductCartDetails}
            style={{ width: isCartPage && '100%' }}
          >
            <div className={styles.productCategoryPriceContainer}>
              <Typography className={styles.productCartCategory}>
                {category}
              </Typography>
              <Typography
                className={`${styles.productPrice} ${
                  item.isSubscription && styles.priceAnim
                }`}
              >
                ${(item.quantity * product?.displayPrice).toFixed(2)}
              </Typography>
            </div>
            <div className={styles.productCartCount}>
              <Typography className={styles.productName}>
                {productName}
              </Typography>
              <div className={styles.counterRemoveButtonContainer}>
                <CounterButton
                  ref={counterRef}
                  qty={qty}
                  handleIncrementCounter={() => handleIncrementCounter()}
                  handleDecrementCounter={() => handleDecrementCounter()}
                  incrementDisabled={incrementDisabled}
                  decrementDisabled={decrementDisabled}
                />
                <button
                  onClick={() => handleRemove()}
                  className={styles.productRemove}
                  disabled={cartLoading}
                >
                  Remove
                </button>
              </div>
            </div>
            <div ref={quantityRef} className={styles.quantityUpdateInfo}>
              {showQuantityInfo.id == item.productId &&
                showQuantityInfo.status &&
                quantityInfoMsg}
            </div>
            {!item.isSubscription && (
              <div
                className={`${styles.cartProductDetailsAction} ${
                  item.isSubscription && styles.hidden
                }`}
                style={{
                  justifyContent: isCartPage ? 'flex-start' : 'flex-start',
                }}
              >
                <OutlinedButton
                  onClick={() => handleUpgrade()}
                  className={styles.subscribeBtn}
                >
                  <Typography variant="h5">
                    {' '}
                    Upgrade to Subscribe & Save
                  </Typography>
                </OutlinedButton>
                <IconButton
                  className={styles.cartInfoBtn}
                  onClick={() => handleInfo()}
                >
                  <div className={styles.cartInfoIcon}>i</div>
                </IconButton>
              </div>
            )}
          </div>
        </div>
        {!item.isSubscription && (
          <div
            className={`${styles.cartProductDetailsTabAction} ${
              item.isSubscription && styles.hidden
            }`}
          >
            <OutlinedButton
              className={`${styles.cartMobActionBtn}`}
              onClick={() => handleUpgrade()}
            >
              <Typography variant="h5"> Upgrade to Subscribe & Save</Typography>
            </OutlinedButton>
            <IconButton
              className={styles.cartInfoBtn}
              onClick={() => handleInfo()}
            >
              <div className={styles.cartInfoIcon}>i</div>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}
