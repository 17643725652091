import { config } from '../../../config';

const yoptoAPI = 'https://api.yotpo.com';
const app_key = config.yotpo_id;

export async function getYotpoBottomLine(productId) {
  const url = yoptoAPI + `/products/${app_key}/${productId}/bottomline`;
  const response = await fetch(url, {
    method: 'GET',
  });
  const result = await response.json();
  return result;
}
