const KB_KEY_ENTER = 'Enter';
const KB_KEY_TAB = 'Tab';
const KB_KEY_SHIFT = 'Shift';

const checkIfKBKey = (keyConst, keyClicked) => keyConst == keyClicked;

const isEnterClicked = (e, cb) => {
  if (checkIfKBKey(KB_KEY_ENTER, e?.key)) cb(e);
};
const isTabClicked = (e, cb) => checkIfKBKey(KB_KEY_TAB, e.key) && cb(e);

export { isEnterClicked, isTabClicked, checkIfKBKey };
