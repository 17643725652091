import { validateCode } from '../../components/body/checkout/sections/checkoutUtils';
import { statesWithId } from '../../components/body/support/forms/shared/FormsData';

const threeLineAddressObj = (address) => {
  return {
    firstName: address?.firstname,
    lastName: address?.lastname,
    city: address?.city,
    zipCode: validateCode(address?.postcode, address?.postcode, 5),
    phone: address?.telephone,
    state: address?.region?.region_code
      ? address?.region?.region_code
      : address.state,
    street1: address?.street?.[0],
    street2: address?.street?.[1],
  };
};

const threeLineAddressObjWithType = (address, addressType) => {
  return {
    firstName: address?.[`${addressType}firstname`],
    lastName: address?.[`${addressType}lastname`],
    city: address?.[`${addressType}city`],
    zipCode: validateCode(address?.postcode, address?.postcode, 5),
    phone: address?.telephone,
    state:
      address?.regionid != 0
        ? statesWithId[address?.regionid]?.alias
        : undefined,
    street2: address?.[`${addressType}street1`],
    street1: address?.[`${addressType}street`],
  };
};

const threeLineAddressObjNew = (address) => {
  return {
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    city: address?.city || '',
    zipCode: validateCode(address?.zip, address?.zip, 5),
    phone: address?.phone || '',
    state:
      address?.state != 0 ? statesWithId[address?.state]?.alias : undefined,
    street2: address?.address1 || '',
    street1: address?.address2 || '',
  };
};
export {
  threeLineAddressObj,
  threeLineAddressObjWithType,
  threeLineAddressObjNew,
};
