import { decryptData, encryptData } from './chipers';
import { config } from '../../../config';
import jwt from 'jsonwebtoken';
import { getToken } from '../../utils/extractCustomerToken';
import resourceApiCall from './resourceApiCall';

const extractData = (result) => {
  if (config.encryption.decrypt) {
    if (result?.data) return decryptData(result?.data);
  }
  return result;
};

const apiCall = ({ endpoint, options, version = 'V1', authorize = true }) => {
  const token = jwt.sign({ test: 'test' }, config.jwt_secret);
  let method = options.method.toUpperCase();
  if (method == 'POST' || method == 'PATCH' || method == 'PUT') {
    if (config.encryption.encrypt) {
      const data = encryptData(options.body);
      options.body = JSON.stringify({
        data: data,
      });
    } else {
      options.body = JSON.stringify(options.body);
    }
  }
  let customer_token = getToken();
  options.headers['apikey'] = token;
  if (authorize && customer_token != '') {
    options.headers['Customer-Token'] = customer_token;
  }
  return new Promise(function (resolve, reject) {
    fetch(`${config.apiUrl}/${version}/${endpoint}`, options)
      .then((response) => {
        return response.json().then((result) => {
          // Success
          if (response.ok) resolve(extractData(result));
          // Error
          else reject(extractData(result));
        });
      })
      .catch((error) => {
        // Error
        reject(extractData(error));
      });
  });
};

export default apiCall;

export { resourceApiCall };
