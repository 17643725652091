import Link from 'next/link';
import { config } from '../../config';
import { SUPPORT_PHONENO } from './HomePage';

export const PRODUCT_QUESTION = [
  {
    question: 'What exactly is Relief Factor?',
    answer:
      'Relief Factor is a supplement for pain relief. It is a non-addictive drug-free product that provides relief from everyday aches and pains.',
  },
  {
    question: 'Can Relief Factor cause or raise high blood pressure?',
    answer:
      'The active ingredients in Relief Factor are botanical extracts and fish oil. They do not raise blood pressure. The supplement supports your body’s healthy response to inflammation, one of the key sources of pain, including muscle and back pain.',
  },
  {
    question: 'Is Relief Factor the real deal?',
    answer:
      'Yes, Relief Factor is the real deal. The supplement has been recommended by doctors for many years and contains ingredients that have been known to provide temporary relief from everyday aches and pains.',
  },
  {
    question: 'What is the deal with Relief Factor?',
    answer:
      'Relief Factor is a unique supplement formula for people who suffer from joint, muscle, and back pain symptoms who want to live their best lives free of every day aches and pains.',
  },
  {
    question: 'How does Relief Factor work?',
    answer:
      "Relief Factor works by supporting the body’s natural healthy response to inflammation, providing relief from aches and pains, commonly caused by aging, exercise and everyday living. It's drug-free and easy to use without unpleasant side effects.",
  },
  {
    question: 'What are the downsides of Relief Factor?',
    answer:
      "It's important to realize that Relief Factor will not work for everyone. The purpose of the supplement is to provide temporary relief from various symptoms, such as muscle and back pain. In rare circumstances, some customers experience stomach discomfort when first starting to take Relief Factor, but those symptoms tend to go away after a few days as your body adjusts to the formula.",
  },
  {
    question: 'Does Relief Factor reduce inflammation?',
    answer:
      'Relief Factor works by supporting your body’s healthy response to inflammation through four metabolic pathways. In turn, your body is then able to reduce inflammation throughout your body. This helps to reduce pain in muscles but also reduces swelling, stiffness, and other symptoms that cause pain.',
  },
  {
    question: 'Is Relief Factor a pill or drink?',
    answer:
      'Each dose of Relief Factor consists of two gelcaps and two capsules, packaged in a convenient easy-to-open cellophane packet.',
  },
  {
    question: 'Is Relief Factor safe?',
    answer:
      'Many of the ingredients in Relief Factor occur in foods we eat and drink every day, but in such small quantities that we don’t feel the same effects. Relief Factor simply provides those key ingredients in substantially high quantities, at a high-enough potency to help the body reduce or eliminate aches and pains.',
  },
  {
    question: 'What kind of pain does Relief Factor help?',
    answer:
      'Because Relief Factor supports the body’s natural healthy response to inflammation which is the source of many aches and pains. These pains include back pain, neck pain, shoulder pain, elbow pain, wrist pain, hand pain, hip pain, knee pain, ankle pain and foot pain caused by aging, exercise or simply everyday living.',
  },
  {
    question: 'Does the FDA approve of Relief Factor?',
    answer:
      'As a dietary supplement Relief Factor is not subject to FDA approval; however, Relief Factor is manufactured and distributed using the FDA’s Good Manufacturing Practice (GMP).',
  },
  {
    question: 'Is the product Relief Factor legitimate?',
    answer:
      'Yes, Relief Factor is a genuine product distributed by Promedev LLC in the United States of America. It has a legitimate Google+ and Facebook page.',
  },
  {
    question: 'Who owns Relief Factor?',
    answer:
      'Relief Factor is a brand owned by Promedev LLC, a private company based in Kirkland, Washington. ',
  },
  {
    question: 'What is Relief Factor made of?',
    answer:
      'Relief Factor has four key ingredients: omega-3s from fish oil derived from wild-caught mackerel, sardines and anchovies; curcumin, the active flavinoid in turmeric; resveratrol, the powerful antioxidant commonly associated with red wine; and icariin, which promotes healthy blood flow.',
  },
];

export const SHIPPING_AND_RETURN = [
  {
    question: 'What is your method of shipping?',
    answer:
      'We ship our packages via UPS or USPS, to all deliverable addresses within the United States, including Post Office Boxes.',
  },
  {
    question: 'Do you ship to other countries?',
    answer: 'We are unable to ship outside the United States at this time.',
  },
  {
    question: 'What is your return/refund policy?',
    answer: `We accept returns of any Relief Factor product within 90 days of original purchase that has the original blue and green bag intact, unopened, and unmarked in any way. To return Relief Factor product, simply write "refused" on the outside of the package and place it in your mailbox or take it to your local post office. Once we receive it back, we will issue a refund less the cost of shipping. If the outer, white package has been opened, you must pay the return shipping costs. Return Address: Relief Factor by Promedev, Customer Care 11031 117th Place NE, Kirkland, WA 98033",`,
  },
];

export const ORDERING_MEMEBER = [
  {
    question: "What's the monthly cost of Relief Factor?",
    answer:
      'Relief Factor is a reasonably priced supplement, costing just $79.95 with a subscription, plus shipping and handling and any applicable sales tax. ',
  },
  {
    question: "What's the actual cost of Relief Factor?",
    answer:
      'The Relief Factor 3-Week QuickStart costs $19.95, plus shipping and handling and applicable sales tax. On subscription, a 60-dose supply, which is a monthly supply for most customers, is just $79.95 plus shipping and handling, a 15% discount from the retail price.',
  },
  {
    question: 'Is it easy to cancel Relief Factor?',
    answer: (
      <>
        Yes. The simplest way is to call{' '}
        <a
          className="phone-link noLineBreak"
          href={`tel:+1-${SUPPORT_PHONENO}`}
        >
          {SUPPORT_PHONENO}
        </a>{' '}
        , and select “cancel.” Your subscription will be automatically canceled
        within 24 hours. You can also email Relief Factor at{' '}
        <span>
          <a
            style={{ color: '#479DA6', textDecoration: 'underline' }}
            href="mailto:contactus@relieffactor.com"
          >
            contactus@relieffactor.com
          </a>
        </span>{' '}
        or submit a cancellation request at{' '}
        <span style={{ color: '#479DA6', textDecoration: 'underline' }}>
          <Link href="/support">{`${config.SiteURL}support`}</Link>
        </span>
        .{' '}
      </>
    ),
  },
  {
    question:
      'I’m calling your support number from outside of the US but it’s not working, what do I do?',
    answer:
      'If you are calling from outside the US, you must dial 1 before dialing our support number. This is a standard requirement for making international calls and is necessary to ensure that your call is routed correctly to the US.',
  },
];

export const feelBrandNewFAQ = [
  {
    title: `How do I reduce inflammation in my joints?`,
    description: `Increase your consumption of fatty acids like omega-3 via a healthy diet and supplements like Relief Factor to help your body reduce inflammation and the pain associated with it.`,
  },
  {
    title: `What is the fastest way to reduce inflammation?`,
    description: `Omega-3 fatty acids contained in Relief Factor are the fastest way to help your body reduce inflammation in joints and surrounding tissue.`,
  },
  {
    title: `How do I get rid of body stiffness?`,
    description: `A common cause of stiffness is caused by inflamed tissue and swelling around the joints. To get rid of it, consume antioxidant ingredients like turmeric and resveratrol (both of which are found in Relief Factor).`,
  },
  {
    title: `What exactly is Relief Factor?`,
    description: `Relief Factor is a supplement for pain relief. It is a non-addictive drug-free product that provides relief from everyday aches and pains. Each dose of Relief Factor consists of two gel caps and two capsules, packaged in a convenient easy-to-open cellophane packet.`,
  },
  {
    title: `Can I rebuild cartilage in my joints?`,
    description: `Unfortunately, cartilage cannot repair itself because it doesn’t have enough blood vessels to support cell growth. You can, however, reduce stress and physical wear on it by consuming supplements like Relief Factor, which have fatty acids and icariin, which promote the production of collagen and lubricate areas around the joints to support tissue health.`,
  },
  {
    title: `Can I rebuild collagen in my joints?`,
    description: `Collagen is an essential part of healthy bones, muscles, and tissue. While studies show mixed results in taking collagen-only supplements to increase its levels, you can promote its production naturally by consuming omega-3 fatty acids and easing the swelling with other Relief Factor ingredients.`,
  },
  {
    title: `Can Relief Factor cause or raise high blood pressure?`,
    description: `The active ingredients in Relief Factor are botanical extracts and fish oil. They do not raise blood pressure. The supplement supports your body’s healthy response to inflammation, one of the key sources of pain, including muscle and back pain.`,
  },
];
