export const BUSINESS_HOURS = `You can also reach us on our support line 833-888-1533 during our business hours.`;
export const BUSINESS_TIMING = '(Monday - Friday 8am - 9pm ET)';
export const SUPPORT_REQUEST_OPTIONS = [
  'Most support requests are resolved in 2 business days or less.',
  'Phone and email are used to look-up your account.',
  'You will receive a support ticket confirmation via email after submitting your request.',
  'We will never share or sell your information.',
];

export const SUPPORT_REQUEST_HASH = {
  // '': 'choose',
  'restart': 'restart',
  'next-order': 'date',
  'shipping-address': 'address',
  'my-account': 'question',
  'frequency': 'frequency',
  'question': 'product',
  'modify-subscription': 'cancel',
  'service-request': 'other',
};

export const SUPPORT_REQUEST_REASONS = {
  choose: {
    value: 'choose',
    name: 'Please Select',
    hash: '/support',
  },
  restart: {
    value: 'restart',
    name: 'Restart my subscription',
    hash: 'restart',
  },
  date: {
    value: 'date',
    name: 'Change the date of my next order',
    hash: 'next-order',
  },
  address: {
    value: 'address',
    name: 'Change my shipping address',
    hash: 'shipping-address',
  },
  question: {
    value: 'question',
    name: 'Question about my account',
    hash: 'my-account',
  },
  frequency: {
    value: 'frequency',
    name: "Change how often I'm getting my order",
    hash: 'frequency',
  },
  product: {
    value: 'product',
    name: 'Product/Subscription question',
    hash: 'question',
  },
  cancel: {
    value: 'cancel',
    name: 'Modify or cancel my subscription',
    hash: 'modify-subscription',
  },
  other: {
    value: 'other',
    name: 'Other customer service request',
    hash: 'service-request',
  },
};
