import { createAsyncThunk, createSlice, isPending } from '@reduxjs/toolkit';
import { newsletter, contactUs } from './newsletterApi';
import { SUCCESS_MESSAGES } from '../../../commons/feedbackMessages';
import { toast } from 'react-toastify';

export const insertNewsletter = createAsyncThunk(
  'SUBMIT_NEWSLETTER',
  async (data) => {
    const res = await newsletter(data);
    return res;
  }
);

export const userContact = createAsyncThunk('SUBMIT_CONTACT', async (data) => {
  const res = await contactUs(data);
  return res;
});

const initialState = {
  isPending: false,
  success: null,
  error: null,
  inputMessage: { content: '', type: null },
};

export const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {
    setInputMessage(state, action) {
      state.inputMessage = action.payload;
    },
    resetNewsletter(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertNewsletter.fulfilled, (state, action) => {
        state.success = action.payload;
        state.isPending = false;
        state.error = null;
        // log
        state.inputMessage = {
          content: action.payload?.successCount,
          type: 'success',
        };
        // return state;
      })
      .addCase(insertNewsletter.pending, (state, action) => {
        state.isPending = true;
        state.error = null;
      })
      .addCase(insertNewsletter.rejected, (state, action) => {
        state.error = action.error.message;
        state.isPending = false;
        state.success = false;
        return state;
      })
      .addCase(userContact.fulfilled, (state, action) => {
        toast.success(SUCCESS_MESSAGES.CONTACT_SUBMIT, {
          theme: 'colored',
        });
        state.error = false;
      })
      .addCase(userContact.rejected, (state, action) => {
        state.error = true;
        toast.error(action?.error?.message || 'Something went wrong!', {
          theme: 'colored',
        });
      });
  },
});

export const { resetNewsletter, setInputMessage } = newsletterSlice.actions;
export default newsletterSlice.reducer;
