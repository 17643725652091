import React from 'react';
import { Button } from '../../../components/ui';
import styles from './OutlinedButton.module.scss';

const OutlinedButton = ({ children, className, ...rest }) => {
  return (
    <Button className={`${styles.OutlinedBtn} ${className}`} {...rest}>
      {children}
    </Button>
  );
};

export default OutlinedButton;
