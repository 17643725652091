import React from 'react';
import styles from './WarningAccordion.module.scss';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
export const WarningAccordion = ({ children, title, ...rest }) => {
  return (
    <div className={styles.warningContainer}>
      <Accordion disableGutters={true} className={styles.warningAccordion}>
        <AccordionSummary
          className={styles.warningAccordionSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={styles.warningAccordionSummaryContent}>
            <WarningAmberIcon />
            <Typography variant="p1">{title}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.warningAccordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
