const PRIMARY = {
  main: "#004152", // Dark Viridian
  light: "#479DA6", // Teal
  black: "#2B2B2B", // Soft Black
  white: "#fff", // White
  info: "#005DC6", // Conversion Blue
};

const SECONDARY = {
  main: "#FFF9EA", // Tan
  light: "#9CD4EA", // Light Blue
  lighter: "#EAFBFF", // Soft Light Blue
  info: "#DACAF2", // Soft Lavender
};

const ACTION = {
  dark: "#C84940", // Error Red
  main: "#595959", // Dark Disabled Gray
  light: "#DFDFDF", // Light Disabled Gray
  green: "#007E3A",
  red: "#FF0000",
  gray: "#645C52",
};

const palette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  action: ACTION,
};

export default palette;
