import CryptoJS from 'crypto-js';
import { config } from '../../../config';

export function encryptData(data) {
  const { hash } = config.encryption.cryptoJS;
  if (hash) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), hash).toString();
  }
}

export function decryptData(data) {
  const { hash } = config.encryption.cryptoJS;
  if (hash) {
    const bytes = CryptoJS.AES.decrypt(data, hash);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}

export const cryptoJSAesDecrypt = (str) => {
  const { passphrase, salt, iv_key } = config.encryption.quoteEncrypt;
  try {
    let encrypted = str.split(' ').join('+');
    var iv = CryptoJS.enc.Hex.parse(iv_key);
    var key = CryptoJS.PBKDF2(passphrase, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999,
    });

    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    let value = decrypted.toString(CryptoJS.enc.Utf8);
    return value || '';
  } catch (err) {}
  return '';
};

export function cryptoJSSha1(data) {
 

  var hashedEmail = CryptoJS.SHA1(data).toString();
  return hashedEmail;
 
}