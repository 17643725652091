import React from 'react';
import styles from './TextButton.module.scss';
import { Typography } from '@mui/material';
import { Button } from '../../../components/ui';
import { TextWithArrowAnimation } from '../../AnimatedComponents';

const TextButton = React.forwardRef(
  (
    {
      children,
      variant = 'h5',
      arrow = false,
      btnType = 'dark',
      className = '',
      ...rest
    },
    ref
  ) => (
    <Button
      {...rest}
      ref={ref}
      className={`${styles.buttonMain} ${
        btnType == 'dark' ? '' : styles.light
      } ${className}`}
    >
      <div style={{ zIndex: 2 }}>
        {arrow ? (
          <TextWithArrowAnimation
            btnType={btnType == 'dark' ? 'light' : 'dark'}
            // variant="h5"
          >
            {children}
          </TextWithArrowAnimation>
        ) : (
          <Typography
            variant={variant}
            color={btnType == 'dark' ? 'primary.main' : 'primary.white'}
            noWrap
          >
            {children}
          </Typography>
        )}
      </div>
    </Button>
  )
);

export default TextButton;
