import { validationFieldMessages } from '../validations';

const getValidationMessage = (errors, field, errorType = '') => {
  let validationField = errorType !== '' ? errorType : field;
  let msgContent = null;
  if (errors?.hasOwnProperty(field)) {
    const type = errors[field].type;
    if (validationFieldMessages.hasOwnProperty(validationField)) {
      const message = validationFieldMessages[validationField].hasOwnProperty(
        type
      )
        ? validationFieldMessages[validationField][type]
        : '';
      msgContent = <div className="formErrorMessage">{message}</div>;
    } else {
      msgContent = <div className="formErrorMessage">Invalid data</div>;
    }
  }
  return msgContent;
};
export default getValidationMessage;

const getBTValidationMessage = (errors, field, errorType = '') => {
  let validationField = errorType !== '' ? errorType : field;
  let msgContent = null;
  if (errors?.hasOwnProperty(field)) {
    const type = errors[field]?.isEmpty
      ? 'required'
      : !errors[field]?.isValid
      ? 'pattern'
      : '';
    if (type)
      if (validationFieldMessages.hasOwnProperty(validationField)) {
        const message = validationFieldMessages[validationField].hasOwnProperty(
          type
        )
          ? validationFieldMessages[validationField][type]
          : '';
        msgContent = <div className="formErrorMessage">{message}</div>;
      } else {
        msgContent = <div className="formErrorMessage">Invalid data</div>;
      }
  }
  return msgContent;
};
export { getBTValidationMessage };
