// ----------------------------------------------------------------------

export default function MenuItem(theme) {
  return {
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
}
