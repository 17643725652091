import { Link } from '../../components/ui';
import { TextWithArrowAnimation } from '../AnimatedComponents';

const LinkArrow = ({ children, type = 'dark', ...props }) => {
  return (
    <Link {...props}>
      <TextWithArrowAnimation type={type} underline={true}>
        {children}
      </TextWithArrowAnimation>
    </Link>
  );
};

export default LinkArrow;
