import { getProducts } from './productsHelper';

const products = getProducts();
const cacluclateSubTotal = (items) => {
  let subTotal = 0;
  for (let item of items)
    subTotal += item.quantity * products[item.key]?.displayPrice;
  return parseFloat(subTotal).toFixed(2);
};

export { cacluclateSubTotal };
