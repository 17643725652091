// import { getProductItems } from '../utils';
import { initialPriceProducts } from '../../../Constants';
import { cartExtract } from '../../../utils';
import { TagNames } from './Constants';

const EventAction = (action, label, params) => {
  window.pintrk?.(window.pintrk(action, label, params));
};

const getProductList = (productList, products) => {
  let content_ids = [];
  productList.forEach(function (item, index) {
    const productData = products?.[item];
    const quoteParams = productData?.quoteParams;
    content_ids.push(quoteParams?.productId);
  });
  return {
    currency: 'USD',
    product_id: [...new Set(content_ids)],
    product_type: 'product',
  };
};

function getProductItems(productList, products) {
  const productItems = [];
  productList.forEach(function (item, index) {
    const productData = products[item];
    if (!productData) {
      return;
    }
    const quoteParams = productData?.quoteParams;
    let data = {
      product_category: 'Supplements',
      product_name: productData.name,
      product_id: quoteParams.productId,
      product_price: initialPriceProducts?.includes(item)
        ? productData['single_price']
        : productData['recurringPrice'],
      product_quantity: quoteParams.quantity,
    };
    productItems.push(data);
  });
  return productItems;
}

const PinterestPageVisit = (productList, products) => {
  let productItems = getProductList(productList, products);
  EventAction('track', TagNames.PageVisit, productItems);
};

const pinterestPurchaseOrder = (products, cartData) => {
  let { productList, subMap } = cartExtract(cartData.items);
  let productItems = getProductItems(productList, products, subMap);
  if (cartData.quote !== '' && cartData.quote !== null) {
    let params = {
      currency: 'USD',
      value: parseFloat(cartData.quote?.grand_total),
      line_items: productItems,
      order_quantity: productItems.quantity,
    };
    EventAction('track', TagNames.Checkout, params);
  }
};

const pinterestAddToCart = (productList, products) => {
  let productItems = getProductItems(productList, products);
  let params = {
    currency: 'USD',
    line_items: productItems,
  };
  EventAction('track', TagNames.AddToCart, params);
};

export { PinterestPageVisit, pinterestPurchaseOrder, pinterestAddToCart };
