import { REGEX_EXPRESSIONS } from '../Regex';

const VALIDATION_MESSAGES = {
  INPUT_REQUIRED: 'This field is required',
  NAME_PATTERN:
    "[Allows only ' and - Any other special characters or numbers are not allowed]",
  EMAIL_PATTERN: 'Please enter a valid email address (Ex: johndoe@domain.com)',
  PASSWORD_PATTERN: 'Please enter a valid password',
  EMAIL_MIN_LENGTH: 'Email must be at least 6 characters long',
  EMAIL_MAX_LENGTH: 'Email must be less than 255 characters long',
  ADDRESS_PATTERN: 'Special characters are not allowed',
  ZIP_CODE_PATTERN: 'Please enter a valid zip code',
  PHONE_PATTERN: '',
  PHONE_NUMBER_LENGTH: 'Phone number must be 10 digits',
  CREDIT_CARD_CVV_PATTERN: 'Invalid CVV',
  CREDIT_CARD_CVV_LENGTH: 'Please enter a CVV of 3 or 4 digits',
  DATE_IN_PAST: 'Please enter a valid expiration month and year',
  EXPIRY_PATTERN: 'Please enter a valid expiration date',
  PHONE_NUMBER_PATTERN: 'Please enter a valid phone number',
  CREDIT_CARD_NUMBER_PATTERN: 'Please enter a valid card number',

  // TODO: Need to check unused constants and remove it.
  FIRST_NAME_REQUIRED: 'This field is required',
  FIRST_NAME_MIN_LENGTH: 'First name must be at least 2 characters',
  FIRST_NAME_ONLY_ALPHABETS:
    "[Allows only ' and - Any other special characters or numbers are not allowed]",
  LAST_NAME_REQUIRED: 'Last name is required',
  LAST_NAME_MIN_LENGTH: 'Must be at least 2 characters long',
  LAST_NAME_ONLY_ALPHABETS: 'Numbers and special characters are not allowed',
  EMAIL_REQUIRED: 'Email address is required',
  PHONE_REQUIRED: 'Phone number is required',
  FULL_NAME_REQUIRED: 'Card holder name is required',
  FULL_NAME_MIN_LENGTH: 'Must be at least 2 characters long',
  FULL_NAME_PATTERN: 'Please enter a valid name',
  CREDIT_CARD_NUMBER_REQUIRED: 'Card number is required',

  // CREDIT_CARD_EXPIRY_MONTH_REQUIRED : "",
  // CREDIT_CARD_EXPIRY_MONTH_PAST : "",
  // CREDIT_CARD_EXPIRY_YEAR_REQUIRED : "",
  // CREDIT_CARD_EXPIRY_YEAR_PAST : "",
  CREDIT_CARD_CVV_REQUIRED: 'CVV is required',

  STREET_ADDRESS_ONE_REQUIRED: 'Street address 1 is required',
  STREET_ADDRESS_ONE_PATTERN: 'Please enter a valid street address',
  STREET_ADDRESS_TWO_REQUIRED: 'Street address 2 is required',
  STREET_ADDRESS_TWO_PATTERN: 'Please enter a valid street address',
  STREET_ADDRESS_ALPHANUMERIC_PATTERN: 'Special characters are not allowed',
  CITY_REQUIRED: 'City is required',
  CITY_MIN_LENGTH: 'Must be at least 2 characters long',
  CITY_PATTERN: 'Please enter a valid city name',
  ZIP_CODE_REQUIRED: 'Zip code is required',
  MESSAGE_REQUIRED: 'Message is required',
  ORDER_DATE_REQUIRED: 'Order date is required',
  ZIP_CODE_MIN_LENGTH: 'Please enter a valid zip code',
  PASSWORD_REQUIRED: 'Password is required',
  PASSWORD_MIN_LENGTH: 'Password should be minimum of 8 characters',
  PASSWORD_PATTERN:
    'Password should contain atleast one number, one capital alphabet and one special character',
  CONFIRM_PASSWORD_REQUIRED: 'Please re-enter your password',
  PASSWORDS_DONT_MATCH: "Passwords don't match",
  CURRENT_PASSWORD_REQUIRED: 'Please enter your current password',
  NEXT_ORDER_DATE: 'Next order date is required',
  CONFIRM_SUBSCRIPTION: 'You must agree to the terms above!',
  EMAIL_SUBJECT: 'Subject is required',
  EMAIL_BODY: 'Message is required',
};

const getValidationText = {
  maxLength: (name, length, text = '') =>
    `${name} must be at most ${length} characters` + text,
  minLength: (name, length) => `${name} must be at least ${length} characters`,
};

const validationTypes = {
  firstName: {
    required: true,
    minLength: 2,
    maxLength: 50,
    pattern: REGEX_EXPRESSIONS.FIRST_NAME,
  },
  lastName: {
    required: true,
    minLength: 2,
    maxLength: 50,
    pattern: REGEX_EXPRESSIONS.LAST_NAME,
  },
  email: {
    required: true,
    minLength: 6,
    maxLength: 255,
    pattern: REGEX_EXPRESSIONS.EMAIL,
  },
  password: {
    required: true,
    minLength: 6,
    maxLength: 30,
    pattern: REGEX_EXPRESSIONS.PASSWORD,
  },
  phone: {
    required: true,
    minLength: 10,
    maxLength: 17,
    pattern: {
      value: REGEX_EXPRESSIONS.PHONE,
    },
  },
  address1: {
    required: true,
    minLength: 2,
    maxLength: 35,
  },
  address2: {
    required: false,
    maxLength: 50,
    pattern: {
      value: REGEX_EXPRESSIONS.ADDRESS,
    },
  },
  city: {
    required: true,
    minLength: 2,
    maxLength: 50,
    pattern: REGEX_EXPRESSIONS.CITY_NAME,
  },
  state: {
    required: true,
  },
  zip: {
    required: true,
    minLength: 5,
    maxLength: 10,
    // pattern: {
    //   value: REGEX_EXPRESSIONS.ZIP_CODE,
    // },
  },
  nameOnCard: {
    required: true,
    minLength: 2,
    maxLength: 50,
    pattern: REGEX_EXPRESSIONS.FULL_NAME,
  },
  cardNumber: {
    required: true,
    minLength: 13,
    maxLength: 19,
    // pattern: REGEX_EXPRESSIONS.CREDIT_CARD_GENERAL_AMEX,
  },
  expirationYear: {
    required: true,
  },
  expirationMonth: {
    required: true,
  },
  expirationDetails: {
    required: true,
    maxLength: 6,
    // pattern: REGEX_EXPRESSIONS.EXPIRY,
  },
  cvv: {
    required: true,
    minLength: 3,
    maxLength: 4,
  },
  comments: {
    required: true,
  },
  confirmSubscription: {
    required: true,
  },
  emailSubject: {
    required: true,
  },
  emailBody: {
    required: true,
  },
  fullname: {
    required: true,
    minLength: 2,
    maxLength: 50,
    pattern: REGEX_EXPRESSIONS.FIRST_NAME,
  },
};
const validationFieldMessages = {
  firstName: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'First name',
      validationTypes.firstName.minLength
    ),
    maxLength: getValidationText.maxLength(
      'First name',
      validationTypes.firstName.maxLength
    ),
    pattern: VALIDATION_MESSAGES.NAME_PATTERN,
  },
  lastName: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'Last name',
      validationTypes.lastName.minLength
    ),
    maxLength: getValidationText.maxLength(
      'Last name',
      validationTypes.lastName.maxLength
    ),
    pattern: VALIDATION_MESSAGES.NAME_PATTERN,
  },
  email: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: VALIDATION_MESSAGES.EMAIL_MIN_LENGTH,
    maxLength: VALIDATION_MESSAGES.EMAIL_MAX_LENGTH,
    pattern: VALIDATION_MESSAGES.EMAIL_PATTERN,
  },
  password: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: VALIDATION_MESSAGES.EMAIL_MIN_LENGTH,
    maxLength: VALIDATION_MESSAGES.EMAIL_MAX_LENGTH,
    pattern: VALIDATION_MESSAGES.EMAIL_PATTERN,
  },
  phone: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: VALIDATION_MESSAGES.PHONE_NUMBER_PATTERN,
    maxLength: VALIDATION_MESSAGES.PHONE_NUMBER_PATTERN,
    pattern: VALIDATION_MESSAGES.PHONE_NUMBER_PATTERN,
  },
  address1: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'Address line',
      validationTypes.address1.minLength
    ),
    maxLength: getValidationText.maxLength(
      'Address line',
      validationTypes.address1.maxLength,
      '. Please use line 2 if necessary.'
    ),
  },
  address2: {
    pattern: VALIDATION_MESSAGES.ADDRESS_PATTERN,
  },
  city: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'City',
      validationTypes.city.minLength
    ),
    maxLength: getValidationText.maxLength(
      'City',
      validationTypes.city.maxLength
    ),
    pattern: VALIDATION_MESSAGES.CITY_PATTERN,
  },
  state: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    validate: VALIDATION_MESSAGES.INPUT_REQUIRED,
  },
  zip: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'Zip',
      validationTypes.zip.minLength
    ),
    maxLength: getValidationText.maxLength(
      'Zip',
      validationTypes.zip.maxLength
    ),
    pattern: VALIDATION_MESSAGES.ZIP_CODE_PATTERN,
  },
  nameOnCard: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'Name',
      validationTypes.nameOnCard.minLength
    ),
    maxLength: getValidationText.maxLength(
      'Name',
      validationTypes.nameOnCard.maxLength
    ),
    pattern: VALIDATION_MESSAGES.NAME_PATTERN,
  },
  cardNumber: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: VALIDATION_MESSAGES.CREDIT_CARD_NUMBER_PATTERN,
    maxLength: VALIDATION_MESSAGES.CREDIT_CARD_NUMBER_PATTERN,
    pattern: VALIDATION_MESSAGES.CREDIT_CARD_NUMBER_PATTERN,
  },
  expirationYear: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    validate: VALIDATION_MESSAGES.INPUT_REQUIRED,
    selectedDefault: VALIDATION_MESSAGES.INPUT_REQUIRED,
    pattern: VALIDATION_MESSAGES.INPUT_REQUIRED,
  },
  expirationDetails: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    validate: VALIDATION_MESSAGES.INPUT_REQUIRED,
    selectedDefault: VALIDATION_MESSAGES.INPUT_REQUIRED,
    dateInPast: VALIDATION_MESSAGES.DATE_IN_PAST,
    pattern: VALIDATION_MESSAGES.EXPIRY_PATTERN,
  },
  expirationMonth: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    validate: VALIDATION_MESSAGES.INPUT_REQUIRED,
    selectedDefault: VALIDATION_MESSAGES.INPUT_REQUIRED,
    dateInPast: VALIDATION_MESSAGES.DATE_IN_PAST,
    pattern: VALIDATION_MESSAGES.INPUT_REQUIRED,
  },
  cvv: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: VALIDATION_MESSAGES.CREDIT_CARD_CVV_LENGTH,
    maxLength: VALIDATION_MESSAGES.CREDIT_CARD_CVV_LENGTH,
    pattern: VALIDATION_MESSAGES.CREDIT_CARD_CVV_PATTERN,
  },
  comments: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
  },
  radioButton: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
  },
  confirmSubscription: {
    required: VALIDATION_MESSAGES.CONFIRM_SUBSCRIPTION,
  },
  emailSubject: {
    required: VALIDATION_MESSAGES.EMAIL_SUBJECT,
  },
  emailBody: {
    required: VALIDATION_MESSAGES.EMAIL_BODY,
  },
  fullname: {
    required: VALIDATION_MESSAGES.INPUT_REQUIRED,
    minLength: getValidationText.minLength(
      'Name',
      validationTypes.nameOnCard.minLength
    ),
    maxLength: getValidationText.maxLength(
      'Name',
      validationTypes.nameOnCard.maxLength
    ),
    pattern: VALIDATION_MESSAGES.NAME_PATTERN,
  },
};
export { VALIDATION_MESSAGES, validationTypes, validationFieldMessages };
