import { useRouter } from 'next/router';
import styles from './ErrrorPage.module.scss';

const ErrrorPage = () => {
  const router = useRouter();
  const handleRefresh = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.errorMessage}>Oops!</h5>
      <h2 className={`${styles.pagenotFound}`}></h2>
      <h2 className={`${styles.pagenotFound}`}>
        Something went wrong with your cart.!
      </h2>
      <p>
        To reload you cart again Click “Reload” below or Start a new cart by
        clicking “ Refresh” below.
      </p>
      <div className={styles.buttonContainer}>
        <a href={router.asPath} className={styles.reloadButton}>
          Reload
        </a>
        <a href={'/'} onClick={handleRefresh} className={styles.refreshButton}>
          Refresh
        </a>
      </div>
    </div>
  );
};

export default ErrrorPage;
