import Head from 'next/head';
import { config } from '../../../config';
import { productImages } from '../../../src/images';
import { structuredData } from '../../../commons/Constants';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const defaulImage = productImages['rfShareLogo'];

const Index = ({
                 title = "",
                 description = "",
                 schemaData = null,
                 hasCanonical = null,
               }) => {
  const router = useRouter();
  const path_url = config.SiteURL.slice(0, -1);
  const [canonicalURL, setCanonicalURL] = useState(
    path_url + (hasCanonical || router.pathname)
  );

  let defaultTitle = "";
  let defaultDescription = "";
  let companyTitle = 'Relief Factor Co.'
  if (router.pathname === "/products/z-factor") {
    defaultTitle = `Z Factor: 100% Drug-Free Sleep Supplement | ${companyTitle}`;
    defaultDescription = "Z Factor is a 100% drug-free, non-addictive sleep supplement that leaves you feeling rested and restored after a good night's sleep!"

  } else if (router.pathname === "/products/go-factor") {
    defaultTitle = `Go Factor: 100% Drug-Free Energy Boost | ${companyTitle}`;
    defaultDescription = "Need a Boost? Go Factor helps you keep going, and helps your body make better use of your caloric intake, keeping you sharp and in the game."
  } else if (router.pathname === "/products/zen-factor") {
    defaultTitle = `Zen Factor: All-Natural Anxiety Relief | ${companyTitle}`;
    defaultDescription = "Zen Factor is a 100% Drug-Free, Non-Addictive Supplement that can help you RELAX in the face of anxiety, FOCUS when you need it, & REDUCE everyday stress."
  } else if (router.pathname === "/products/relief-factor") {
    defaultTitle = `Relief Factor: All-Natural Pain Relief | ${companyTitle}`;
    defaultDescription = 'Relief Factor combines four-ingredients to help your body fight pain and inflammation. Within weeks, it can reduce or even eliminate pain.';
  } 
  else if (router.pathname === "/support"){
    defaultDescription = 'Support';
    defaultTitle = `Support | ${companyTitle}`;
  }
  else if (router.pathname === "/reviews"){
    defaultDescription = 'Reviews';
    defaultTitle = `Reviews | ${companyTitle}`;
  }
  else if (router.pathname === "/policies/shipping"){
    defaultDescription = 'We follow these shipping policies to ensure prompt fulfillment and delivery of Relief Factor products to your door.';
    defaultTitle = `Shipping Policies | ${companyTitle}`;
  }
  else if (router.pathname === "/policies/returns"){
    defaultDescription = 'We always hope you are happy with your Relief Factor purchase. If you ever need to return an order, we make it easy by following this simple return policy.';
    defaultTitle = `Return Policies | ${companyTitle}`;
  }
  else if (router.pathname === "/policies/money-back"){
    defaultDescription = 'Feel Better or Your Money Back';
    defaultTitle = `Feel Better or Your Money Back | ${companyTitle}`;
  }
  else if (router.pathname === "/policies/privacy"){
    defaultDescription = 'We take Relief Factor customer data privacy very seriously, and use the following policies to protect our customer’s privacy and data.';
    defaultTitle = `Privacy Policy | ${companyTitle}`;
  }
  else if (router.pathname === "/login"){
    defaultDescription = 'Relief Factor QuickStart is a convenient 3-week trial, including 49 packets and a 91-page anti-inflammatory diet guide. Get relief today!';
    defaultTitle = `Login | ${companyTitle}`;
  }
  else if (router.pathname === "/forgotPassword"){
    defaultDescription = 'Forgot User Password';
    defaultTitle = `Forgot Password | ${companyTitle}`;
  }
  else if (router.pathname === "/account"){
    defaultDescription = 'Account';
    defaultTitle = `My Account | ${companyTitle}`;
  }
  else if (router.pathname === "/account/subscriptions"){
    defaultDescription = 'Subscription';
    defaultTitle = `My Account: Subscriptions | ${companyTitle}`;
  }
  else if (router.pathname.includes("/subscription/")){
    defaultDescription = 'Edit Subscription';
    defaultTitle = `My Account: Edit Subscription | ${companyTitle}`;
  }
  else if (router.pathname === "/account/orders"){
    defaultDescription = 'Order History';
    defaultTitle = `My Account: Order History | ${companyTitle}`;
  }
  else if (router.pathname === "/createpassword"){
    defaultDescription = 'Create New Password';
    defaultTitle = `Reset Password | ${companyTitle}`;
  }
  else if (router.pathname === "/paymentexpired"){
    defaultDescription = 'Payment Expired';
    defaultTitle = `My Account: Update Payment Method | ${companyTitle}`;
  }
  else if (router.pathname === "/reschedule-order"){
    defaultDescription = 'Reschedule Order';
    defaultTitle = `Change Shipping Date | ${companyTitle}`;
  }
  else if (router.pathname === "/ingredients"){
    defaultDescription = 'Ingredients';
    defaultTitle = `Relief Factor Ingredients | ${companyTitle}`;
  }
   else {
    defaultTitle = 'Relief Factor Co. Celebrating 10 Years of 100% Drug-Free Wellness';
    defaultDescription = 'Relief Factor Co.\'s mission is help you live your best life with 100% drug-free supplements.  We\'ve safely served 1+ million customers, over 10+ years.';
  }

  useEffect(() => {
    setCanonicalURL(path_url + (hasCanonical || router.pathname));
  }, [hasCanonical]);
  return (
    <Head>
      <title>{defaultTitle}</title>
      <meta name="description" content={defaultDescription}/>
      <link key="canonical-url" rel="canonical" href={`${canonicalURL}`}/>
      <meta property="og:title" content={defaultTitle} key="ogtitle"/>
      <meta
        property="og:description"
        content={defaultDescription}
        key="ogdesc"
      />
      <meta property="og:url" content={config.SiteURL} key="ogurl"/>
      <meta
        property="og:image"
        content={`${config.SiteURL.slice(0, -1) + defaulImage}`}
        key="ogimage"
      />
      <meta property="og:site_name" content="Relief Factor" key="ogsitename"/>
      <meta property="og:type" content="website"/>
      {schemaData != null && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData[schemaData]),
          }}
        />
      )}
    </Head>
  );
};
export default Index;
