import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getYotpoBottomLine } from './yoptoApi';

export const yoptoState = {
  bottomline: {},
};
const initialState = {
  bottomline: null,
};

export const getBottomLineInfo = createAsyncThunk(
  'yopto/bottomline',
  async (productId) => {
    const response = await getYotpoBottomLine(productId);
    return response;
  }
);

export const yoptoSlice = createSlice({
  name: 'yopto',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBottomLineInfo.fulfilled, (state, action) => {
      state.bottomline = action.payload?.response?.bottomline;
    });
  },
});
export const bottomLine = (state) => state.yotpo.bottomline;
export default yoptoSlice.reducer;
