import { COOKIE, LOCAL_STORAGE, SESSION_STORAGE } from '../Constants';

export const isSessionStorageEnabled = () => {
  try {
    const key = '__storage__test';
    sessionStorage.setItem(key, null);
    sessionStorage.removeItem(key, null);
    return true;
  } catch (err) {
    return false;
  }
};
// Unfinished
export const isLocalStorageEnabled = () => {
  try {
    const key = '__storage__test';
    localStorage.setItem(key, null);
    localStorage.removeItem(key, null);
    return true;
  } catch (err) {
    return false;
  }
};

export const isCookieEnabled = () => {
  var cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') != -1;
  }
  return cookieEnabled;
};

export const isBrowserFeature = (feature) => {
  switch (feature) {
    case SESSION_STORAGE:
      return isSessionStorageEnabled();
    case LOCAL_STORAGE:
      return isLocalStorageEnabled();
    case COOKIE:
      return isCookieEnabled();
    default:
      return false;
  }
};
