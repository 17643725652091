import styles from './TextWithArrowAnimation.module.scss';
import { SvgIcon, Typography } from '@mui/material';
import ArrowIcon from '../../../../src/images/v5/arrow.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const TextWithArrowAnimation = ({
  children,
  underline = false,
  type = 'dark',
  ...rest
}) => {
  let typeVars = { variant: 'h5' };
  let dark = type == 'dark';
  if (dark) {
    typeVars['color'] = 'primary.main';
  } else typeVars['color'] = 'primary.white';

  return (
    <div
      className={`${styles.textContainer} ${
        underline ? styles.underline : ''
      } ${dark ? '' : styles.lightArrow}`}
    >
      <Typography {...typeVars} {...rest} noWrap>
        {children}
      </Typography>

      <SvgIcon>
        <ArrowForwardIcon />
      </SvgIcon>
    </div>
  );
};

export default TextWithArrowAnimation;
