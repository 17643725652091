import apiCall from '../../../commons/helpers/apiCall';

export async function fetchAdBlockerStatus() {
  const result = await fetch(`https://www.google-analytics.com/j/collect?v=1`)
    .then((res) => {
      return !(res.status === 200 || res.status === 204);
    })
    .catch((err) => {
      return true;
    });
  return result;
}

export async function fetchCountryStatesList(country) {
  const result = await apiCall({
    endpoint: `country-states/${country}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
    authorize: false,
  });
  return result;
}
