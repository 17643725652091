//css
import styles from './navbar.module.scss';
import {
  Grid,
  Stack,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@mui/material';
import Link from 'next/link';

import { businessHourTiming } from '../../../commons/utils/utils';

import {
  painRelief,
  rfZenFactor1,
  rfZFactor1,
  rfGoFactor1,
} from '../../../src/images';
import PHONE_ICON from '../../../src/assets/images/icons/phone-side-nav.svg';
import SUPPORT_ICON from '../../../src/assets/images/icons/support-side-nav.svg';
import ACCOUNT_ICON from '../../../src/assets/images/icons/account.svg';
import SHIPPING_ICON from '../../../src/assets/images/icons/shipping.svg';

import INSTA_ICON from '../../../src/assets/images/icons/insta.svg';
import TWITTER_ICON from '../../../src/assets/images/icons/twitter.svg';
import FACEBOOK_ICON from '../../../src/assets/images/icons/facebook.svg';
import YOUTUBE_ICON from '../../../src/assets/images/icons/youtube.svg';

const isOnline = businessHourTiming();
const CustomMenuList = ({
  item,
  color = 'primary.main',
  sx,
  showOnline = false,
}) => {
  return (
    <>
      <Link href={item.link} underline="none">
        <MenuItem sx={{ ...sx }} disableRipple>
          {item.icon && (
            <ListItemIcon sx={{ justifyContent: 'left' }}>
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText sx={{ justifyContent: 'left' }}>
            <Typography variant="h5" color={color}>
              <a style={{ display: 'flex', alignItems: 'center' }}>
                {' '}
                {item.title}
                {showOnline && (
                  <Badge
                    className={`${
                      isOnline ? styles.onlineStatus : styles.offlineStatus
                    }`}
                    sx={{
                      pl: 2,
                      pb: 0.5,
                      color: 'warning',
                    }}
                    variant="dot"
                  />
                )}
              </a>
            </Typography>
          </ListItemText>
        </MenuItem>
      </Link>
    </>
  );
};

export default function Navbar({ height, isDark, router }) {
  const color = 'primary.black';

  const navProductMenu = [
    {
      img: painRelief,
      name: 'Relief Factor',
      category: 'Pain Relief',
      link: '/products/relief-factor',
    },
    {
      img: rfZenFactor1,
      name: 'Zen Factor',
      category: 'Anxiety Relief',
      link: '/products/zen-factor',
    },
    {
      img: rfGoFactor1,
      name: 'Go Factor',
      category: 'Energy',
      link: '/products/go-factor',
    },
    {
      img: rfZFactor1,
      name: 'Z Factor',
      category: 'Sleep',
      link: '/products/z-factor',
    },
  ];
  const bottomFooterMenu = [
    {
      title: 'Account',
      icon: <ACCOUNT_ICON />,
      link: '/login',
    },
    {
      title: 'Sales: 888-488-3112',
      icon: <PHONE_ICON />,
      link: 'tel:+1-888-488-3112',
      showOnline: true,
    },
    {
      title: 'Support',
      icon: <SUPPORT_ICON />,
      link: '/support',
    },
    {
      title: 'Shipping Policies',
      icon: <SHIPPING_ICON />,
      link: '/policies/shipping',
    },
    {
      title: 'Returns Policy',
      icon: <SHIPPING_ICON />,
      link: '/policies/returns',
    },
  ];

  const socialMenu = {
    items: [
      {
        icon: <INSTA_ICON color="#004152" />,
        link: 'https://www.instagram.com/relieffactor/',
      },
      {
        icon: <TWITTER_ICON color="#004152" />,
        link: 'https://twitter.com/relieffactor',
      },
      {
        icon: <FACEBOOK_ICON color="#004152" />,
        link: 'https://facebook.com/relieffactor',
      },
      {
        icon: <YOUTUBE_ICON color="#004152" />,
        link: 'https://www.youtube.com/@relieffactor/',
      },
    ],
  };

  return (
    <Stack
      display="flex"
      flexDirection="column"
      justifyContent="start"
      columnGap={2}
      maxWidth="md"
      sx={{ px: 5 }}
      className={styles['nav-bg']}
    >
      <div
        style={{
          height,
          width: '100%',
          transition: 'all .2s',
        }}
      />
      <Grid
        container
        alignItems="center"
        sx={{justifyContent:'center'}}
        columns={{ sm: 12 }}
        columnGap={{ sm: 0, xs: 2, lg: 0 }}
      >
        {navProductMenu.map((item, i) => (
          <Grid item xs="auto" sm={6} key={i}>
            <Link href={item.link} underline="none">
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CardMedia
                  component="img"
                  alt=""
                  sx={{ width: { xs: 110, sm: 120, md: 120 } }}
                  image={item.img}
                  className={styles.cardImg}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="action.gray"
                    component="div"
                    align="center"
                  >
                    {item.category}
                  </Typography>
                  <Typography variant="h4" color="primary.main" align="center">
                    {item.name}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          bgcolor: 'primary.black',
          width: '100%',
          opacity: 0.7,
          margin: 'auto',
        }}
      />
      <Stack
        display="flex"
        flexDirection="column"
        sx={{ pt: 5 }}
        justifyContent="flex-start"
      >
        {bottomFooterMenu.map((item, i) => (
          <CustomMenuList
            key={i}
            item={item}
            index={i}
            color={color}
            sx={{ px: 0 }}
            showOnline={item.showOnline || false}
          />
        ))}
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{ py: 2 }}
        columnGap={1}
      >
        {socialMenu.items.map((item, i) => (
          <Link href={item.link}>
            <MenuItem sx={{ p: 0 }} key={i}>
              <ListItemIcon sx={{ justifyContent: 'left' }}>
                {item.icon}
              </ListItemIcon>
            </MenuItem>
          </Link>
        ))}
      </Stack>
    </Stack>
  );
}
