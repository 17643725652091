import styles from './DotsCircular.module.scss';

const DotsCircular = ({ contrast = false,custom_class_name }) => {
  return (
    <div  className={`${styles.skChase} ${custom_class_name}`}>
      <div
        className={`${styles.skChaseDot} ${contrast ? styles.theme : ''}`}
      ></div>
      <div
        className={`${styles.skChaseDot} ${contrast ? styles.theme : ''}`}
      ></div>
      <div
        className={`${styles.skChaseDot} ${contrast ? styles.theme : ''}`}
      ></div>
      <div
        className={`${styles.skChaseDot} ${contrast ? styles.theme : ''}`}
      ></div>
      <div
        className={`${styles.skChaseDot} ${contrast ? styles.theme : ''}`}
      ></div>
      <div
        className={`${styles.skChaseDot} ${contrast ? styles.theme : ''}`}
      ></div>
    </div>
  );
};

export default DotsCircular;
