const TagNames = {
  PageView: 'PageView',
  ViewContent: 'ViewContent',
  AddToCart: 'AddToCart',
  StartTrial: 'StartTrial',
  Subscribe: 'Subscribe',
  Purchase: 'Purchase',
  CancelSubscription: 'CancelSubscription',
  SleepTrial: 'SleepTrials',
};
export { TagNames };
