import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UN_AUTHORIZED } from '../../../commons/Constants';
import {
  fetchListAllOrders,
  fetchOrderDetails,
  fetchOrderStatusDetails,
  fetchOrderEventsDetails,
} from './orderApi';

export const orderState = {
  ordersList: {},
  orderDetails: {},
  orderListMeta: {},
  orderStatusList: [],
  orderEventDetails: {},
};

const initialState = {
  ordersList: [],
  orderListMeta: {},
  orderDetails: {},
  orderStatusList: [],
  orderEventDetails: {},
};

export const getListAllOrders = createAsyncThunk(
  'user/fetchListAllOrders',
  async (orderDetails) => {
    const response = await fetchListAllOrders(orderDetails);
    let orderlist = response.result.map((order) => {
      if (order.track_info[0]) {
        order.track_number = order.track_info[0].track_number;
        order.tracking_status = order.track_info[0].title
          .toUpperCase()
          .split(' ')
          .join('');
        order.carrier_code = order.track_info[0].carrier_code
          .toUpperCase()
          .split(' ')
          .join('');
        delete order.track_info;
      }
      return order;
    });
    return {
      meta: response.meta,
      result: orderlist,
    };
  }
);

export const getOrderDetails = createAsyncThunk(
  'user/fetchOrderDetails',
  async (orderId) => {
    const response = await fetchOrderDetails(orderId);
    let orderdetails = {
      ...response[0],
    };
    if (orderdetails.track_info && orderdetails.track_info[0]) {
      orderdetails.track_number = orderdetails.track_info[0].track_number;
      orderdetails.latest_status = orderdetails.track_info[0].latest_category;
      orderdetails.events = orderdetails.track_info[0].events;
      orderdetails.tracking_status = orderdetails.track_info[0].title
        .toUpperCase()
        .split(' ')
        .join('');
      orderdetails.carrier_code = orderdetails.track_info[0].carrier_code
        .toUpperCase()
        .split(' ')
        .join('');
      delete orderdetails.track_info;
    }
    return orderdetails;
  }
);

export const getAllOrdersStatus = createAsyncThunk(
  'user/fetchOrderStatusDetails',
  async (orderIds) => {
    const response = await fetchOrderStatusDetails(orderIds);
    return {
      result: response.data,
    };
  }
);

export const getOrdersEvents = createAsyncThunk(
  'user/fetchOrderEventsDetails',
  async (trackNumber) => {
    const response = await fetchOrderEventsDetails(trackNumber);
    return {
      result: response.data,
    };
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrder: (state) => initialState,
    resetShippingEvents: (state, action) => {
      state.orderEventDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListAllOrders.fulfilled, (state, action) => {
        state.ordersList = action.payload?.result;
        state.orderListMeta = action?.payload?.meta;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.orderDetails = {
          ...state.orderDetails,
          [action.payload.entity_id]: action.payload,
        };
      })
      .addCase(getAllOrdersStatus.fulfilled, (state, action) => {
        state.orderStatusList = action.payload?.result;
      })
      .addCase(getOrdersEvents.fulfilled, (state, action) => {
        state.orderEventDetails = action.payload?.result;
      })
      .addMatcher(
        (action) =>
          action.type.endsWith('rejected') &&
          action?.error?.message === UN_AUTHORIZED,
        (state, action) => {
          return initialState;
        }
      );
  },
});

export const ordersList = (state) => state.order.ordersList;
export const orderListMeta = (state) => state.order.orderListMeta;
export const orderDetails = (state) => state.order.orderDetails;
export const allOrderStatus = (state) => state.order.orderStatusList;
export const getOrderEvents = (state) => state.order.orderEventDetails;
export const { resetOrder, resetShippingEvents } = orderSlice.actions;
export default orderSlice.reducer;
