export default function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path d="M0.569336 0.75L20.0088 20.1886" stroke="#004152" />
      <path d="M20.0088 0.75L0.569335 20.1886" stroke="#004152" />
    </svg>
  );
}
