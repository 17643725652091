const Content = ({ children }) => {
  return (
    <>
      {/* //Todo: Extract individual containers and create a universal style for all containers */}
      {/* <main className={styles.contentContainer}> */}
      {children}
      {/* </main> */}
    </>
  );
};

export default Content;
