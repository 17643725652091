//Next
import Image from 'next/image';
import { useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../src/app/hooks';
import { encryptionForPaymentInfo } from '../../../commons/utils/encryption-forge';

//mui
import { styled } from '@mui/material/styles';
import {
  Typography,
  Divider,
  Grid,
  MenuList,
  MenuItem,
  ListItemText,
  Paper,
  InputBase,
  ListItemIcon,
  Stack,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from '@mui/material';

import Link from 'next/link';
import { useRouter } from 'next/router';
import ARROW_RIGHT from '../../../src/assets/images/icons/arrow-right.svg';
import INSTA_ICON from '../../../src/assets/images/icons/insta.svg';
import TWITTER_ICON from '../../../src/assets/images/icons/twitter.svg';
import FACEBOOK_ICON from '../../../src/assets/images/icons/facebook.svg';
import YOUTUBE_ICON from '../../../src/assets/images/icons/youtube.svg';

import {
  leaf1,
  leaf2,
  leaf3,
  leaf4,
  veteranImage,
  rfFooterLogo,
} from '../../../src/images';

import imgLoader from '../../../imgloader';

// css
import styles from './footer.module.scss';
import {
  insertNewsletter,
  resetNewsletter,
  setInputMessage,
} from '../../../src/features/newsletter/newsletterState';
import { useSelector } from 'react-redux';
import { REGEX_EXPRESSIONS } from '../../../commons/Regex';
import { LoadingButton } from '@mui/lab';
import { config } from '../../../config';

const FooterMainMenu = ({ menuItems }) => {
  return (
    <div className={styles.footerMainMenuList}>
      <Typography variant="h4" color="primary.white">
        {menuItems.name}
      </Typography>
      <Divider color="#FBF2E5" sx={{ my: 1.5 }} />
      <MenuList sx={{ p: 0 }}>
        {menuItems.items.map((item) => (
          <Link href={item.link} underline="none">
            <a target={item.target ? '_blank' : '_parent'}>
              <MenuItem
                tabIndex={0}
                sx={{ pl: 0 }}
                key={item.name}
                className={styles.footerMainMenu}
              >
                <ListItemText>
                  <Typography variant="h5" color="primary.white">
                    {item.name}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </a>
          </Link>
        ))}
      </MenuList>
    </div>
  );
};

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
          transform: 'scale(1.8)',
        },
      }}
    >
      <Typography className="expandIconWrapper" color="primary.white">
        -
      </Typography>
      <Typography className="collapsIconWrapper" color="primary.white">
        +
      </Typography>
    </Box>
  );
};

const FooterMainMenuAccordion = ({ menuItems }) => {
  return (
    <>
      <Accordion className={styles['footer-menu-accordion']}>
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={styles['footer-menu-accordion-summary']}
        >
          <Typography
            variant="h4"
            color="primary.white"
            className={styles.menu_title}
          >
            {menuItems.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles['footer-menu-accordion-details']}>
          <MenuList sx={{ p: 0 }}>
            {menuItems.items.map((item) => (
              <Link
                variant="h5"
                color="primary.white"
                href={item.link}
                underline="none"
              >
                <a target={item.target ? '_blank' : '_parent'}>
                  <MenuItem
                    sx={{ pl: 0, py: { xs: 1 } }}
                    key={item.name}
                    className={styles.footerMainMenu}
                  >
                    <ListItemText>
                      <Typography variant="h5" color="primary.white">
                        {item.name}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </a>
              </Link>
            ))}
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const StyledBackground = styled(Container)(({ theme }) => ({
  background: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    borderRadius: '15px 15px 0px 0px',
  },
  [theme.breakpoints.only('md')]: {
    width: '95%',
    borderRadius: '6px',
    marginBottom: '20px',
    bottom: '20px',
  },
}));

const StyledBackgroundImage = styled('div')(() => ({}));
const StyledImage1 = styled('img')(() => ({}));
const StyledImage2 = styled('img')(() => ({}));
const StyledImage3 = styled('img')(() => ({}));
const StyledImage4 = styled('img')(() => ({}));

export default function Footer() {
  const shopMenu = {
    name: 'Shop',
    items: [
      {
        name: 'Pain Relief',
        link: '/products/relief-factor',
      },
      {
        name: 'Anxiety Relief',
        link: '/products/zen-factor',
      },
      {
        name: 'Energy',
        link: '/products/go-factor',
      },
      {
        name: 'Sleep',
        link: '/products/z-factor',
      },
    ],
  };

  const companyMenu = {
    name: 'Company',
    items: [
      {
        name: 'Resources',
        link: '/resources',
      },
      {
        name: 'Reviews',
        link: '/reviews',
      },
      {
        name: 'Business Inquiry',
        link: '/business-inquiry',
      },
      {
        name: 'Careers',
        link: 'https://recruiting.paylocity.com/recruiting/jobs/All/0b797ba8-6bdb-415b-8c3f-93c3b3e9e324/Relief-Factor-Co',
        target: true,
      },
    ],
  };

  const helpMenu = {
    name: 'Help',
    items: [
      {
        name: 'Support Center',
        link: '/support',
      },
      {
        name: 'Shipping Policies',
        link: '/policies/shipping',
      },
      {
        name: 'Returns Policy',
        link: '/policies/returns',
      },
      {
        name: 'Feel Better or Your Money Back',
        link: '/policies/money-back',
      },
    ],
  };

  const socialMenu = {
    name: 'Connect',
    items: [
      {
        icon: <INSTA_ICON fill="#fff" />,
        link: 'https://www.instagram.com/relieffactor/',
      },
      {
        icon: <TWITTER_ICON fill="#fff" />,
        link: 'https://twitter.com/relieffactor',
      },
      {
        icon: <FACEBOOK_ICON fill="#fff" />,
        link: 'https://facebook.com/relieffactor',
      },
      {
        icon: <YOUTUBE_ICON fill="#fff" />,
        link: 'https://www.youtube.com/@relieffactor/',
      },
    ],
  };

  const bottomFooterMenu = [
    {
      title: 'Privacy and Customer Data Policies',
      link: '/policies/privacy',
    },
  ];

  const obj = useSelector((state) => state.newsletter);

  const inputRef = useRef(null);
  const inputRefMob = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setInputMessage({ content: '', type: '' }));
  }, []);

  const handleClick = (e) => {
    const pattern = REGEX_EXPRESSIONS.EMAIL;
    pattern.lastIndex = 0;
    const width = screen?.width;
    const validationEmail =
      width >= 1023 ? inputRef.current.value : inputRefMob.current.value;

    if (validationEmail.match(pattern)) {
      const payload = {
        email:
          width >= 1023 ? inputRef.current.value : inputRefMob.current.value,
        platform: 'Customer Portal',
      };
      dispatch(insertNewsletter(payload));
      setTimeout(() => {
        dispatch(setInputMessage({ content: '', type: '' }));
      }, 10000);
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRefMob.current.value = '';
      }
    } else {
      dispatch(
        setInputMessage({
          content: 'Please enter a valid email address',
          type: 'error',
        })
      );
    }
  };

  const router = useRouter();

  const productsPath = [
    '/products/relief-factor',
    '/products/go-factor',
    '/products/zen-factor',
    '/products/z-factor',
  ];

  return (
    <Box
      className={
        router.pathname.includes(`/resources/`) &&
        router.query.category?.length > 0 &&
        router.query.blog?.length > 0
          ? styles.footerMainContainerResourcesCategory
          : router.pathname === '/resources' ||
            (router.pathname.includes('/resources/') &&
              router.query.category?.length > 0)
          ? styles.footerMainContainerResources
          : styles.footerMainContainer
      }
    >
      <StyledBackground className={styles.footerBg} maxWidth="xl">
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ maxWidth: { lg: 'xl' } }}
          zIndex={2}
          className={styles.footerGridContainer}
        >
          <Container
            sx={{
              maxWidth: {
                xl: '1159px',
                lg: '867px',
                md: '90%',
                sm: '85%',
                xs: '90%',
              },
            }}
            margin="auto"
          >
            <Grid item sx={{}}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                rowGap={{ lg: 20, md: 4, sm: 2, xs: 2 }}
              >
                <Grid item xs sx={{}}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    spacing={{ lg: 12, md: 1, sm: 1, xs: 1 }}
                    columns={{ lg: 12, xs: 1, sm: 1, md: 1 }}
                  >
                    <Grid item xs={2}>
                      <FooterMainMenu menuItems={shopMenu} />
                      <div className={styles.footerMobileNewletter}>
                        <Typography
                          variant="h5"
                          sx={{ mt: { lg: 5, md: 3, sm: 3, xs: 2 } }}
                          color="primary.white"
                          className={styles.h5}
                        >
                          Add Some Relief to Your Inbox
                        </Typography>
                        <div
                          className={
                            obj?.inputMessage?.type === 'error'
                              ? styles.newsLetterSuccessMessage2
                              : obj?.inputMessage?.type === 'success'
                              ? styles.newsLetterSuccessMessage1
                              : styles.newsLetterSuccessMessage2
                          }
                        >
                          <Divider color="#FBF2E5" sx={{ my: 1.5 }} />
                          <Typography
                            variant="p1"
                            color="primary.white"
                            className={styles.paragraph}
                          >
                            Sign up for sneak peaks, exclusive offers, and
                            scientific resources.
                          </Typography>
                        </div>
                        <Stack
                          display="flex"
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ mt: 2 }}
                        >
                          <div
                            className={
                              obj?.inputMessage?.type === 'error'
                                ? styles.newsLetterSuccessMessage2
                                : obj?.inputMessage?.type === 'success'
                                ? styles.newsLetterSuccessMessage1
                                : styles.newsLetterSuccessMessage2
                            }
                          >
                            <Paper
                              component="form"
                              onSubmit={(e) => e.preventDefault()}
                              className={
                                obj?.inputMessage?.type === 'error'
                                  ? styles.newsletterBorder
                                  : ''
                              }
                              sx={{
                                px: { lg: 1.5, sm: 0.7 },
                                py: 0.7,
                                mt: 3,
                                mb: obj?.inputMessage?.type !== null ? 1 : 3,
                                display: 'flex',
                                alignItems: 'center',
                                bgcolor: '#326470',
                                width: { sm: '100%', md: '50%' },
                              }}
                            >
                              <InputBase
                                inputRef={inputRefMob}
                                sx={{ ml: 1, flex: 1, color: 'primary.white' }}
                                placeholder="Email Address"
                                onChange={() => {
                                  obj?.inputMessage?.type !== null &&
                                    dispatch(
                                      setInputMessage({
                                        content: '',
                                        type: null,
                                      })
                                    );
                                  obj?.inputMessage?.type === 'success' &&
                                    dispatch(resetNewsletter({}));
                                }}
                                inputProps={{ 'aria-label': 'Email Addresss' }}
                              />

                              <LoadingButton
                                onClick={handleClick}
                                loading={obj.isPending}
                                id="message"
                                name="message"
                                sx={{
                                  textTransform: 'none',
                                  color: 'primary.white',
                                }}
                                className={styles.footerMainMenu}
                                endIcon={<ARROW_RIGHT />}
                              >
                                <span className={styles.rightArrowMobile}>
                                  submit
                                </span>
                              </LoadingButton>
                            </Paper>
                          </div>
                          <div
                            className={
                              obj?.inputMessage?.type === 'error'
                                ? styles.newsLetterSuccessMessage1
                                : obj?.inputMessage?.type === 'success'
                                ? styles.newsLetterSuccessMessage2
                                : styles.newsLetterSuccessMessage1
                            }
                          >
                            <p
                              className={
                                styles.newsLetterSuccessMessageChildOneMobile
                              }
                            >
                              {' '}
                              Thank you for subscribing!{' '}
                            </p>

                            <p
                              className={
                                styles.newsLetterSuccessMessageChildSecondMobile
                              }
                            >
                              {' '}
                              Check your email for a confirmation message.
                            </p>
                          </div>

                          <div className={styles.footerVateranTabImg}>
                            <Link href="https://www.veteranownedbusiness.com/business/37505/promedev-llc">
                              <a target="_blank" rel="noopener noreferrer">
                                <Image
                                  src={veteranImage}
                                  width={83}
                                  height={54}
                                  alt=""
                                  loader={imgLoader}
                                />
                              </a>
                            </Link>
                          </div>
                        </Stack>

                        <div
                          className={
                            obj?.inputMessage?.type === 'error'
                              ? styles.newsLetterSuccessMessage2
                              : obj?.inputMessage?.type === 'success'
                              ? styles.newsLetterSuccessMessage1
                              : styles.newsLetterSuccessMessage2
                          }
                        >
                          <div className={styles.newsLetterFormMessage}>
                            {!!obj?.inputMessage?.content && (
                              <Typography
                                variant="p2"
                                color="primary.white"
                                className={styles.newletterErrorMessage}
                                sx={{
                                  py: 0.5,
                                  px: 1.5,
                                  bgcolor:
                                    obj?.inputMessage?.type === 'error'
                                      ? '#C84940'
                                      : obj?.inputMessage?.type === 'success'
                                      ? 'green'
                                      : '',
                                  borderRadius: 1,
                                }}
                              >
                                {obj?.inputMessage?.content}
                              </Typography>
                            )}
                          </div>
                        </div>
                        <Stack
                          direction="row"
                          alignItems="flex-start"
                          columnGap={1}
                        >
                          {socialMenu.items.map((item, i) => (
                            <Link href={item.link}>
                              <MenuItem
                                sx={{ p: 0 }}
                                key={i}
                                className={styles.footerMainMenu}
                              >
                                <ListItemIcon sx={{ justifyContent: 'center' }}>
                                  <a target="_blank" rel="noopener noreferrer">
                                    {item.icon}
                                  </a>
                                </ListItemIcon>
                              </MenuItem>
                            </Link>
                          ))}
                        </Stack>
                        <Box
                          sx={{ mt: 2 }}
                          className={styles.footerMobileBadgeImg}
                        >
                          <Link href="https://www.veteranownedbusiness.com/business/37505/promedev-llc">
                            <a target="_blank" rel="noopener noreferrer">
                              <Image
                                src={veteranImage}
                                width={83}
                                height={54}
                                alt=""
                                loader={imgLoader}
                                className={styles.footerVeteranImg}
                              />
                            </a>
                          </Link>
                        </Box>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ pt: 3 }}
                      className={styles.footerMobileShopMenu}
                    >
                      <FooterMainMenuAccordion menuItems={shopMenu} />
                      <Divider color="#FBF2E5" sx={{ my: { lg: 1 } }} />
                    </Grid>

                    <Grid item xs={2} sx={{ p: 0 }}>
                      <FooterMainMenu menuItems={companyMenu} />
                      <FooterMainMenuAccordion menuItems={companyMenu} />
                      <Divider
                        className={styles.footerCompanyMenuDivder}
                        color="#FBF2E5"
                        sx={{ my: { lg: 1 } }}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ p: 0 }}>
                      <FooterMainMenu menuItems={helpMenu} />
                      <FooterMainMenuAccordion menuItems={helpMenu} />
                    </Grid>
                    <Grid item xs={2} className={styles.footerEmptyGrid}></Grid>
                    <Grid item xs={4} className={styles.footerDesktopNewletter}>
                      <Typography variant="h5" color="primary.white">
                        Add Some Relief to Your Inbox
                      </Typography>
                      <Divider color="#FBF2E5" sx={{ my: 1.5 }} />
                      <div
                        className={
                          obj?.inputMessage?.type === 'error'
                            ? styles.newsLetterSuccessMessage2
                            : obj?.inputMessage?.type === 'success'
                            ? styles.newsLetterSuccessMessage1
                            : styles.newsLetterSuccessMessage2
                        }
                      >
                        <Typography
                          variant="p1"
                          className={styles.paragraph}
                          color="primary.white"
                        >
                          Sign up for latest updates and exclusive offers.
                        </Typography>
                      </div>
                      <div
                        className={
                          obj?.inputMessage?.type === 'error'
                            ? styles.newsLetterSuccessMessage2
                            : obj?.inputMessage?.type === 'success'
                            ? styles.newsLetterSuccessMessage1
                            : styles.newsLetterSuccessMessage2
                        }
                      >
                        <Paper
                          component="form"
                          onSubmit={(e) => e.preventDefault()}
                          className={
                            obj?.inputMessage?.type === 'error'
                              ? styles.newsletterBorder
                              : ''
                          }
                          sx={{
                            px: 1.5,
                            py: 0.7,
                            mt: 3,
                            mb: obj?.inputMessage?.type !== null ? 1 : 3,
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: '#326470',
                          }}
                        >
                          <InputBase
                            inputRef={inputRef}
                            sx={{ ml: 1, flex: 1, color: 'primary.white' }}
                            placeholder="Email Address"
                            onChange={() => {
                              obj?.inputMessage?.type !== null &&
                                dispatch(
                                  setInputMessage({ content: '', type: null })
                                );
                              obj?.inputMessage?.type === 'success' &&
                                dispatch(resetNewsletter({}));
                            }}
                            inputProps={{ 'aria-label': 'Email Addresss' }}
                          />

                          <LoadingButton
                            onClick={handleClick}
                            loading={obj.isPending}
                            id="message"
                            name="message"
                            sx={{
                              textTransform: 'none',
                              color: 'primary.white',
                            }}
                            className={styles.footerMainMenu}
                            endIcon={<ARROW_RIGHT />}
                          >
                            Submit
                          </LoadingButton>
                        </Paper>
                      </div>

                      <div
                        className={
                          obj?.inputMessage?.type === 'error'
                            ? styles.newsLetterSuccessMessage2
                            : obj?.inputMessage?.type === 'success'
                            ? styles.newsLetterSuccessMessage1
                            : styles.newsLetterSuccessMessage2
                        }
                      >
                        {!!obj?.inputMessage?.content && (
                          <Typography
                            variant="p2"
                            className={styles.newletterErrorMessage}
                            color="primary.white"
                            sx={{
                              py: 0.5,
                              px: 1.5,
                              bgcolor:
                                obj?.inputMessage?.type === 'error'
                                  ? '#C84940'
                                  : obj?.inputMessage?.type === 'success'
                                  ? 'green'
                                  : '',
                              borderRadius: 1,
                            }}
                          >
                            {obj?.inputMessage?.content}
                          </Typography>
                        )}
                      </div>

                      <div
                        className={
                          obj?.inputMessage?.type === 'error'
                            ? styles.newsLetterSuccessMessage1
                            : obj?.inputMessage?.type === 'success'
                            ? styles.newsLetterSuccessMessage2
                            : styles.newsLetterSuccessMessage1
                        }
                      >
                        <p className={styles.newsLetterSuccessMessageChildOne}>
                          {' '}
                          Thank you for subscribing!{' '}
                        </p>

                        <p
                          className={styles.newsLetterSuccessMessageChildSecond}
                        >
                          {' '}
                          Check your email for a confirmation message.
                        </p>
                      </div>

                      <Box sx={{ my: 2 }}>
                        <Link href="https://www.veteranownedbusiness.com/business/37505/promedev-llc">
                          <a target="_blank" rel="noopener noreferrer">
                            <Image
                              src={veteranImage}
                              width={83}
                              height={54}
                              alt=""
                              loader={imgLoader}
                              className={styles.footerVeteranImg}
                            />
                          </a>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  sx={{ width: { lg: 'auto', sm: '90%', md: '90%' } }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={{ lg: 8, md: 2, sm: 2 }}
                    maxWidth="xl"
                    wrap="nowrap"
                    className={styles.footerMenuMainConatiner}
                  >
                    <Grid item xs={3} className={styles.footerSocialMediaMenu}>
                      <MenuList>
                        <Stack
                          direction="row"
                          alignItems="flex-start"
                          columnGap={1}
                        >
                          <Typography
                            variant="h5"
                            color="primary.white"
                            sx={{ pr: 1 }}
                          >
                            {socialMenu.name}
                          </Typography>
                          {socialMenu.items.map((item, i) => (
                            <Link href={item.link}>
                              <MenuItem
                                tabIndex={0}
                                sx={{ p: 0 }}
                                key={i}
                                className={styles.footerMainMenu}
                              >
                                <ListItemIcon sx={{ justifyContent: 'center' }}>
                                  <a target="_blank" rel="noopener noreferrer">
                                    {item.icon}
                                  </a>
                                </ListItemIcon>
                              </MenuItem>
                            </Link>
                          ))}
                        </Stack>
                      </MenuList>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={styles.copyRightContainer}
                      sx={{ textAlign: { lg: 'center' } }}
                    >
                      <div className={styles.imageContainer}>
                        <Image
                          alt={'rfc-logo'}
                          layout="fill"
                          src={rfFooterLogo}
                          className={styles.footerRfLogo}
                        />
                      </div>
                      <Typography variant="p1-book" color="primary.white">
                        ©{' '}
                        {new Date().getFullYear() >= config.copyright.year
                          ? new Date().getFullYear()
                          : config.copyright.year}{' '}
                        All Rights Reserved
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Stack
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        columnGap={2}
                      >
                        {bottomFooterMenu.map((item, i) => (
                          <Link href={item.link} underline="none">
                            <MenuItem
                              tabIndex={0}
                              sx={{ p: 0, justifyContent: 'end' }}
                              key={i}
                              className={styles.footerMainMenu}
                            >
                              <ListItemText>
                                <Typography
                                  variant="h5"
                                  color="primary.white"
                                  className={styles.dataPolicy}
                                >
                                  {item.title}
                                </Typography>
                              </ListItemText>
                            </MenuItem>
                          </Link>
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Grid item xs sx={{ width: '100%' }}>
            <Divider
              color="#668D97"
              sx={{ mt: { lg: 2.5, md: 2.5, sm: 8 }, width: '100%' }}
            />
          </Grid>
          <Grid
            id="copyRight"
            item
            xs
            sx={{ p: 3, textAlign: { lg: 'center' } }}
          >
            <Typography
              variant="p2-book"
              color="primary.white"
              className={styles.footerBottomContent}
            >
              * These statements have not been evaluated by the Food and Drug
              Administration. This product is not intended to diagnose, treat,
              cure, or prevent any disease. Individual results may vary.
              <br></br>
              {productsPath.includes(router.pathname) && (
                <>
                  <span>
                    ** The natural foods depicted in our product images are
                    inspired by ingredients found in the featured product.
                  </span>
                  <br></br>
                  <span>
                    *** Please see our{' '}
                    <Link href="/policies/money-back">
                      <a className={styles.footerBottomStatementLink}>
                        Feel Better or Your Money Back
                      </a>
                    </Link>{' '}
                    policy
                  </span>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <StyledBackgroundImage className={styles['footer-bg-img']}>
          <StyledImage1
            className={styles['footer-bg-img-1']}
            src={leaf1}
            alt=""
          />
          <StyledImage2
            className={styles['footer-bg-img-2']}
            src={leaf2}
            alt=""
          />
          <StyledImage3
            className={styles['footer-bg-img-3']}
            src={leaf3}
            alt=""
          />
          <StyledImage4
            className={styles['footer-bg-img-4']}
            src={leaf4}
            alt=""
          />
        </StyledBackgroundImage>
      </StyledBackground>
    </Box>
  );
}
