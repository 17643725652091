// Images
import Visa from '../../../../src/images/Visa.svg';
import Mastercard from '../../../../src/images/Mastercard.svg';
import Discover from '../../../../src/images/Discover.svg';
import Amex from '../../../../src/images/Amex.svg';
import CCPlaceholder from '../../../../src/images/CCPlaceholder.svg';

export const normalizeInput = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const firstNumber = cleaned.charAt(0);
  if (firstNumber == 1 && cleaned?.length === 11) {
    let match = cleaned.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    );
    return ['+1 (', match[2], ') ', match[3], '-', match[4]].join('');
  } else {
    let match = cleaned.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    );
    return match
      ? ['(', match[2], ') ', match[3], '-', match[4]].join('')
      : null;
  }
  // 1234567890
  // if (firstNumber == 1 && cleaned?.length === 10) {
  //   ///1234567890;
  //   let phoneNo = cleaned;
  //   let match = phoneNo.match(
  //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  //   );
  //   console.log(match, ['(', match[2], ') ', match[3], '-', match[4]].join(''));
  //   return ['+1 (', match[1], ') ', match[2], '-', match[3]].join('');
  // } else if (firstNumber == 1 && cleaned?.length === 11) {
  //   let match = cleaned.match(
  //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  //   );
  //   return ['+1 (', match[2], ') ', match[3], '-', match[4]].join('');
  // } else {
  //   let match = cleaned.match(
  //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  //   );
  //   return match
  //     ? ['(', match[2], ') ', match[3], '-', match[4]].join('')
  //     : null;
  // }
  return null;
};

// export const normalizeInput = (value, prevValue) => {
//   const currentValue = value.replace(/[^\d]/g, "");
//   const filteredPrev = (prevValue || "").replace(/[^\d]/g, "");
//   let hasCountryCode = false;
//   let countryCode = "";
//   let valueWithCountryCode = "";

//   if (!value) return value;

//   if (currentValue[0] === "1") {
//     let temp = currentValue.split("");
//     temp.splice(0, 1);
//     valueWithCountryCode = temp.join("");
//     countryCode = "+1 ";
//     hasCountryCode = true;
//   }
//   const finalValue = hasCountryCode ? valueWithCountryCode : currentValue;
//   const cvLength = finalValue.length;

//   if (cvLength === 0 && filteredPrev.length > finalValue.length)
//     return finalValue;
//   if (cvLength < 4) return `${countryCode}(${finalValue}`;

//   if (cvLength < 7) {
//     return `${countryCode}(${finalValue.slice(0, 3)}) ${finalValue.slice(3)}`;
//   }
//   if (cvLength < 11) {
//     return `${countryCode}(${finalValue.slice(0, 3)}) ${finalValue.slice(
//       3,
//       6
//     )}-${finalValue.slice(6, 10)}`;
//   }
//   return prevValue;
// };

// export const normalizeInput = (value) => {
//   if(!value) return false;
//   let number = value.replace(/\D/g, "");
//   let countryCode = number[0] == 1 ? "+1 ": "";
//   if(number[0] == 1) number = number.substring(1);
//   if(number) {
//     let formattedNumber = foramtPhoneNumber(number, countryCode);
//     return `${countryCode}${formattedNumber}`
//     // if(chunks)  {
//     //   return `${countryCode}(${chunks[1]}) ${chunks[2]}-${chunks[3]}`
//     // }
//   }
//   return `${countryCode}${number}`;
// }

// export const foramtPhoneNumber = (number) => {
//   const x = number && number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
//   return !x[2] ? (!countryCode ? x[1]: `(${x[1]})`) : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
// }

// export const foramtPhoneNumberCC = (number) => {
//   const x = number && number.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
//   return !x[2] ? x[1]: `(${x[1]})`) : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
// }

export const normalizeCCInput = (
  value,
  previousValue,
  cardType,
  setCardType,
  setCreditCardIcon,
  setCVVLength
) => {
  if (!value) {
    setCardType('');
    setCreditCardIcon(null);
    setCreditCardIcon(<CCPlaceholder />);
  }

  const input = value.toString().replace(/\D+/g, '');
  const sanitize = input?.replace(/[^\d]/g, '');
  let cardNoType = '';
  if (sanitize.length > 0) {
    switch (sanitize[0]) {
      case '3':
        cardNoType = 'amex';
        setCVVLength && setCVVLength(4);
        setCreditCardIcon(<Amex />);
        break;
      case '4':
        cardNoType = 'visa';
        setCVVLength && setCVVLength(3);
        setCreditCardIcon(<Visa />);
        break;
      case '5':
        cardNoType = 'mastercard';
        setCVVLength && setCVVLength(3);
        setCreditCardIcon(<Mastercard />);
        break;
      case '6':
        cardNoType = 'discover';
        setCVVLength && setCVVLength(3);
        setCreditCardIcon(<Discover />);
        break;
      default:
        setCreditCardIcon(null);
        setCreditCardIcon(<CCPlaceholder />);
        break;
    }
  }
  setCardType(cardNoType);
  if (cardNoType === 'amex') {
    if (sanitize.length < 5) return sanitize;

    if (sanitize.length < 11)
      return `${sanitize.slice(0, 4)} ${sanitize.slice(4)}`;

    return `${sanitize.slice(0, 4)} ${sanitize.slice(4, 10)} ${sanitize.slice(
      10,
      15
    )}`;
  } else {
    if (sanitize.length > 16) return previousValue;
    return sanitize.replace(/\d{4}(?=.)/g, '$& ');
  }
};

export const validateCode = (value, previousValue, length) => {
  const currentValue = value?.replace(/[^\d]/g, '');
  if (!currentValue) return '';
  if (length < 5) {
    if (currentValue.length > length) return previousValue;
    return currentValue;
  } else if (currentValue.length > length) {
    if (currentValue.length === 10) {
      return previousValue;
    } else {
      return `${currentValue.slice(0, 5)}-${currentValue.slice(5)}`;
    }
  }

  return currentValue;
};

export const validateNewZipCode = (value, previousValue, length) => {
  const currentValue = value?.replace(/[^\d\w ]/g, '');
  return currentValue;
};
