import React from 'react';
import { getValidationMessage } from '../../../utils';
import styles from './TextInput.module.scss';

const TextInput = ({
  key,
  label = '',
  register,
  errors,
  type = 'text',
  required = false,
  helperText = '',
  errorType = '',
  showError = true,
  inputGroup = null,
  isDarkBorder = false,
  ...rest
}) => {
  return (
    <>
      <div
        className={`${
          isDarkBorder ? styles.inputContainerSupport : styles.inputContainer
        } ${label ? '' : styles.paymentInput} ${label ? '' : styles.column}`}
      >
        {label && (
          <label htmlFor={rest?.id}>
            {label}
            {required && <div className={styles.requiredAsterisk}>*</div>}
          </label>
        )}
        <input
          {...register}
          type={type}
          className={`${
            errors && errors[register?.name]?.type ? styles.inputError : ''
          }`}
          {...rest}
        />
        {showError && getValidationMessage(errors, register?.name, errorType)}
        {helperText && <p className={styles.helperWrapper}>{helperText}</p>}
      </div>
    </>
  );
};

export default TextInput;
