import React from 'react';
import styles from './ContainedButton.module.scss';
import { Typography } from '@mui/material';
import { Button } from '../../../components/ui';
import { TextWithArrowAnimation } from '../../AnimatedComponents';

const BuyNowButton = React.forwardRef(
  (
    {
      children,
      variant = 'h5',
      arrow = false,
      btnType = 'dark',
      className = '',
      isLoading = false,
      ...rest
    },
    ref
  ) => (
    <Button
      {...rest}
      ref={ref}
      className={`${styles.buttonMain} ${
        btnType == 'dark' ? '' : styles.light
      } ${className}`}
    >
      {isLoading ? (
        <span style={{ zIndex: 2 }} className={styles.loader}></span>
      ) : (
        <div style={{ zIndex: 2 }}>
          {arrow ? (
            <TextWithArrowAnimation
              type={btnType == 'dark' ? 'light' : 'dark'}
              // variant="h5"
            >
              {children}
            </TextWithArrowAnimation>
          ) : (
            <Typography
              variant={variant}
              color={btnType == 'dark' ? 'primary.white' : 'primary.main'}
              noWrap
            >
              {children}
            </Typography>
          )}
        </div>
      )}
    </Button>
  )
);

export default BuyNowButton;
