import { useEffect, useState } from 'react';
import { Rating } from '../../components/ui';
import { productsKeyConstants } from '../../../config';
import {
  bottomLine,
  getBottomLineInfo,
} from '../../../src/features/yopto/yoptoState';
import { useAppDispatch, useAppSelector } from '../../../src/app/hooks';
import { getProducts } from '../../utils/productsHelper';
import { Typography } from '@mui/material';

const RatingWithCount = ({
  precision = 0.1,
  fetchFromYotpo = false,
  size,
  product = productsKeyConstants.RF_QUICKSTART,
  displayScore = false,
  className,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const products = getProducts();
  const bottomLineData = useAppSelector(bottomLine);
  const [mounted, setMounted] = useState(false);
  const [averageScore, setAverageScore] = useState(5);
  const [totalReviews, setTotalReviews] = useState(56);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (product && fetchFromYotpo) {
      let id = products[product].quoteParams?.productId;
      if (id) dispatch(getBottomLineInfo(id));
    }
  }, [product]);

  useEffect(() => {
    if (fetchFromYotpo) {
      setAverageScore(bottomLineData?.average_score || 0);
      setTotalReviews(bottomLineData?.total_reviews || 0);
    }
  }, [bottomLineData, fetchFromYotpo]);

  return (
    <>
      {mounted ? (
        <>
          {displayScore && (
            <Typography variant="h2" component="span" color={'primary.main'}>
              {averageScore || 3.5}
            </Typography>
          )}
          <Rating
            value={averageScore}
            precision={precision}
            readOnly
            size={size}
            count={`(${totalReviews})`}
            className={className}
            {...rest}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default RatingWithCount;
