export const EMAIL_REGISTER = 'email';
export const FN_REGISTER = 'firstName';
export const LN_REGISTER = 'lastName';
export const ADD1_REGISTER = 'address1';
export const ADD2_REGISTER = 'address2';
export const CITY_REGISTER = 'city';
export const STATE_REGISTER = 'state';
export const ZIP_REGISTER = 'zip';
export const PHONE_REGISTER = 'phone';
export const COMMENTS_REGISTER = 'comments';
