import { useEffect, useState } from 'react';
import { rfPainRelief, RFQSTabletsOld } from '../../../src/images';
import styles from './header.module.scss';

import {
  Grid,
  Box,
  Container,
  Stack,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Badge,
} from '@mui/material';

import { useRouter } from 'next/router';
import Link from 'next/link';

import PHONE_ICON from '../../../src/assets/images/icons/phone.svg';
import SUPPORT_ICON from '../../../src/assets/images/icons/support.svg';

import { useResponsive } from '../../../commons/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';

import { businessHourTiming } from '../../../commons/utils/utils';

const CustomMenuList = ({
  item,
  color = 'primary.main',
  sx,
  showOnline = false,
}) => {
  const [isOnline, setOnline] = useState(false);

  useEffect(() => {
    setOnline(businessHourTiming());
  }, []);

  return (
    <>
      <Link variant="h5" color={color} href={item.link} underline="none">
        <MenuItem
          tabIndex={0}
          sx={{ ...sx }}
          disableRipple
          className={styles.desktopNavBarMenu}
        >
          {item.icon && (
            <ListItemIcon sx={{ justifyContent: 'center' }}>
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography variant="h5" color={color}>
              {item.title}
            </Typography>
          </ListItemText>
          {showOnline && (
            <Badge
              className={`${
                isOnline ? styles.onlineStatus : styles.offlineStatus
              }`}
              sx={{
                pr: 1,
                color: 'warning',
              }}
              variant="dot"
            />
          )}
        </MenuItem>
      </Link>
    </>
  );
};
// Below is Promoheader code to show the promotional content
const PromoHeader = ({ isTap, showSideNav }) => {
  let router = useRouter();

  return (
    <Box
      component="div"
      className={` ${styles.promoHeaderBg} ${
        showSideNav || router.pathname == '/products'
          ? styles.hidePromoHeader
          : ''
      }`}
      sx={{ py: 1 }}
      width="100%"
    >
      <Container className={styles.promoHeaderContainer} maxWidth="lg">
        <Stack className={styles.promoContentArea}>
          <Box className={styles.promoImgWarpper}>
            <Box className={styles.promoImgContainer}>
              <img
                src={RFQSTabletsOld}
                width={40}
                height={50}
                className={styles.oldImage}
              />
              <img
                src={rfPainRelief}
                width={45}
                height={55}
                className={styles.newImage}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: 420, lg: 'auto', sm: '60%', xs: '60%' },
              pb: { lg: 0, md: 1 },
              mt: { sm: 2, md: 0 },
              lineHeight: { xs: 1 },
            }}
          >
            <Typography variant="p1" color="primary.white">
              Relief Factor has a new look, but with the same relief you’ve come
              to know and trust.
              {/* <Link href="/products/relief-factor#info" color="primary.white">
                  <Typography
                    variant="p1"
                    sx={{
                      'textDecoration': 'underline',
                      'cursor': 'pointer',
                      '&:hover': {
                        opacity: 0.7,
                      },
                    }}
                  >
                    Learn More
                  </Typography>
                </Link> */}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default function TopHeader({ showSideNav, promoContent }) {
  const bottomFooterMenu = [
    {
      title: 'Sales: 888-488-3112',
      icon: <PHONE_ICON />,
      link: 'tel:+1-888-488-3112',
      showOnline: true,
    },
    {
      title: 'Support',
      icon: <SUPPORT_ICON />,
      link: '/support',
    },
  ];
  const isDesktop = !useMediaQuery('(max-width:880px)');
  const isTap = useResponsive('down', 'lg');
  const color = 'primary.main';
  return (
    <>
      <Box sx={{ width: '100%', zIndex: 10004 }}>
        {/* Keep this as if any promotion we have to do we can use it */}
        {/* <PromoHeader isTap={isTap} showSideNav={showSideNav} /> */}
        <Box
          component="div"
          className={`${styles['top-nav-bar']}`}
          sx={{
            bgcolor: 'secondary.light',
            py: { sm: 1, xs: 0 },
          }}
          width="100%"
        >
          <Container maxWidth="xl" sx={{ paddingX: { xl: 0, lg: 2, sm: 2 } }}>
            <Grid
              container
              direction={isDesktop ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems="center"
              maxWidth="xl"
              margin="auto"
              className={`${styles.promoContainer} ${
                !promoContent && styles.promoContainerwithContent
              }`}
            >
              <Grid item xs className={styles.promoBarText}>
                {promoContent && (
                  <Typography variant="p2" color="primary.main">
                    {promoContent.mainContent}
                    <Link href={promoContent.promoLink}>
                      <Typography
                        variant="p2"
                        color="primary.main"
                        sx={{
                          'textDecoration': 'underline',
                          'cursor': 'pointer',
                          '&:hover': {
                            opacity: 0.7,
                          },
                        }}
                      >
                        {promoContent.linkContent}
                      </Typography>
                    </Link>
                  </Typography>
                )}
              </Grid>
              <Grid item xs>
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  columnGap={0}
                  className={`${styles.topMenu} ${
                    promoContent ? styles.topMonWithPromo : ''
                  }`}
                >
                  {bottomFooterMenu.map((item, i) => (
                    <CustomMenuList
                      key={i}
                      item={item}
                      index={i}
                      color={color}
                      sx={{ px: 1 }}
                      showOnline={item.showOnline || false}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
