import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styles from './CounterButton.module.scss';
import { Button } from '../../../components/ui';

const CounterButton = React.forwardRef(
  (
    {
      className = '',
      handleIncrementCounter,
      handleDecrementCounter,
      qty,
      minQty = 1,
      incrementDisabled,
      decrementDisabled,
      showError = false,
    },
    ref
  ) => {
    return (
      <div className={`${styles.counterBtnWrapper} ${className}`}>
        <div
          className={`${styles.counterBtnContainer} ${
            showError && styles.errorBorder
          }`}
        >
          <Button
            disabled={qty <= minQty || decrementDisabled}
            onClick={() => handleDecrementCounter()}
            className={`${styles.CounterBtn} ${
              (qty <= minQty || decrementDisabled) && styles.disabled
            }`}
            style={{ fontSize: 'large', transform: 'translateY(-.5px)' }}
          >
            -
          </Button>
          <div className={styles.counterNumber}>{qty}</div>
          <Button
            // disabled={qty == 10 || incrementDisabled}
            onClick={() => handleIncrementCounter()}
            style={{ transform: 'translateY(.5px)' }}
            className={`${styles.CounterBtn} ${
              incrementDisabled && styles.disabled
            }`}
          >
            +
          </Button>
        </div>
      </div>
    );
  }
);

export default CounterButton;
