import { productsKeyConstants } from '../../config';
import {
  rfPainRelief,
  rfZenFactor,
  rfZFactor,
  rfGoFactor,
} from '../../src/images';

export const BEACON_TOKEN_KEY = 'beaconToken';

export const initialPriceProducts = [
  productsKeyConstants.RF_QUICKSTART,
  productsKeyConstants.RF_60CT_SINGLE,
  productsKeyConstants.RF_SLEEP,
  productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE,
  productsKeyConstants.RF_GO_FACTOR_SINGLE,
  productsKeyConstants.RF_ZEN_FACTOR_SINGLE,
  productsKeyConstants.RF_Z_FACTOR_SINGLE,
];

export const HeadTitleContent = {
  relieffactor: {
    path: '/',
    title: 'Relief Factor Co.: Celebrating 10 Years of 100% Drug-Free Wellness',
    description:
      'Relief Factor is a 100% drug-free pain relief supplement made from a unique combination of botanical ingredients and fish oil. Get relief today!',
  },
  support: {
    path: '/support',
    title: 'Customer Support | Relief Factor',
    description:
      'Have questions or concerns? Reach out to our talented support team today!',
  },
  login: { path: '/login', title: 'Login', description: 'Customer Login' },
  quickstart: {
    path: '/products/relief-factor',
    title: 'Anti-Inflammatory Pain Relief QuickStart | Relief Factor',
    description:
      'Relief Factor QuickStart is a convenient 3-week trial, including 49 packets and a 91-page anti-inflammatory diet guide. Get relief today!',
  },
  freegift: {
    path: '/freegift',
    title: 'Free Gift',
    description: 'Free gift',
  },
  calm: {
    path: '/products/zen-factor',
    title:
      'Zen Factor: All-Natural Stress Relieving Supplement | Relief Factor',
    description:
      'Zen Factor helps your body effectively deal with stress with a powerful blend of herbal extracts. Order now for free shipping and handling!',
  },
  energy: {
    path: '/products/go-factor',
    title: 'Go Factor: All-Natural Energy Boosting Supplement | Relief Factor',
    description:
      'Go Factor helps you keep going, and helps your body make nutrients readily available when you need it. Order now for free shipping & handling!',
  },
  sleep: {
    path: '/products/z-factor',
    title: 'Z Factor: All-Natural Sleep Aid Supplement | Relief Factor',
    description:
      'Z Factor, a 100% drug-free, non-addictive sleep supplement that leaves you feeling rested and restored. Order now for free shipping & handling!',
  },
  faq: {
    path: '/faq',
    title: 'Frequently Asked Questions | Relief Factor',
    description:
      "Visit Relief Factor's FAQ to answer common questions about our ingredients, shipping and handling, memberships and more.",
  },
  ordersuccess: {
    path: '/ordersuccess',
    title: 'Order Success',
    description: 'Order success',
  },
  createpassword: {
    path: '/createpassword',
    title: 'Create New Password',
    description: 'Create New password',
  },
  checkout: {
    path: '/checkout',
    title: 'Checkout',
    description: 'Shopping Cart',
  },
  paymentexpired: {
    path: '/paymentexpired',
    title: 'Payment',
    description: 'Payment expired',
  },
  ingredients: {
    path: '/ingredients',
    title: 'Relief Factor Ingredients Omega-3, Curcumin, Resveratrol, Icariin',
    description:
      'Relief Factor Ingredients Omega-3, Curcumin, Resveratrol, Icariin',
  },
  turmeric: {
    path: '/turmeric',
    title: 'Ingredients - Turmeric',
    description: 'Ingredients - Turmeric',
  },
  research: {
    path: '/the-science-research',
    title:
      'The ingredients are scientifically proven to fight against inflammation',
    description:
      'The ingredients are scientifically proven to fight against inflammation',
  },

  rescheduleorder: {
    path: '/reschedule-order',
    title: 'How To Reschedule Your Shipment in the Customer Portal',
    description: 'Reschedule Order',
  },
  enablecookies: {
    path: '/enable-cookies',
    title: 'Enable Cookies',
    description: 'Enable Cookies',
  },
  somethingwentwrong: {
    path: '/something-went-wrong',
    title: 'Something Went Wrong',
    description: 'Something went wrong.',
  },
  forgotpassword: {
    path: '/forgotPassword',
    title: 'Forgot Password',
    description: 'Forgot User Password',
  },
  returnsPolicy: {
    path: '/policies/returns',
    title: 'Returns Policy',
    description:
      'We always hope you are happy with your Relief Factor purchase. If you ever need to return an order, we make it easy by following this simple return policy.',
  },
  privacyPolicy: {
    path: 'policies/privacy',
    title: 'Privacy and Customer Data Policies',
    description:
      'We take Relief Factor customer data privacy very seriously, and use the following policies to protect our customer’s privacy and data.',
  },
  shippingPolicy: {
    path: 'policies/shipping',
    title: 'Shipping Policies',
    description:
      'We follow these shipping policies to ensure prompt fulfillment and delivery of Relief Factor products to your door.',
  },
  reviews: {
    path: '/reviews',
    title: 'Relief Factor Reviews: All-Natural Anti-Inflammatory Pain Relief',
    description: `Relief Factor reviews by real customers (2023). Read reviews from 1,000's of happy customers who share their health and wellness journeys. Get relief today!`,
  },
};

export const productUpsellCarousel = [
  {
    id: 1,
    productImg: rfPainRelief,
    category: 'Pain Relief',
    productName: 'Relief Factor',
    link: '/products/relief-factor',
    gradient: '#9cd4ea87',
  },
  {
    id: 2,
    productImg: rfZenFactor,
    category: 'Anxiety Relief',
    productName: 'Zen Factor',
    link: '/products/zen-factor',
    gradient: '#5d4d9a5e',
  },
  {
    id: 3,
    productImg: rfGoFactor,
    category: 'Energy Boost',
    productName: 'Go Factor',
    link: '/products/go-factor',
    gradient: '#50723473',
  },
  {
    id: 4,
    productImg: rfZFactor,
    category: 'Sleep Support',
    productName: 'Z Factor',
    link: '/products/z-factor',
    gradient: '#1f4e815e',
  },
];
