// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          'textTransform': 'none !important',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
}
