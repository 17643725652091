// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          [theme.breakpoints.down('lg')]: {
            // top: 126,
            boxShadow: 'none',
          },
          [theme.breakpoints.down('md')]: {
            // top: 150,
            boxShadow: 'none',
          },
        },
      },
    },
  };
}
