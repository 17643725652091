const TagNames = {
  PageView: 'page_view',
  Click: 'click',
  ViewItem: 'view_item',
  SelectItem: 'select_item',
  AddToCart: 'add_to_cart',
  RemoveCart:'remove_from_cart',
  BeginCheckout: 'begin_checkout',
  AddShippingInfo: 'add_shipping_info',
  AddBillingInfo: 'add_billing_info',
  AddPaymentInfo: 'add_payment_info',
  ViewPromotion: 'view_promotion',
  SelectPromotion: 'select_promotion',
  SetCheckoutOption: 'set_checkout_option',
  CheckoutOptionView: 'view',
  Purchase: 'purchase',
  OrderNowQS: 'order_now_qs',
  OrderNow60Pack: 'order_now_60pack',
  OrderNowSingle: 'order_now_single',
  OrderNowCalm: 'order_now_calm',
  OrderNowEnergy: 'order_now_energy',
  OrderNowSleep: 'order_now_sleep',
  AddCustomerInfo: 'add_customer_info',
  SubscriptionConfirm: 'subscription_confirmation',
  ReviewConfirm: 'review_confirmation',
  GAUSPSValidation: 'usps_validation',
  GAUSPSVerificationPassed: 'usps_verification_passed',
  GACustomEvent: 'view_item_list',
  TwitterAddToCart: 'twitter_add_to_cart'
};
export { TagNames };
