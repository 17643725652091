import styles from './Divider.module.scss';

const Divider = ({ stretchScreen = false, className = '' }) => {
  return (
    <div
      className={`${styles.divider} ${
        stretchScreen ? styles.extendBeyond : ''
      } ${className}`}
    />
  );
};

export default Divider;
