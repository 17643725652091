import { getBeaconToken } from '.';
import { config } from '../../config';

export const injectRiskified = () => {
  let store_domain = config.riskified.domain;
  var session_id = getBeaconToken();
  let url = `${
    'https:' == document.location.protocol ? 'https://' : 'http://'
  }beacon.riskified.com?shop=${store_domain}&sid=${session_id}`;
  const script = document.createElement('script');
  script.src = url;
  script.type = 'text/javascript';
  script.async = true;
  let x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(script, x);
};
