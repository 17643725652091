import React from 'react';
import { Typography } from '@mui/material';
import styles from './TabButton.module.scss';

const TabButton = ({ btnList, activeIdx, setIdxCB, className }) => {
  return (
    <div className={`${styles.tabBtnWrapper} ${className}`}>
      <div className={styles.tabBtnContainer}>
        {btnList.map((item, i) => (
          <button
            key={i}
            onClick={() => setIdxCB(item.id)}
            className={`${styles.tabBtn} ${
              activeIdx == item.id && styles.active
            }`}
          >
            <Typography variant="button1">{item.name}</Typography>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabButton;
