import React from 'react';
import styles from './CountryDropdown.module.scss';
import { Dropdown } from '../../../components/ui';
import { getValidationMessage } from '../../../utils';

const CountryDropdown = (
  {
    name = '',
    id = '',
    options = [],
    tkey = '',
    pkey = 'value',
    selectedOption = '',
    selectLabel = false,
    addNewOption = false,
    label = '',
    required = false,
    error = false,
    // defaultValue = "",
    disable = '',
    selectDisable = true,
    addNewText = 'Add New',
    optionsOrder = [1, 2, 3],
    errors,
    errorType = '',
    disabledItem = -1,
    isDarkBorder = false,
    labelNeeded = true,
    readOnly = false,
    ...rest
  },
  ref
) => {
  const optionsInScope = {
    1:
      //? Set default option to PLACE_HOLDER_OPTION to force select the disabled option
      selectLabel && (
        <option key="PLACE_HOLDER_OPTION" value="" disabled={selectDisable}>
          Select {label}
        </option>
      ),
    2: options.map((item, index) => (
      <option
        key={index}
        value={item[pkey]}
        disabled={item['disabled'] || item['id'] == disabledItem}
      >
        {item[tkey]}
      </option>
    )),
    3: addNewOption && (
      <option key="ADD_NEW_OPTION" value="ADD_NEW_OPTION">
        {addNewText}
      </option>
    ),
  };

  return (
    <div className={styles.dropdownContainer}>
      {label && labelNeeded && (
        <label>
          {label}
          {required && <div className={styles.requiredAsterisk}>*</div>}
        </label>
      )}
      <select
        ref={ref}
        className={`${
          isDarkBorder ? styles.selectDDSupport : styles.selectDD
        } ${error ? styles.inputError : ''}`}
        name={name}
        id={id}
        key={id}
        disabled={readOnly}
        {...rest}
      >
        {optionsOrder.map((order) => optionsInScope[order])}
      </select>
    </div>
  );
};

const forwardDropdown = React.forwardRef(CountryDropdown);

export default forwardDropdown;
