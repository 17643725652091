import { Typography } from '@mui/material';
import styles from './DividerWithText.module.scss';

const DividerWithText = ({
  stretchScreen = false,
  className = '',
  text,
  ...rest
}) => {
  return (
    <div className={`${styles.dividerContainer}`} {...rest}>
      <div
        className={` ${className} ${styles.divider} ${
          stretchScreen ? styles.leftExtend : ''
        }`}
      />

      <Typography variant="button" color="primary.black" component="span">
        {text}
      </Typography>

      <div
        className={` ${className} ${styles.divider} ${
          stretchScreen ? styles.rightExtend : ''
        }`}
      />
    </div>
  );
};

export default DividerWithText;
