// ----------------------------------------------------------------------

export default function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            padding: 0,
          },
          [theme.breakpoints.down('md')]: {
            // top: 150,
            padding: 0,
          },
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
  };
}
