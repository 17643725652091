import { Rating, Typography } from '@mui/material';
import styles from './Rating.module.scss';
import { Box } from '@material-ui/core';

const RatingComponent = ({ text, count, size, className, ...rest }) => {
  return (
    <Box component="div" className={`${styles.ratingContainer} ${className}`}>
      <Rating
        size={size}
        className={size ? '' : styles.customFontSize}
        {...rest}
      />{' '}
      {count && (
        <Typography variant="h5" color="action.gray">
          {count}
        </Typography>
      )}
      {/* {text && <p className={styles.ratingText}>{text}</p>} */}
    </Box>
  );
};

export default RatingComponent;
