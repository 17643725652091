const breakpoints = {
  values: {
    xs: 320,
    sm: 375,
    md: 768,
    lg: 1024,
    xl: 1328,
  },
};

export default breakpoints;
