import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';

function PaginationComponent({
  handlePaginationCall,
  totalCounts,
  itemsPerPages,
  maxPageNumberLimits,
  minPageNumberLimits,
  pageNumberLimits,
  currentPages,
}) {
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(20);
  const [pageNumberLimit, setpageNumberLimit] = useState(7);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(7);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event, number) => {
    if (number != currentPage) {
      setcurrentPage(Number(event.target.id));
      handlePaginationCall(Number(event.target.id));
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(totalCounts / itemsPerPage); i++) {
    pages.push(i);
  }

  useEffect(() => {
    setcurrentPage(currentPages);
    setitemsPerPage(itemsPerPages);
    setpageNumberLimit(pageNumberLimits);
    setmaxPageNumberLimit(maxPageNumberLimits);
    setminPageNumberLimit(minPageNumberLimits);
  }, [itemsPerPages]);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={(e) => handleClick(e, number)}
          style={currentPage == number ? null : { color: 'blue' }}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    handlePaginationCall(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    handlePaginationCall(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages?.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &#9002; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &#9001; </li>;
  }
  if (renderPageNumbers?.length > 1) {
    return (
      <>
        <ul className={styles.pageNumbers}>
          {pageDecrementBtn}
          {renderPageNumbers}
          {pageIncrementBtn}
        </ul>
      </>
    );
  }
  return null;
}

export default PaginationComponent;
