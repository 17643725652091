import { useState, useEffect, useRef } from 'react';
//mui
import {
  Grid,
  Box,
  Container,
  Stack,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Menu,
  IconButton,
  Drawer,
  AppBar,
} from '@mui/material';

//Next
import { useRouter } from 'next/router';

import USER_ICON from '../../../src/assets/images/icons/user-icon.svg';
import USER_ICON_DARK from '../../../src/assets/images/icons/user-icon-dark.svg';
import CART_ICON from '../../../src/assets/images/icons/cart-light.svg';
import CART_ICON_DARK from '../../../src/assets/images/icons/cart-dark.svg';
import MENU_DARK from '../../../src/assets/images/icons/mobile-menu-dark.svg';
import CLOSE_DARK from '../../../src/assets/images/icons/close-dark.svg';

import RF_LOGO from '../../../src/assets/images/global/rf-logo-new.svg';
import RF_LOGO_DARK from '../../../src/assets/images/global/rf-logo-dark.svg';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../src/app/hooks';

import { customerInfo } from '../../../src/features/user/userState';

import Navbar from './navbar';
import Link from 'next/link';

// css
import styles from './header.module.scss';
import { ToastContainer } from 'react-toastify';
import { noOfItemsInCart } from '../../../src/features/cart/cartState';
import { showCart } from '../../../src/features/ui/uiState';

const CustomMenuList = ({ item, color = 'primary.main', sx }) => {
  return (
    <>
      <Link variant="h5" color={color} href={item.link} underline="none">
        <MenuItem
          tabIndex={0}
          sx={{ ...sx }}
          disableRipple
          className={styles.desktopNavBarMenu}
        >
          {item.icon && (
            <ListItemIcon sx={{ justifyContent: 'center' }}>
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography variant="h5" color={color}>
              {item.title}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Link>
    </>
  );
};

export default function Header({ menuClicked, showSideNav, isDark, gradient }) {
  // params
  const isDesktop = !useMediaQuery('(max-width:880px)');
  //------------------------
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const userInfo = useAppSelector(customerInfo);
  const cartCount = useAppSelector(noOfItemsInCart);
  const elementRef = useRef(null);
  const [bottom, setBottom] = useState(0);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(-1);
  const disableCartIcon = ![
    '/cart',
    '/cart/cartempty',
    '/something-went-wrong',
  ].includes(router.pathname);

  const mainMenuList = [
    {
      title: 'Pain Relief',
      link: '/products/relief-factor',
    },
    {
      title: 'Anxiety Relief',
      link: '/products/zen-factor',
    },
    {
      title: 'Energy',
      link: '/products/go-factor',
    },
    {
      title: 'Sleep',
      link: '/products/z-factor',
    },
  ];

  const aboutMenuList = [
    {
      title: 'Our Story',
      link: '#',
    },
    {
      title: 'Ingredient Stories',
      link: '#',
    },
    {
      title: 'Testing',
      link: '#',
    },
    {
      title: 'Safety Standards',
      link: '#',
    },
    {
      title: 'Quality',
      link: '#',
    },
  ];

  const color = isDark ? 'primary.main' : 'primary.white';

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      if (showSideNav) {
        menuClicked(false);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, showSideNav]);

  useEffect(() => {
    const currentUrl = router.asPath;
    let activeIndex = -1;
    mainMenuList.forEach((item, index) => {
      if (currentUrl.includes(item.link)) {
        activeIndex = index;
      }
    });
    setActiveMenuItemIndex(activeIndex);
  }, [router]);

  return (
    <>
      <AppBar
        position="relative"
        sx={{
          width: '100%',
          zIndex: 10004,
          boxShadow: 'none',
          bgcolor: 'transparent',
        }}
        ref={elementRef}
      >
        <Box
          component="div"
          id="head"
          className={
            isDark ? styles['header-bg'] : styles['header-gradient-bg']
          }
          sx={{ zIndex: 10002, position: isDark ? 'relative' : 'absolute' }}
          width="100%"
        >
          <Container
            maxWidth="xl"
            className={gradient && styles.gradientDesign}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              maxWidth="xl"
              margin="auto"
              className={styles.headerdiv}
              sx={{ py: 3 }}
            >
              <Grid item xs={4} lg={2}>
                <Stack
                  flexDirection="row"
                  justifyContent="start"
                  columnGap={2}
                  className={styles.headerMenu}
                >
                  {mainMenuList.map((item, i) => (
                    <CustomMenuList
                      key={i}
                      item={item}
                      index={i}
                      color={color}
                      sx={{
                        pl: 0,
                        opacity:
                          activeMenuItemIndex === i ? '0.7 !important' : '1',
                      }}
                    />
                  ))}
                </Stack>
                <IconButton
                  aria-label="menu"
                  onClick={() => {
                    menuClicked(!showSideNav);
                    setTimeout(function () {
                      setBottom(
                        elementRef.current.getBoundingClientRect().bottom
                      );
                    }, 300);
                  }}
                  className={styles.menuButton}
                  sx={{
                    zIndex: 10005,
                  }}
                >
                  {showSideNav ? (
                    <CLOSE_DARK color={isDark ? '#004152' : '#fff'} />
                  ) : (
                    <MENU_DARK color={isDark ? '#004152' : '#fff'} />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={4} lg={7} xl={7}>
                <Stack
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link href="/">
                    <div className={styles.logoMain}>
                      {isDark ? <RF_LOGO /> : <RF_LOGO_DARK />}
                    </div>
                  </Link>
                </Stack>
              </Grid>
              <Grid item xs={4} lg={3} xl={3}>
                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  columnGap={1}
                >
                  {isDesktop && (
                    <>
                      <Box>
                        <Menu
                          id="demo-customized-menu"
                          MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          {aboutMenuList.map((item, i) => (
                            <CustomMenuList key={i} item={item} index={i} />
                          ))}
                        </Menu>
                      </Box>
                      <Box display="flex"></Box>
                      <Box>
                        <Link href={userInfo?.id ? '/account' : '/login'}>
                          <IconButton className={styles.userIconMenu}>
                            <a className={styles.myAccountBtn}>
                              <Typography
                                variant="h5"
                                color={color}
                                sx={{ pr: 1.1 }}
                              >
                                My Account
                              </Typography>
                              {isDark ? <USER_ICON_DARK /> : <USER_ICON />}
                            </a>
                          </IconButton>
                        </Link>
                      </Box>
                    </>
                  )}
                  {!isDesktop && (
                    <Box>
                      <Link href={userInfo?.id ? '/account' : '/login'}>
                        <IconButton className={styles.userIconMenu}>
                          <a className={styles.myAccountBtn}>
                            {isDark ? <USER_ICON_DARK /> : <USER_ICON />}
                          </a>
                        </IconButton>
                      </Link>
                    </Box>
                  )}

                  {disableCartIcon && (
                    <Box>
                      <IconButton
                        className={styles.cartIconMenu}
                        onClick={() => dispatch(showCart(true))}
                      >
                        {isDark ? <CART_ICON_DARK width="25" /> : <CART_ICON />}
                        <Typography variant="h5" sx={{ pl: 0 }} color={color}>
                          ({cartCount})
                        </Typography>
                      </IconButton>
                    </Box>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <ToastContainer
          className={`${styles.customToastContainer}`}
          autoClose={6000}
          newestOnTop
          position="top-right"
        />
      </AppBar>
      <Drawer
        anchor="left"
        open={showSideNav}
        onClose={() => {
          menuClicked(!showSideNav);
          setTimeout(function () {
            setBottom(elementRef.current.getBoundingClientRect().bottom);
          }, 300);
        }}
        sx={{
          ['& .MuiDrawer-root']: {
            position: 'unset',
            zIndex: '10003 !important',
          },
        }}
      >
        <Navbar
          height={isDark ? bottom : bottom + 80}
          isDark={isDark}
          router={router}
          menuClicked={menuClicked}
        />
      </Drawer>
    </>
  );
}
