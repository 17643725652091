import apiCall from '../../../commons/helpers/apiCall';

//! NOT IN USE
export async function fetchSurveyList(surveyCode) {
  const result = await apiCall({
    endpoint: `survey/options?product=${surveyCode}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });

  return result;
}

//Connected
export async function postSurvey(data) {
  const result = await apiCall({
    endpoint: 'survey',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      redirect: 'follow',
    },
  });
  return result;
}
