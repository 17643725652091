import React from 'react';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { config } from '../../config';

const {
  sentry: { secret_key, host, project_id, enabled },
  environment,
} = config;

if (secret_key !== '' && host !== '' && project_id !== '' && enabled) {
  const sentry_dsn = `https://${secret_key}@${host}/${project_id}`;

  Sentry.init({
    dsn: sentry_dsn,
    integrations: [
      new CaptureConsole({ levels: ['fatal', 'error', 'critical'] }),
      new BrowserTracing({ tracingOrigins: ['*'] }),
    ],
    tracesSampleRate: 1.0,
    environment,
  });
}

export const makeClientLog = (message) => {
  Sentry.captureMessage(message, 'log');
};

class SentrySetup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default SentrySetup;
