import { normalizeInput } from '../../components/body/checkout/sections/checkoutUtils';
import { config } from '../../config';
import { changeTimeZone } from './';
const setAutoCompleteFormData = (
  values,
  tempFormData,
  setValue,
  trigger = () => {}
) => {
  for (const [key, value] of Object.entries(values)) {
    if (value === '' || value === null) {
      if (tempFormData.hasOwnProperty(key)) {
        setValue(key, tempFormData[key]);
        trigger(key);
      }
    }
  }
};
const setFormDataFieldValue = (values, tempFormData, setValue, trigger) => {
  for (const [key, value] of Object.entries(values)) {
    if (tempFormData.hasOwnProperty(key)) {
      if (tempFormData[key] !== '' && tempFormData[key] !== null) {
        setValue(key, tempFormData[key]);
        trigger(key);
      } else {
        setValue(key, '');
      }
    }
  }
};

const removeCountryCodeAndFormat = (phoneNo) => {
  let cleaned = ('' + phoneNo).replace(/\D/g, '');
  const firstNumber = cleaned.charAt(0);

  if (firstNumber == 1 && cleaned?.length === 11) {
    return cleaned.substring(1);
  }
  return cleaned;
};

const removeCountryCode = (values, field_name) => {
  let newValues = { ...values };
  if (values.hasOwnProperty(field_name)) {
    let phoneNo = values[field_name];
    let cleaned = ('' + phoneNo).replace(/\D/g, '');
    const firstNumber = cleaned.charAt(0);
    // if (firstNumber == 1) {
    //   newValues[field_name] = cleaned.substring(1);
    // }
    newValues[field_name] = firstNumber == 1 ? '+' + cleaned : '+1' + cleaned;
  }
  return newValues;
};
const removeCountryCodeByValue = (phoneNo, isMask = false) => {
  let cleaned = ('' + phoneNo).replace(/\D/g, '');
  let newPhone = cleaned.charAt(0) == 1 ? cleaned.substring(1) : phoneNo;
  return isMask ? normalizeInput(newPhone) : newPhone;
};

const removeCountryCodeByValueAttentive = (phoneNo, isMask = false) => {
  let cleaned = ('' + phoneNo).replace(/\D/g, '');
  return cleaned.charAt(0) == 1 ? '+' + cleaned : '+1' + cleaned;
};

const filteredStateValue = (addrs) => {
  let filteredState = '';
  if (Array.isArray(addrs)) {
    if (typeof addrs[2] !== 'undefined') {
      const exceptionalAddress = addrs[2].trim().split(' ');
      filteredState =
        exceptionalAddress.length > 1
          ? exceptionalAddress?.[0]
          : addrs[2].replace(/\s/g, '');
    }
  }
  return filteredState;
};
const getValidIndexValue = (arr) => {
  return typeof arr[2] !== 'undefined' ? arr[2] : '';
};

//For comparing two objects
const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (key === 'phone' && obj1[key] !== obj2[key]) {
      obj2[key] = normalizeInput(obj2[key]);
    } else if (key === 'state') {
      obj2[key] = Number(obj2[key]);
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

const googleMapsSearchOptions = {
  componentRestrictions: { country: ['us', 'ca'] },
};

const googleMapsSearchOptionsAll = {
  componentRestrictions: { country: ['us', 'ca'] },
};

const googleMapsSearchOptionsShipping = {
  componentRestrictions: { country: ['us'] },
};

function googleMapsValueSplit(place) {
  let resultGoogleAPI = {
    address1: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
  };

  for (const component of place.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        resultGoogleAPI['address1'] = `${component.long_name}` || '';
        break;
      }
      case 'route': {
        resultGoogleAPI['address1'] += ' ' + component.long_name || '';
        break;
      }
      case 'postal_code': {
        resultGoogleAPI['postcode'] = `${component.long_name}` || '';
        break;
      }
      case 'locality':
        resultGoogleAPI['city'] = component.long_name || '';
        break;
      case 'sublocality_level_1':
        resultGoogleAPI['city'] = component.long_name || '';
        break;
      case 'administrative_area_level_1': {
        resultGoogleAPI['state'] = component?.short_name || '';
        break;
      }
      case 'country':
        resultGoogleAPI['country'] = component?.short_name || '';
        break;
    }
  }
  return resultGoogleAPI;
}

export const googleMapsRequestOptions = (placeId) => {
  return {
    placeId,
    componentRestrictions: { country: ['us', 'ca'] },
    fields: [
      'address_components',
      'formatted_address',
      'place_id',
      'geometry',
      'icon',
      'name',
    ],
    strictBounds: false,
    types: ['establishment'],
  };
};

const businessHourTiming = (customStartTime, customEndTime) => {
  let startTime = config.businessHours.openTime;
  let endTime = config.businessHours.closeTime;
  if (customEndTime) endTime = customEndTime;
  if (customStartTime) startTime = customStartTime;
  let today = new Date();
  let todayInET = changeTimeZone(today, 'America/New_York');
  let etOpen = changeTimeZone(today, 'America/New_York');
  let etClose = changeTimeZone(today, 'America/New_York');
  etOpen = new Date(etOpen.toDateString() + ' ' + startTime);
  etClose = new Date(etClose.toDateString() + ' ' + endTime);

  if (
    etOpen.valueOf() < todayInET.valueOf() &&
    todayInET.valueOf() < etClose.valueOf()
  ) {
    return true;
  }
  return false;
};

const maxDatePickerRange = () => {
  var year = new Date().getFullYear();
  var month = new Date().getMonth();
  var day = new Date().getDate();
  return new Date(year, month + 6, day);
};

const cookiesValueSplit = () => {
  var pairs = document.cookie.split(';');
  var cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies;
};

const getUrlParamValue = (param, url = '') => {
  if (param && url) {
    let searchParams = url.split('?')[1] || url;
    return new URLSearchParams(searchParams).get(param);
  }
  return '';
};

const getFormattedAddress = (address = null, addressType = 'shipping') => {
  if (address)
    return {
      [`${addressType}firstname`]: address.firstname || '',
      [`${addressType}lastname`]: address.lastname || '',
      [`${addressType}street`]: address?.street?.[0] || '',
      [`${addressType}street1`]: address?.street?.[1] || '',
      [`${addressType}city`]: address.city || '',
      [`${addressType}countryid`]: address.country_id || '',
      regionid: address.region_id || '',
      postcode: address.postcode || '',
      telephone: address.telephone || '',
    };
  else return null;
};

const convertAPIAddressToRedux = (address = null, addressType = 'shipping') => {
  if (address)
    return {
      id: address?.id || '',
      firstName: address?.firstname || '',
      lastName: address?.lastname || '',
      address1: address?.street?.[0] || '',
      address2: address?.street?.[1] || '',
      city: address?.city || '',
      countryId: address?.country_id || '',
      state: address?.region_id || '',
      zip: address?.postcode || '',
      phone: address?.telephone || '',
    };
  else return null;
};

const isUTCExpiredInMinutes = (date, minutes) => {
  if (date) {
    let endtime = new Date(date);
    endtime.setMinutes(endtime.getMinutes() + minutes);
    let currenttime = new Date();
    let utccurrenttime = new Date(
      currenttime.getUTCFullYear(),
      currenttime.getUTCMonth(),
      currenttime.getUTCDate(),
      currenttime.getUTCHours(),
      currenttime.getUTCMinutes(),
      currenttime.getUTCSeconds()
    );
    return endtime.getTime() < utccurrenttime.getTime();
  }
  return true;
};

const getObjFrmArray = (array, key, value) => {
  const filteredResult = array.find((e) => e[key] == value);
  return filteredResult;
};

export {
  setAutoCompleteFormData,
  setFormDataFieldValue,
  removeCountryCode,
  removeCountryCodeByValue,
  googleMapsSearchOptions,
  filteredStateValue,
  getValidIndexValue,
  googleMapsValueSplit,
  businessHourTiming,
  removeCountryCodeByValueAttentive,
  maxDatePickerRange,
  cookiesValueSplit,
  getUrlParamValue,
  getFormattedAddress,
  isUTCExpiredInMinutes,
  removeCountryCodeAndFormat,
  convertAPIAddressToRedux,
  getObjFrmArray,
  googleMapsSearchOptionsAll,
  googleMapsSearchOptionsShipping,
  compareObjects,
};
