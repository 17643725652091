//
import Backdrop from './Backdrop';
import Paper from './Paper';
import Drawer from './Drawer';
import Container from './Container';
import Card from './Card';
import Button from './Button';
import MenuItem from './MenuItem';
import IconButton from './IconButton';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Backdrop(theme),
    Paper(theme),
    Drawer(theme),
    Container(theme),
    Card(theme),
    Button(theme),
    MenuItem(theme),
    IconButton(theme)
  );
}
