import { config, productsKeyConstants } from '../../../../config';
import { postUiLogs } from '../../../../src/features/user/userApi';
import {
  sentFbGraphAPIPost,
  getClientUserData,
  getProductList,
  getProductSingleItem,
  getCookieByName,
  currentTimeStamp,
  encryptStrToSHA256,
  getUniqueEventId,
  getURLWithoutQueryParam,
  isFBNonBlockEvents,
  getProductViewContent,
} from '../utils';
import { TagNames } from './Constants';

const fbqTrack = (name, options = {}, eventId) => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', name, { ...options }, { eventID: eventId });
  }
};

const fbq = (name, options = {}, eventId, isSleep = false) => {
  let pixelID = isSleep ? config.fb.pixel_id_sleep : config.fb.pixel_id;
  if (typeof window.fbq === 'function') {
    // window.fbq('track', name, { ...options }, { eventID: eventId });
    window.fbq(
      'trackSingle',
      pixelID,
      name,
      { ...options },
      { eventID: eventId }
    );
  }
};

const FBPageView = (ipaddress = '') => {
  const eventID = getUniqueEventId();
  let data = [
    {
      event_id: eventID,
      event_name: TagNames.PageView,
      event_time: currentTimeStamp,
      user_data: getClientUserData(),
      event_source_url: getURLWithoutQueryParam(),
      action_source: 'website',
    },
  ];
  let clientData = getClientUserData();
  if (ipaddress !== '') {
    clientData['client_ip_address'] = ipaddress;
  }
  if (isFBNonBlockEvents()) {
    fbqTrack(TagNames.PageView, {}, eventID);
  }
  sentFbGraphAPIPost(data);
  sentFbGraphAPIPost(data, true);
  let payload = {
    message: `FbEvents`,
    event_name: TagNames.PageView,
    is_block: !isFBNonBlockEvents(),
    location: window.location.href,
    data: data[0],
  };
  if (config.fbLog == 'true') {
    postUiLogs(payload);
  }
};

const FBViewContent = (productList, products, isSleep = false) => {
  let custom_data = getProductViewContent(productList, products);
  const eventID = getUniqueEventId();
  let data = [
    {
      event_id: eventID,
      event_name: TagNames.ViewContent,
      event_time: currentTimeStamp,
      event_source_url: getURLWithoutQueryParam(),
      action_source: 'website',
      user_data: getClientUserData(),
      custom_data: custom_data,
    },
  ];
  if (isFBNonBlockEvents()) {
    fbq(TagNames.ViewContent, custom_data, eventID, isSleep);
  }
  sentFbGraphAPIPost(data, isSleep);
  let payload = {
    message: `FbEvents`,
    event_name: TagNames.ViewContent,
    is_block: !isFBNonBlockEvents(),
    location: window.location.href,
    data: data[0],
  };
  if (config.fbLog == 'true') {
    postUiLogs(payload);
  }
};

const FBAddToCartPage = (productList,products, cart, isSleep) => {
  let custom_data = getProductList(productList,products, cart );
  const eventID = getUniqueEventId();
  let data = [
    {
      event_id: eventID,
      event_name: TagNames.AddToCart,
      event_time: currentTimeStamp,
      event_source_url: getURLWithoutQueryParam(),
      action_source: 'website',
      user_data: getClientUserData(),
      custom_data: custom_data,
    },
  ];
  fbq(TagNames.AddToCart, custom_data, eventID, isSleep);
  sentFbGraphAPIPost(data, isSleep);
};

const FBAddToCart = (productData, recurringPrice = true, updateQty, isSleep = false) => {
  let custom_data = getProductSingleItem(productData, recurringPrice,updateQty );
  const eventID = getUniqueEventId();
  let data = [
    {
      event_id: eventID,
      event_name: TagNames.AddToCart,
      event_time: currentTimeStamp,
      event_source_url: getURLWithoutQueryParam(),
      action_source: 'website',
      user_data: getClientUserData(),
      custom_data: custom_data,
    },
  ];
  fbq(TagNames.AddToCart, custom_data, eventID, isSleep);
  sentFbGraphAPIPost(data, isSleep);
};

function Trail_Event(arr, tagName, grand_total) {
  if (arr) {
    let additionParams = {
      value: parseFloat(grand_total),
      currency: 'USD',
    };
    let data = {
      event_name: tagName,
      event_source_url: window.location.origin,
      custom_data: { ...arr, ...additionParams },
    };
    return data;
  }
}

const FBActionOrders = (products, cartData, orderFor) => {
  let cartProductIds = [];
  let eventItems = [];
  let grand_total = cartData.quote['grand_total'];
  let custom_data = '';
  const emailId = encryptStrToSHA256(orderFor.email);

  for (let [key, item] of Object.entries(cartData.items)) {

 
    if (item.key === productsKeyConstants.RF_RELIEF_FACTOR_QS) {
      custom_data = getProductList([item.key], products, cartData.quote);
      eventItems.push(
        Trail_Event(custom_data, TagNames.StartTrial, grand_total)
      );
    } else if (item.key === productsKeyConstants.RF_Z_FACTOR) {
      custom_data = getProductList([item.key], products,cartData.quote);
      eventItems.push(
        Trail_Event(custom_data, TagNames.SleepTrial, grand_total)
      );
    } else if (item.key === productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE || item.key === productsKeyConstants.RF_GO_FACTOR_SINGLE || item.key === productsKeyConstants.RF_ZEN_FACTOR_SINGLE  ) {
      custom_data = getProductList([item.key], products,cartData.quote);
      eventItems.push(Trail_Event(custom_data, TagNames.Purchase, grand_total));
    } else {
      cartProductIds.push(item.key);
    }
  }
  if (cartProductIds.length > 0) {
    custom_data = getProductList(cartProductIds, products,cartData.quote);
    eventItems.push(Trail_Event(custom_data, TagNames.Subscribe, grand_total));
  }
  return {
    emailId: emailId,
    items: eventItems,
    firstName: encryptStrToSHA256(orderFor.firstName),
    lastName: encryptStrToSHA256(orderFor.lastName),
  };
};
const FBCancelSubscription = (
  subscriptionData,
  displayCustomerInfo
) => {

  var items = subscriptionData.items;  
  var isSleep = false;
  items.forEach(function (item, index) {  
    if ((item.sku == 'ZFA-60-01') || (item.sku == 'SLP-60-01' )) {
      isSleep = true;
    } 
  });   
 
  const emailId = encryptStrToSHA256(displayCustomerInfo.email);
  let user_data = {
    ...getClientUserData(),
    em: [emailId],
    subscription_id: subscriptionData.id,
    fbc: getCookieByName('_fbc'),
    fbp: getCookieByName('_fbp'),
  };
  const eventID = getUniqueEventId();
  let data = [
    {
      event_id: eventID,
      event_name: TagNames.CancelSubscription,
      event_time: currentTimeStamp,
      event_source_url: getURLWithoutQueryParam(),
      action_source: 'website',
      user_data: user_data,
      custom_data: {
        currency: 'USD',
        value: parseFloat(subscriptionData.grand_total),
      },
    },
  ];
  fbq(TagNames.CancelSubscription, data[0].custom_data, eventID, isSleep);
  sentFbGraphAPIPost(data, isSleep);
};
export {
  FBPageView,
  FBViewContent,
  FBAddToCartPage,
  FBAddToCart,
  FBActionOrders,
  FBCancelSubscription,
};
