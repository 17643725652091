import { config } from '../../config';

export const structuredData = {
  homePage: {
    '@context': 'http://www.schema.org',
    '@type': 'Organization',
    'name': 'Relief Factor',
    'url': `${config.SiteURL}`,
    'sameAs': [
      'https://www.facebook.com/relieffactor/',
      'https://twitter.com/relieffactor',
      'https://www.instagram.com/relieffactor/',
    ],
    'logo': `${config.SiteURL}media/rf60-front-1.png`,
    'description':
      'Pain is a key reason why people go to a doctor. Relief Factor was designed to help your body reduce pain associated with aging, exercise, and everyday living. Physicians at a well-known clinic in the Pacific Northwest were asked by their patients to create a 100% drug-free supplement to help their bodies lower or eliminate pain. As a result of much research, four ingredients were combined together in one amazing product that we now call Relief Factor.',
    'contactPoint': {
      '@type': 'ContactPoint',
      'telephone': '833-888-1533',
      'contactType': 'Customer Service',
    },
    '@context': 'https://schema.org',
    '@type': 'Product',
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '5',
      'reviewCount': '3',
    },
    'description':
      'Relief Factor is a unique combination of botanical ingredients and fish oil. It was formulated by physicians and is now trusted by many thousands to provide ongoing relief from their pain…back, neck, shoulder, hip and knee pain, including general muscle aches and pains.',
    'name': 'Relief Factor',
    'image': `${config.SiteURL}media/rfqs-front-1.png`,
    'offers': {
      '@type': 'Offer',
      'availability': 'https://schema.org/InStock',
      'price': '79.95',
      'priceCurrency': 'USD',
    },
    'review': [
      {
        '@type': 'Review',
        'author': {
          '@type': 'Person',
          'name': 'Alan',
        },
        'reviewBody':
          'I heard about Relief Factor from my wife…I took it every day, three times a day. I woke up, rolled over and told her ’The pain is gone!',
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': '5',
          'worstRating': '1',
        },
      },
      {
        '@type': 'Review',
        'author': {
          '@type': 'Person',
          'name': 'Sandra',
        },
        'reviewBody':
          'Relief Factor, I feel…has improved my quality of life…It’s like the best thing in the world.',
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': '5',
          'worstRating': '1',
        },
      },
      {
        '@type': 'Review',
        'author': {
          '@type': 'Person',
          'name': 'Dale',
        },
        'reviewBody':
          'If your knees hurt bad enough - which mine were - you’ll try just about anything…After four days, I thought ‘My knees aren’t hurting!’ I’ve been taking it ever since.',
        'name': 'Value purchase',
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': '5',
          'worstRating': '1',
        },
      },
    ],
  },

  quickStart: {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '5',
      'reviewCount': '3',
    },
    'description':
      'Relief Factor is a unique combination of botanical ingredients and fish oil. It was formulated by physicians and is now trusted by many thousands to provide ongoing relief from their pain…back, neck, shoulder, hip and knee pain, including general muscle aches and pains.',
    'name': 'Relief Factor QuickStart',
    'image': `${config.SiteURL}media/relief-factor-quickstart-guide.png`,
    'offers': {
      '@type': 'Offer',
      'availability': 'https://schema.org/InStock',
      'price': '19.95',
      'priceCurrency': 'USD',
    },
    'review': [
      {
        '@type': 'Review',
        'author': {
          '@type': 'Person',
          'name': 'Vicki Looney',
        },
        'reviewBody':
          'This is an AMAZING product which has saved me from having a second hip surgery. If I had known about it sooner, perhaps it would have kept me from having a BOTH knees and my right hip replaced. So this is like a miracle things for me to recommend it HIGHLY. If you have joint issues or anything similar, this product is the ANSWER.',
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': '5',
          'worstRating': '1',
        },
      },
      {
        '@type': 'Review',
        'author': {
          '@type': 'Person',
          'name': 'Steve Parkes',
        },
        'reviewBody':
          'The day I retired, I began experiencing pain in my left knee. It hurt to walk and plying golf or running arround with my grand kids was out of the question. After a few weeks on Relief Factor I’m back on the golf course and running arround in the yard with my grand kids. Thanx Relief Factor !!!!',
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': '5',
          'worstRating': '1',
        },
      },
      {
        '@type': 'Review',
        'author': {
          '@type': 'Person',
          'name': 'Phil Hauser',
        },
        'reviewBody':
          'This stuff is great and it really work . I recommend it friends all the time.',
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': '5',
          'worstRating': '1',
        },
      },
    ],
  },
  calm: {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'description':
      "A powerful blend of herbal extracts, Zen Factor helps your body effectively deal with stress by managing your body's level of cortisol - a hormone produced during stress - and by supporting your body's healthy nervous system function and restful sleep.",
    'name': 'Zen Factor',
    'image': `${config.SiteURL}media/relief-factor-calm.png`,
    'offers': {
      '@type': 'Offer',
      'availability': 'https://schema.org/InStock',
      'price': '21.95',
      'priceCurrency': 'USD',
    },
  },
  faq: {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': [
      {
        '@type': 'Question',
        'name':
          'What are the exact amounts of each ingredient in Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'You can find our ingredient label on our homepage under “The Ingredients” section.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What kind of oil does Omega-3 come from?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Our Omega-3 is derived from wild caught mackerel, anchovy, and sardine oil, molecularly distilled to remove impurities such as mercury and other heavy metals.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is the difference between Turmeric and Curcumin?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Curcumin is the active substance (flavinoid) that comes from the spice Turmeric, a plant of the ginger family.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Does Relief Factor contain wheat or gluten?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': 'There is no wheat or gluten in Relief Factor.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Is this a vegan-friendly product?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'The dry capsules are botanical, and contain no animal products, so they should fit anybody’s definition of vegan. On the other hand, the Omega-3 acids are extracted from fish oil and placed inside a soft gel made of beef gelatin, which would disqualify the supplements from vegan diets.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Are Relief Factor’s ingredients grown with pesticides?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'These are not organically grown herbs, so there is a possibility of pesticides/herbicides, but this doesn’t mean that any were used. The herbs may technically be organic but the supplier may not have decided to go through the costly process of obtaining true organic certification.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is your method of shipping?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'We ship our packages via UPS or USPS, to all deliverable addresses within the United States, including Post Office Boxes.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Do you ship to other countries?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'We are unable to ship outside the United States at this time.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is your return/refund policy?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'We accept returns of any Relief Factor product within 90 days of original purchase that has the original blue and green bag intact, unopened, and unmarked in any way. To return Relief Factor product, simply write refused on the outside of the package and place it in your mailbox or take it to your local post office. Once we receive it back, we will issue a refund less the cost of shipping. If the outer, white package has been opened, you must pay the return shipping costs. Return Address: Relief Factor by Promedev, Customer Care 11031 117th Place NE, Kirkland, WA 98033',
        },
      },
      {
        '@type': 'Question',
        'name': 'How can I order Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'You can order online at our website or by calling us at 888-488-3112 (Every Day 6am - Midnight ET). Relief Factor is not available in stores or on any other websites.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is the monthly cost?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'If you order the 3-Week QuickStart for $19.95 then the following full month’s supply of 60 packets is only $79.95 each month. However, without ordering the 3-Week QuickStart and becoming a preferred member, a single month supply of 60 packets is $93.95 plus S&H. This is why nearly 95% of people begin by ordering the 3-Week QuickStart.',
        },
      },
      {
        '@type': 'Question',
        'name':
          'What forms of payment do you accept? Can I use my Health Savings Account (HSA) card or PayPal, etc.?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'We accept all major credit cards, but not PayPal, checks, or money orders. To use your HSA card, you will need to contact them and get prior approval before ordering with us. Some companies will cover Relief Factor and some will not.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What exactly is Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor is a supplement for pain relief. It is a non-addictive drug-free product that provides relief from everyday aches and pains.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Can Relief Factor cause or raise high blood pressure?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'The active ingredients in Relief Factor are botanical extracts and fish oil. They do not raise blood pressure. The supplement supports your body’s healthy response to inflammation, one of the key sources of pain, including muscle and back pain.',
        },
      },
      {
        '@type': 'Question',
        'name': "What's the monthly cost of Relief Factor?",
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor is a reasonably priced supplement, costing just $79.95 with a subscription, plus shipping and handling and any applicable sales tax. ',
        },
      },
      {
        '@type': 'Question',
        'name': 'Is Relief Factor the real deal?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Yes, Relief Factor is the real deal. The supplement has been recommended by doctors for many years and contains ingredients that have been known to provide temporary relief from everyday aches and pains.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is the deal with Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor is a unique supplement formula for people who suffer from joint, muscle, and back pain symptoms who want to live their best lives free of every day aches and pains.',
        },
      },
      {
        '@type': 'Question',
        'name': 'How does Relief Factor work?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            "Relief Factor works by supporting the body’s natural healthy response to inflammation, providing relief from aches and pains, commonly caused by aging, exercise and everyday living. It's drug-free and easy to use without unpleasant side effects.",
        },
      },
      {
        '@type': 'Question',
        'name': "What's the actual cost of Relief Factor?",
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Yes. The simplest way is to call 833-888-1533, and select “cancel.” Your subscription will be automatically canceled within 24 hours. You can also email Relief Factor at contactus@relieffactor.com or submit a cancellation request at http://www.relieffactor.com/support.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Is it easy to cancel Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Curcumin is the active substance (flavinoid) that comes from the spice Turmeric, a plant of the ginger family.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What are the downsides of Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            "It's important to realize that Relief Factor will not work for everyone. The purpose of the supplement is to provide temporary relief from various symptoms, such as muscle and back pain. In rare circumstances, some customers experience stomach discomfort when first starting to take Relief Factor, but those symptoms tend to go away after a few days as your body adjusts to the formula.",
        },
      },
      {
        '@type': 'Question',
        'name': 'What is the difference between Turmeric and Curcumin?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Curcumin is the active substance (flavinoid) that comes from the spice Turmeric, a plant of the ginger family.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Does Relief Factor reduce inflammation?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor works by supporting your body’s healthy response to inflammation through four metabolic pathways. In turn, your body is then able to reduce inflammation throughout your body. This helps to reduce pain in muscles but also reduces swelling, stiffness, and other symptoms that cause pain.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is the difference between Turmeric and Curcumin?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Curcumin is the active substance (flavinoid) that comes from the spice Turmeric, a plant of the ginger family.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Is Relief Factor a pill or drink?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Each dose of Relief Factor consists of two gelcaps and two capsules, packaged in a convenient easy-to-open cellophane packet.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is the difference between Turmeric and Curcumin?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Curcumin is the active substance (flavinoid) that comes from the spice Turmeric, a plant of the ginger family.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Is Relief Factor safe?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Many of the ingredients in Relief Factor occur in foods we eat and drink every day, but in such small quantities that we don’t feel the same effects. Relief Factor simply provides those key ingredients in substantially high quantities, at a high-enough potency to help the body reduce or eliminate aches and pains.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What kind of pain does Relief Factor help?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Because Relief Factor supports the body’s natural healthy response to inflammation which is the source of many aches and pains. These pains include back pain, neck pain, shoulder pain, elbow pain, wrist pain, hand pain, hip pain, knee pain, ankle pain and foot pain caused by aging, exercise or simply everyday living.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Does the FDA approve of Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'As a dietary supplement Relief Factor is not subject to FDA approval; however, Relief Factor is manufactured and distributed using the FDA’s Good Manufacturing Practice (GMP).',
        },
      },
      {
        '@type': 'Question',
        'name': 'Is the product Relief Factor legitimate?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Yes, Relief Factor is a genuine product distributed by Promedev LLC in the United States of America. It has a legitimate Google+ and Facebook page.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Who owns Relief Factor?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor is a brand owned by Promedev LLC, a private company based in Kirkland, Washington.',
        },
      },
      {
        '@type': 'Question',
        'name': 'What is Relief Factor made of?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor has four key ingredients: omega-3s from fish oil derived from wild-caught mackerel, sardines and anchovies; curcumin, the active flavinoid in turmeric; resveratrol, the powerful antioxidant commonly associated with red wine; and icariin, which promotes healthy blood flow.',
        },
      },
      {
        '@type': 'Question',
        'name': 'Does Relief Factor contain soy?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text':
            'Relief Factor does contain trace amounts of soy, as the Vitamin E in our Omega-3 capsule is derived from soy.',
        },
      },
    ],
  },
  energy: {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'description':
      'Energy is full of caffeine and taurine, in thoughtful amounts. No sugar, adding calories and contributing to a crash. Plus a healthy dose of B-Complex, further enhancing your metabolic efficiency. Energy helps you keep going, and helps your body make better use of your caloric intake, keeping you sharp and in the game. Because it’s a supplement, you know exactly how much caffeine and taurine you’re getting, so you’re in control. And Go Factor is cheaper than a $4 bottle or cup of coffee, to get you through the afternoon.',
    'name': 'Go Factor',
    'image': `${config.SiteURL}media/relief-factor-energy.png`,
    'offers': {
      '@type': 'Offer',
      'availability': 'https://schema.org/InStock',
      'price': '34.95',
      'priceCurrency': 'USD',
    },
  },
  sleep: {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'description':
      "Sleep accounts for about a third of our life. It's the time our body uses to repair and restore our body and mind. Z Factor works with your body's own systems to address multiple causes of disrupted sleep.",
    'name': 'Z Factor',
    'image': `${config.SiteURL}media/relief-factor-sleep.png`,
    'offers': {
      '@type': 'Offer',
      'availability': 'https://schema.org/InStock',
      'price': '19.95',
      'priceCurrency': 'USD',
    },
  },
};
