import Carousel from 'react-material-ui-carousel';

const CarouselComponent = ({ Item = <></>, data = [], ...rest }) => {
  return (
    <Carousel {...rest}>
      {data.map((item, i) => (
        <Item key={i} {...item} />
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
