import apiCall from '../../../commons/helpers/apiCall';

// Connected
export async function fetchUpdateSubscriptionData(data) {
  const result = await apiCall({
    endpoint: 'subscription',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { data: data },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchActiveSubscriptions(customerId) {
  const result = await apiCall({
    endpoint: `subscriptions?customerId=${customerId}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchActiveSubscriptionsWithLimit(customerData) {
  const result = await apiCall({
    endpoint: `subscriptions?customerId=${customerData?.customerId}&limit=${customerData?.limit}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

// No current use case - Will implement in future
export async function fetchActiveSubscriptionsWithLimitAndPage(customerData) {
  const result = await apiCall({
    endpoint: `subscriptions?customerId=${customerData.customerId}&limit=${customerData.limit}&currPage=${customerData.currentPage}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchSubscriptionDetails(subscriptionId, userToken) {
  const result = await apiCall({
    endpoint: `subscription/${subscriptionId}`,
    version: 'V4',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchSubscriptionCancellationReasons() {
  const result = await apiCall({
    endpoint: `subscriptions/cancellationReasons`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
    authorize: false,
  });
  return result;
}

export async function fetchAffectedSubscriptions({
  addressId,
  recurringId,
  addressType,
}) {
  const result = await apiCall({
    endpoint: `subscriptionIds?addressId=${addressId}&addressType=${addressType}&recurringId=${recurringId}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchUpdateSubscriptionPaymentData(data) {
  const result = await apiCall({
    endpoint: 'subscription',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { data: data },
      redirect: 'follow',
    },
  });
  return result;
}
