import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchPostList,
  fetchAllPostWithPagination,
  fetchPostListByCatgory,
  fetchAllCatgory,
  fetchPostSingle,
  fetchAllTags,
  fetchPostListByTag,
} from './resourceApi';

const initialState = {
  postList: [],
  categotyList: [],
  currentPost: {},
  totalPost: null,
  tagsList: [],
  allPostList: [],
};

export const getPostList = createAsyncThunk(
  'resource/fetchPostList',
  async () => {
    const response = await fetchPostList();
    return response;
  }
);
export const getPostListSingle = createAsyncThunk(
  'resource/fetchPostSingle',
  async (id) => {
    const response = await fetchPostSingle(id);
    return {
      data: response,
    };
  }
);

export const getPostWithPagination = createAsyncThunk(
  'resource/fetchAllPostWithPagination',
  async (data) => {
    const response = await fetchAllPostWithPagination(data);
    return response;
  }
);

export const getPostByCategory = createAsyncThunk(
  'resource/fetchPostListByCatgory',
  async (data) => {
    const response = await fetchPostListByCatgory(data);
    return response;
  }
);
export const getPostByTag = createAsyncThunk(
  'resource/fetchPostListByTag',
  async (data) => {
    const response = await fetchPostListByTag(data);
    return response;
  }
);

export const getCategoryList = createAsyncThunk(
  'resource/fetchAllCatgory',
  async () => {
    const response = await fetchAllCatgory();
    return response;
  }
);

export const getTagsList = createAsyncThunk(
  'resource/fetchAllTags',
  async () => {
    const response = await fetchAllTags();
    return response;
  }
);

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    resetPostList: (state, action) => {
      state.postList = action.payload;
    },
    resetTotalPost: (state, action) => {
      state.totalPost = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostList.fulfilled, (state, action) => {
        state.allPostList = action.payload.posts;
        // state.totalPost = action.payload.len;
      })
      .addCase(getPostWithPagination.fulfilled, (state, action) => {
        state.postList = action.payload.posts;
        state.totalPost = action.payload.total_count;
      })
      .addCase(getPostByCategory.fulfilled, (state, action) => {
        state.postList = action.payload.posts;
        state.totalPost = action.payload.total_count;
      })
      .addCase(getPostByTag.fulfilled, (state, action) => {
        state.postList = action.payload.posts;
        state.totalPost = action.payload.total_count;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.categotyList = action.payload;
      })
      .addCase(getTagsList.fulfilled, (state, action) => {
        state.tagsList = action.payload;
      })
      .addCase(getPostListSingle.fulfilled, (state, action) => {
        state.currentPost = action.payload;
      });
  },
});

export const getCurrentPost = (state) => state.resource.currentPost;
export const getTotalPostCount = (state) => state.resource.totalPost;
export const getAllPostList = (state) => state.resource.postList;
export const getAllCategoryList = (state) => state.resource.categotyList;
export const getAllTagsList = (state) => state.resource.tagsList;
export const getTotalPostList = (state) => state.resource.allPostList;
export const { resetPostList, resetTotalPost } = resourceSlice.actions;
export default resourceSlice.reducer;
