import React from 'react';
import styles from './CVVInput.module.scss';
import { getValidationMessage } from '../../../utils';
import {
  Visa,
  Amex,
  MasterCard,
  QuestionIcon,
} from '../../../../src/assets/images/icons';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button } from '@mui/material';

const CVVTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


const CVVInput = ({
  key,
  label = '',
  register,
  errors,
  type = 'text',
  required = false,
  helperText = '',
  errorType = '',
  showError = true,
  ...rest
}) => {
  return (
    <>
      <div
        className={`${styles.inputContainer} ${
          label ? '' : styles.paymentInput
        } ${label ? '' : styles.column}`}
      >
        {label && (
          <label>
            {label}
            {required && <div className={styles.requiredAsterisk}>*</div>}
          </label>
        )}
        <div className={styles.cardInputMain}>
          <input
            {...register}
            type={type}
            className={`${
              errors[register.name]?.type ? styles.inputError : ''
            }`}
            {...rest}
          />
          <div className={styles.cardIconWrapper}>
            <CVVTooltip title="3-digit code on back of card">
              <Button style={{maxWidth: "30px", minWidth: "30px"}}><QuestionIcon /></Button>
            </CVVTooltip>
          </div>
        </div>
        {showError && getValidationMessage(errors, register.name, errorType)}
        {helperText && <p className={styles.helperWrapper}>{helperText}</p>}
      </div>
    </>
  );
};

export default CVVInput;
