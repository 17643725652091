import { postUiLogs } from '../../../../src/features/user/userApi';
import { config, productsKeyConstants } from '../../../../config';
import {
  getProductCartItems,
  getProductItems,
  getTwitterProductList,
  removeSpecialCharFromPhoneNo,
} from '../utils';
import { isBrowserFeature } from '../../../utils/isBrowserFeature';
import { COOKIE } from '../../../Constants';
import { TagNames } from './Constants';
import { cartExtract } from '../../../utils';
import { irePurchaseEvents } from '../ire';

const configProducts = config.products;


function getLocalISOTime() {
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';

  const pad = (num, size = 2) => {
    return String(num).padStart(size, '0');
  };

  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    pad(now.getMilliseconds(), 3) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

function getClientID() { 
  var match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)'),
  raw = match ? decodeURIComponent(match[1]) : null;
if (raw) {
  match = raw.match(/(\d+\.\d+)$/)
}
return (match) ? match[1] : null;

}



const EventAction = (action, label, params) => {
  const category = 'event';
  let gaPrefix = '';
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('config', config.ga.measurement_id);
  if (typeof gtag !== 'undefined') {
    gtag(category, action, params);
  }

  if (window?.isAdBlockerEnabled) {
    gaPrefix = 'AdBlockerEnabled ';
  }
  if (!isBrowserFeature(COOKIE)) {
    gaPrefix += 'CookieDisabled ';
  }
  let gaClientID = '';
  if (typeof window.gaGlobal !== 'undefined') {
    gaClientID = window.gaGlobal.vid;
  }
  let payload = {
    message: `GAEvents: ${gaPrefix}- ${action}`,
    data: {
      action: action,
      client_id: gaClientID,
      params,
    },
  };
  if (config.gaLog == 'true') {
    postUiLogs(payload);
  }
};

const GAHeaderNavbarClick = (
  value,
  event_category = 'Product',
  event_label = 'Order Now'
) => {
  let params = {
    event_category: event_category,
    event_label: event_label,
    value: parseFloat(value),
  };
  EventAction(TagNames.Click, '', params);
};

const GAPageView = () => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('config', config.ga.measurement_id, {
      page_title: document.title,
      page_path: window.location.pathname,
      send_page_view: false,
      HitTimestamp:getLocalISOTime(), 
      ga_client_id:getClientID(),
    });
  }
  let params = {
    page_title: document.title,
    page_path: window.location.pathname,
  };

  EventAction(TagNames.PageView, '', params);
};

const GAPageViewItem = (productList, products, subMap = {}) => {
  let productItems = getProductItems(productList, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.ViewItem, '', params);
};

const GASelectedItem = (index, productData, recurringPrice = true) => {
  const quoteParams = productData?.quoteParams;
  let data = {
    item_id: quoteParams.productId,
    item_name: productData.productName,
    affiliation: 'Relief Factor',
    coupon: quoteParams.couponCode,
    currency: 'USD',
    discount: '',
    index: index,
    item_brand: '',
    item_category: productData.sku,
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: productData.isSubscription,
    item_list_id: '',
    item_list_name: '',
    item_variant: '',
    location_id: '',
    price: productData.isSubscription
      ? productData.initialPrice
      : productData.single_price,
    quantity: quoteParams.quantity,
    is_subscription: productData.isSubscription,
  };
  const productItems = [data];
  let params = {
    event_type: productData.title,
    currency: 'USD',
    value: 1,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.SelectItem, '', params);
  /*const orderNowGAEventName = getGAOrderNowEventName(productData);
  if (orderNowGAEventName != null) {
    params['event_type'] = orderNowGAEventName;
    EventAction(TagNames.GACustomEvent, '', params);
  }*/
};

const GA4MiniCart = (index, productData, qtyupdate = '', cartFlag) => {
  const quoteParams = productData?.quoteParams;
  if (qtyupdate) {
    var qty = qtyupdate;
    var price = productData.isSubscription
      ? productData.initialPrice
      : productData.single_price;

    var calculatedPrice = qty * parseFloat(price);
    calculatedPrice = calculatedPrice.toFixed(2);
  } else {
    var qty = quoteParams.quantity;
    var calculatedPrice = productData.isSubscription
      ? productData.initialPrice
      : productData.single_price;
  }

  let data = {
    item_id: quoteParams.productId,
    item_name: productData.productName,
    affiliation: 'Relief Factor',
    coupon: quoteParams.couponCode,
    currency: 'USD',
    discount: '',
    index: index,
    item_brand: '',
    item_category: productData.sku,
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: productData.isSubscription,
    item_list_id: '',
    item_list_name: '',
    item_variant: '',
    location_id: '',
    price: calculatedPrice,
    quantity: qty,
    is_subscription: productData.isSubscription,
  };
  const productItems = [data];
  let params = {
    event_type: productData.title,
    cart_flag: cartFlag,
    currency: 'USD',
    value: 1,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.AddToCart, '', params);
  /*const orderNowGAEventName = getGAOrderNowEventName(productData);
  if (orderNowGAEventName != null) {
    params['event_type'] = orderNowGAEventName;
    EventAction(TagNames.GACustomEvent, '', params);
  }*/
  TwitterMiniCart(index, productData, qty, calculatedPrice);
};

const GA4RemoveCart = (index, productData, recurringPrice = true) => {
  const quoteParams = productData?.quoteParams;
  let data = {
    item_id: quoteParams.productId,
    item_name: productData.productName,
    affiliation: 'Relief Factor',
    coupon: quoteParams.couponCode,
    currency: 'USD',
    discount: '',
    index: index,
    item_brand: '',
    item_category: productData.sku,
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: productData.isSubscription,
    item_list_id: '',
    item_list_name: '',
    item_variant: '',
    location_id: '',
    price: productData.isSubscription
      ? productData.initialPrice
      : productData.single_price,
    quantity: quoteParams.quantity,
    is_subscription: productData.isSubscription,
  };
  const productItems = [data];
  let params = {
    event_type: productData.title,
    currency: 'USD',
    value: 1,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.RemoveCart, '', params);
  /*const orderNowGAEventName = getGAOrderNowEventName(productData);
  if (orderNowGAEventName != null) {
    params['event_type'] = orderNowGAEventName;
    EventAction(TagNames.GACustomEvent, '', params);
  }*/
};

function getGAOrderNowEventName(productData) {
  if (
    configProducts[productsKeyConstants.RF_QUICKSTART].sku === productData.sku
  ) {
    return TagNames.OrderNowQS;
  } else if (
    configProducts[productsKeyConstants.RF_60CT].sku === productData.sku &&
    productData.isSubscription
  ) {
    return TagNames.OrderNow60Pack;
  } else if (
    configProducts[productsKeyConstants.RF_60CT_SINGLE].sku ===
      productData.sku &&
    !productData.isSubscription
  ) {
    return TagNames.OrderNowSingle;
  } else if (
    configProducts[productsKeyConstants.RF_ENERGY1].sku === productData.sku
  ) {
    return TagNames.OrderNowEnergy;
  } else if (
    configProducts[productsKeyConstants.RF_CALM].sku === productData.sku
  ) {
    return TagNames.OrderNowCalm;
  } else if (
    configProducts[productsKeyConstants.RF_SLEEP].sku === productData.sku
  ) {
    return TagNames.OrderNowSleep;
  } else {
    return null;
  }
}

const GAAddItemToCart = (productList, cart, products, subMap = {}) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.AddToCart, '', params);
  TwitterAddToCart(products, productList, cart);
};
const GABeginCheckout = (productList, cart, products, subMap = {}) => {
  var quote = cart.quote;

  let productItems = getProductCartItems(productList, quote, products, subMap);

  let params = {
    currency: 'USD',
    value: 1,
    coupon: '',
    shipping_tier: '',
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.BeginCheckout, '', params);
};
const GAAddShippingInfo = (productList, cart = {}, products, subMap = {}) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    coupon: '',
    shipping_tier: '',
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.AddShippingInfo, '', params);
};
const GAAddBillingInfo = (productList, cart = {}, products, subMap = {}) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    event_type: TagNames.AddBillingInfo,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.GACustomEvent, '', params);
};
const GAAddPaymentInfo = (productList, cart = {}, products, subMap = {}) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    coupon: '',
    payment_type: 'Credit Card',
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.AddPaymentInfo, '', params);
};
const GAViewPromotion = (items, parentId) => {
  const data = {
    creative_name: '',
    creative_slot: '',
    location_id: '',
    promotion_id: '',
    promotion_name: 'Free Gift',
    parent_id: parentId,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: items,
  };
  EventAction(TagNames.ViewPromotion, '', data);
};
const GASelectPromotion = (item, parentId = '') => {
  const data = {
    creative_name: '',
    creative_slot: '',
    location_id: '',
    promotion_id: '',
    promotion_name: 'Free Gift',
    parent_id: parentId,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: [item],
  };
  EventAction(TagNames.SelectPromotion, '', data);
};

const GACheckoutAction = (productList, products, step, event_label) => {
  let productItems = getProductItems(productList, products);
  EventAction(TagNames.SetCheckoutOption, '', {
    checkout_step: step,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  });
  EventAction(TagNames.CheckoutOptionView, '', {
    event_category: 'checkout',
    event_label: event_label,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  });
};
const GAPurchaseOrder = (
  products,
  cartData,
  orderId,
  orderFor = {},
  isCustomer
) => {
  let { productList, subMap } = cartExtract(cartData.items);

  let productItems = getProductCartItems(
    productList,
    cartData.quote,
    products,
    subMap
  );
  if (cartData.quote !== '' && cartData.quote !== null) {
    let { tax, shipping_amount, coupon_code, grand_total } = cartData.quote;
    grand_total = parseFloat(grand_total);
    let params = {
      currency: 'USD',
      transaction_id: orderId,
      is_guest: isCustomer,
      affiliation: 'Relief Factor',
      tax: tax,
      shipping: shipping_amount,
      coupon: coupon_code,
      value: grand_total,
      HitTimestamp:getLocalISOTime(), 
      ga_client_id:getClientID(),
      items: productItems,
    };
    EventAction(TagNames.Purchase, '', params);
    TwitterActionOrders(products, cartData, orderId, orderFor);
    irePurchaseEvents(cartData.quote, orderId);
  }
};

const TwitterActionOrders = (products, cartData, orderId, orderFor = '') => {
  let grand_total = cartData.quote['grand_total'];
  let eventList = {
    start_trial: { contents: [] },
    subscribe: { contents: [] },
    purchase: { contents: [] },
  };
  for (let [key, item] of Object.entries(cartData.items)) {
    let contents = getTwitterProductList([item.key], products, cartData.quote);
    if (item.key === productsKeyConstants.RF_RELIEF_FACTOR_60_CT) {
      eventList.start_trial['contents'] =
        eventList.start_trial['contents'].concat(contents);
    } else if (!item.isSubscription) {
      eventList.purchase['contents'] =
        eventList.purchase['contents'].concat(contents);
    } else {
      eventList.subscribe['contents'] =
        eventList.subscribe['contents'].concat(contents);
    }
  }
  for (let [key, value] of Object.entries(eventList)) {
    if (value.contents.length > 0) {
      const params = {
        value: grand_total,
        currency: 'USD',
        contents: value.contents,
        conversion_id: orderId,
        email_address: orderFor.email,
        phone_number: removeSpecialCharFromPhoneNo(orderFor.phone),
      };
      EventAction('twitter_' + key, '', params);
    }
  }
};
const TwitterAddToCart = (products, cartItems, cart) => {
  let contentsData = getTwitterProductList(cartItems, products, cart);
  let params = {
    currency: 'USD',
    contents: contentsData,
  };
  EventAction(TagNames.TwitterAddToCart, '', params);
};

const TwitterMiniCart = (index, productData, qty, price) => {
  let contentsData = [];

  const quoteParams = productData?.quoteParams;
  const data = {
    content_type: 'product',
    content_id: quoteParams.productId,
    content_name: productData.name,
    content_price: price,
    num_items: qty,
    content_group_id: null,
  };

  contentsData.push(data);
  let params = {
    currency: 'USD',
    contents: contentsData,
  };
  EventAction(TagNames.TwitterAddToCart, '', params);
};

const GAAddCustomerInfo = (productList, cart = {}, products, subMap = {}) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    event_type: TagNames.AddCustomerInfo,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.GACustomEvent, '', params);
};
const GASubscriptionConfirm = (
  productList,
  cart = {},
  products,
  subMap = {}
) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    event_type: TagNames.SubscriptionConfirm,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.GACustomEvent, '', params);
};
const GAUSPSValidation = (productList, cart = {}, products, subMap = {}) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    event_type: TagNames.GAUSPSValidation,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.GACustomEvent, '', params);
};

const GAUSPSVerificationPassed = (
  productList,
  cart = {},
  products,
  verificationType,
  subMap = {}
) => {
  let productItems = getProductCartItems(productList, cart, products, subMap);
  let params = {
    currency: 'USD',
    value: 1,
    event_type: TagNames.GAUSPSVerificationPassed,
    verification_type: verificationType,
    HitTimestamp:getLocalISOTime(), 
    ga_client_id:getClientID(),
    items: productItems,
  };
  EventAction(TagNames.GACustomEvent, '', params);
};



export {
  GAHeaderNavbarClick,
  GAPageViewItem,
  GASelectedItem,
  GAAddItemToCart,
  GA4MiniCart,
  GA4RemoveCart,
  GAViewPromotion,
  GASelectPromotion,
  GACheckoutAction,
  GAPurchaseOrder,
  GAAddShippingInfo,
  GAAddBillingInfo,
  GABeginCheckout,
  GAAddPaymentInfo,
  GAPageView,
  GAAddCustomerInfo,
  GASubscriptionConfirm,
  GAUSPSValidation,
  GAUSPSVerificationPassed,
  TwitterActionOrders,
};
