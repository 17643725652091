import key from './publicKey.json';
import forge from 'node-forge';

export const encryptionForPaymentInfo = (data) => {
  const publicKey = key.publicKey;
  var obj = JSON.stringify(data);
  const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
  const encryptedData = forge.util.encode64(publicKeyObj.encrypt(obj));
  // console.log("-----------",encryptedData);
  return encryptedData;
};
