import Router from 'next/router';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchUpdateSubscriptionData,
  fetchActiveSubscriptions,
  fetchActiveSubscriptionsWithLimit,
  fetchActiveSubscriptionsWithLimitAndPage,
  fetchSubscriptionDetails,
  fetchSubscriptionCancellationReasons,
  fetchAffectedSubscriptions,
  fetchUpdateSubscriptionPaymentData,
} from './subscriptionApi';
import { getCustomerInfo, updatePaymentAuth } from '../user/userState';
import { UN_AUTHORIZED } from '../../../commons/Constants';
import { validateCode } from '../../../components/body/checkout/sections/checkoutUtils';

const initialState = {
  subscriptionId: '',
  activeSubscriptions: null,
  subscriptionsLimit3: [],
  cancellationReasons: [],
  subscriptionDetails: {},
  affectedSubscriptions: [],
};

export const subscriptionState = initialState;

export const updateSubscriptionData = createAsyncThunk(
  'user/fetchUpdateSubscriptionData',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      let payload = { ...data };
      if (payload.isFree) {
        delete payload.isFree;
      }
      const response = await fetchUpdateSubscriptionData(payload);
      if (data.statusAction) {
        if (data.statusAction == 'cancel') Router.replace('/account');
        else {
          dispatch(getSubscriptionDetails(data?.recurringId));
          Router.push(`/subscription/${data?.recurringId}`);
        }
      } else {
        dispatch(getSubscriptionDetails(data?.recurringId));
      }
      dispatch(getCustomerInfo(user.userToken));
      return data;
    } catch (error) {
      if (error?.message) throw error;
      return rejectWithValue(data);
    }
  }
);

export const getActiveSubscriptions = createAsyncThunk(
  'user/fetchActiveSubscriptions',
  async (customerId) => {
    const response = await fetchActiveSubscriptions(customerId);
    let payload = response;
    if (response.result) {
      const subscriptions = response.result;
      let maxCount = 6;
      let i = 0;
      const activeSubscriptions = subscriptions.filter(
        (arr) => arr.status === 'Active' && ++i <= maxCount
      );
      maxCount = maxCount - activeSubscriptions.length;
      i = 0;
      const pausedSubscriptions = subscriptions.filter(
        (arr) => arr.status === 'Paused' && ++i <= maxCount
      );
      maxCount = maxCount - pausedSubscriptions.length;
      i = 0;
      const disabledSubscriptions = subscriptions.filter(
        (arr) => arr.status === 'Disabled' && ++i <= maxCount
      );
      payload = {
        activeSubscriptions: subscriptions,
        subscriptionsLimit3: [
          ...activeSubscriptions,
          ...pausedSubscriptions,
          ...disabledSubscriptions,
        ],
      };
    }
    return payload;
  }
);

export const getActiveSubscriptionsWithLimit = createAsyncThunk(
  'user/fetchActiveSubscriptionsWithLimit',
  async (customerData) => {
    const response = await fetchActiveSubscriptionsWithLimit(customerData);
    return response;
  }
);

export const getActiveSubscriptionsWithLimitAndPage = createAsyncThunk(
  'user/fetchActiveSubscriptionsWithLimitAndPage',
  async (customerData) => {
    const response = await fetchActiveSubscriptionsWithLimitAndPage(
      customerData
    );
    return response;
  }
);

export const getSubscriptionDetails = createAsyncThunk(
  'user/fetchSubscriptionDetails',
  async (subscriptionId, { getState }) => {
    const { user } = getState();
    const response = await fetchSubscriptionDetails(
      subscriptionId,
      user.userToken
    );
    response.forEach((subscription) => {
      // subscription.billing.postcode = validateCode(
      //   subscription.billing.postcode,
      //   subscription.billing.postcode,
      //   5
      // );
      subscription.shipping.postcode = validateCode(
        subscription.shipping.postcode,
        subscription.shipping.postcode,
        5
      );
    });
    return response;
  }
);

export const getAffectedSubscriptions = createAsyncThunk(
  'user/getAffectedSubscriptions',
  async ({ addressId, addressType = 'shipping', recurringId }) => {
    const response = await fetchAffectedSubscriptions({
      addressId,
      addressType,
      recurringId,
    });
    return response;
  }
);

export const getCancellationsReasons = createAsyncThunk(
  'user/fetchSubscriptionCancellationReasons',
  async () => {
    const response = await fetchSubscriptionCancellationReasons();
    return response;
  }
);

export const subscriptionPaymentDetails = createAsyncThunk(
  'user/subscriptionPaymentDetails',
  async (data, { dispatch }) => {
    const response = await fetchUpdateSubscriptionPaymentData(data);
    if (response[0]?.result) {
      setTimeout(() => {
        Router.push('/account');
        dispatch(updatePaymentAuth({}));
      }, 3000);
    }
    return response;
  }
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetAffectedSubscriptions: (state) => {
      state.affectedSubscriptions = [];
    },
    resetSubscription: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveSubscriptions.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(getActiveSubscriptionsWithLimit.fulfilled, (state, action) => {
        state.subscriptionsLimit3 = action?.payload?.result;
      })
      .addCase(getCancellationsReasons.fulfilled, (state, action) => {
        state.cancellationReasons = action.payload;
      })
      .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
        state.subscriptionDetails = {
          ...state.subscriptionDetails,
          [action?.payload[0]?.id]: action?.payload[0],
        };
      })
      .addCase(getAffectedSubscriptions.fulfilled, (state, action) => {
        state.affectedSubscriptions = action.payload?.result || [];
      })
      .addMatcher(
        (action) =>
          action.type.endsWith('rejected') &&
          action?.error?.message === UN_AUTHORIZED,
        (state, action) => {
          return initialState;
        }
      );
  },
});
export const activeSubscriptions = (state) =>
  state.subscription.activeSubscriptions;
export const subscriptionsLimit3 = (state) =>
  state.subscription.subscriptionsLimit3;
export const subscriptionDetails = (state) =>
  state.subscription.subscriptionDetails;
export const getSubscriptionsDetailsByID = (state, id) =>
  state.subscription.subscriptionDetails[id];
export const cancellationReasons = (state) =>
  state.subscription.cancellationReasons;
export const affectedSubscriptions = (state) =>
  state.subscription.affectedSubscriptions;

export const { resetAffectedSubscriptions, resetSubscription } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
