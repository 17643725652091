import { productsKeyConstants, ProductsCategory, products } from './products';

export { productsKeyConstants, ProductsCategory };

const dev = {
  environment: 'dev',
  commandLink: 'https://rftest.commandlink.com',
};

const stage = {
  environment: 'stage',
  commandLink: 'https://rftest.commandlink.com',
};

const beta = {
  environment: 'beta',
  commandLink: 'https://rftest.commandlink.com',
};

const prod = {
  environment: 'production',
  commandLink: 'https://relieffactor.commandlink.com',
};

const setEnv = () => {
  try {
    if (process.env.NEXT_PUBLIC_APP_ENV)
      return envMapping[process.env.NEXT_PUBLIC_APP_ENV];
    else throw new Error('APP_ENV not found! Running Dev instance!');
  } catch (err) {
    console.debug(err);
    return dev;
  }
};

const envMapping = {
  dev: dev,
  develop: dev,
  development: dev,
  stage: stage,
  staging: stage,
  beta: beta,
  production: prod,
  prod: prod,
};

const getValue = (value) => (typeof value != 'undefined' ? value : '');
export const config = {
  products,
  encryption: {
    encrypt: process.env.NEXT_PUBLIC_encryption_encrypt == 'true',
    decrypt: process.env.NEXT_PUBLIC_encryption_decrypt == 'true',
    cryptoJS: {
      hash: process.env.NEXT_PUBLIC_encryption_cryptoJS_hash,
    },
    quoteEncrypt: {
      passphrase: getValue(process.env.NEXT_PUBLIC_PASSPHRASE),
      salt: getValue(process.env.NEXT_PUBLIC_SALT),
      iv_key: getValue(process.env.NEXT_PUBLIC_IV_KEY),
    },
  },
  businessHours: {
    openTime: process.env.NEXT_PUBLIC_BUSINESS_START_HOURS || '00:00',
    closeTime: process.env.NEXT_PUBLIC_BUSINESS_END_HOURS || '23:59',
  },
  supportHours: {
    openTime: process.env.NEXT_PUBLIC_SUPPORT_START_HOURS || '00:00',
    closeTime: process.env.NEXT_PUBLIC_SUPPORT_END_HOURS || '23:59',
  },
  copyright: {
    year: process.env.NEXT_PUBLIC_LICENSE_YEAR,
  },
  fb: {
    pixel_id: getValue(process.env.NEXT_PUBLIC_FB_PIXEL_ID),
    access_token: getValue(process.env.NEXT_PUBLIC_FB_ACCESS_TOKEN),
    pixel_id_sleep: getValue(process.env.NEXT_PUBLIC_FB_SLEEP_PIXEL_ID),
    access_token_sleep: getValue(process.env.NEXT_PUBLIC_FB_SLEEP_ACCESS_TOKEN),
  },
  ga: {
    proxy_url: getValue(process.env.NEXT_PUBLIC_GA_PROXY_URL),
    proxy_domain: getValue(process.env.NEXT_PUBLIC_GA_PROXY_DOMAIN),
    measurement_id: getValue(
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID
    ),
    tracking_id: getValue(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID),
    tag_manager_id: getValue(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID),
    opt_container_id: getValue(
      process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_CONTAINER_ID
    ),
  },
  pinterest: {
    tracking_id: getValue(process.env.NEXT_PUBLIC_PINTEREST),
  },
  sentry: {
    secret_key: getValue(process.env.NEXT_PUBLIC_SENTRY_SECRET_KEY),
    host: getValue(process.env.NEXT_PUBLIC_SENTRY_HOST),
    project_id: getValue(process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID),
    enabled: getValue(process.env.NEXT_PUBLIC_ENABLE_SENTRY) == 'true',
  },
  riskified: {
    domain: process.env.NEXT_PUBLIC_RISKIFIED_STORE_DOMAIN,
  },
  SiteURL: getValue(process.env.NEXT_PUBLIC_SITEMAP_URL),
  jwt_secret: process.env.NEXT_PUBLIC_JWT_SECRET,
  redux: {
    persist: {
      version: parseInt(process.env.NEXT_PUBLIC_REDUX_VERSION || -1),
      validity: process.env.NEXT_PUBLIC_REDUX_PERSIST_VALIDITY,
      purge: getValue(process.env.NEXT_PUBLIC_REDUX_PURGE) == 'true',
    },
  },
  shoppers_id: getValue(process.env.NEXT_PUBLIC_SHOPPERS_ID), // Conversion - Shoppers ID
  rakuten_id: getValue(process.env.NEXT_PUBLIC_RAKUTEN_ID), // Conversion - rakuten ID
  yotpo_id: getValue(process.env.NEXT_PUBLIC_YOTPO_API_KEY),
  alert_banner: getValue(process.env.NEXT_PUBLIC_APP_ALERT_BANNER),
  gaLog: getValue(process.env.NEXT_PUBLIC_APP_GALOG),
  fbLog: getValue(process.env.NEXT_PUBLIC_APP_FBLOG),
  reset_password_expiry: getValue(
    process.env.NEXT_PUBLIC_RESET_PASSWORD_EMAIL_EXPIRY
  ),
  ms_clarity: process.env.NEXT_PUBLIC_MS_CLARITY,
  scripts: {
    optimize: process.env.NEXT_PUBLIC_ENABLE_OPTIMIZE == 'true',
    vwo: process.env.NEXT_PUBLIC_ENABLE_VWO == 'true',
    crazyegg: process.env.NEXT_PUBLIC_ENABLE_CRAZYEGG == 'true',
  },
  ...setEnv(),
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  resources: {
    base_url: process.env.NEXT_PUBLIC_RESOURCE_URL,
    token: process.env.NEXT_PUBLIC_RESOURCE_TOKEN,
    item_per_page: parseInt(
      process.env.NEXT_PUBLIC_RESOURCE_ITEM_PRE_PAGE || 10
    ),
    // image_domain: process.env.NEXT_PUBLIC_IMAGE_DOMAIN,
  },
  captchaSiteKey: getValue(process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY),
  vwo: {
    accountId: getValue(process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID),
    sdkKey: getValue(process.env.NEXT_PUBLIC_VWO_SDK_KEY),
    campaignKey: getValue(process.env.NEXT_PUBLIC_VWO_CAMPAIGN_ID),
  },
};
