 
const irePurchaseEvents = (cartData, orderId) => {
 
  let productItems = getProductItems(cartData);

  const isSubscription = orderIsSubscription(cartData);

  var type = "purchase";

  if (isSubscription) {
    type = "subscription";
  }


  ire('trackConversion', 44100, {
    orderId: orderId,
    customerId: "",
    text1: type,
    currencyCode: "USD",
    orderPromoCode: "",
    orderDiscount: null,
    items: productItems
  },
    {
      verifySiteDefinitionMatch: true
    }
  );

};


const getProductItems = (cartData) => {
  const productItems = [];

  var cartItems = cartData.items
  cartItems.forEach(function (item, index) {

    let data = {
      subTotal: item.price,
      category: "",
      sku: item.sku,
      quantity: item.qty,
      name: item.name
    };
    productItems.push(data);
  });
  return productItems;
};

const orderIsSubscription = (cartData) => {

  var flag = false;
  var cartItems = cartData.items
  cartItems.forEach(function (item, index) {

    if (item.is_subscription) {
      flag = true;
    }

  });

  return flag;

};

 
export { irePurchaseEvents };
 
