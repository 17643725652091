'use client';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import ComponentsOverrides from './overrides';

// v4
import {
  ThemeProvider as ThemeProviderV4,
  StylesProvider,
} from '@material-ui/core/styles';
import { createMuiTheme as createThemeV4 } from '@material-ui/core/styles';

import { createGenerateClassName } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: 'mui-jss',
});

const themeV4 = createThemeV4({});

const zIndex = {
  // mobileStepper: 1000,
  // fab: 1050,
  // speedDial: 1050,
  // appBar: 1100,
  // drawer: 1200,
  // modal: 100000,
  // snackbar: 1400,
  tooltip: 100001,
};

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      breakpoints,
      typography,
      zIndex,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ThemeProviderV4 theme={themeV4}>
          <MUIThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </MUIThemeProvider>
        </ThemeProviderV4>
      </StyledEngineProvider>
    </StylesProvider>
  );
}
