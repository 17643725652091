import styles from './LinkInline.module.scss';
import { Link } from '../../../components/ui';
import { Typography } from '@mui/material';

const LinkInline = ({ children, textProps = {}, ...props }) => {
  return (
    <div className={styles.LinkMainContainer}>
      <div>
        <Link {...props}>
          <Typography
            variant="p1"
            component={'span'}
            color="primary.main"
            {...textProps}
            noWrap
          >
            {children}
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default LinkInline;
