import apiCall from '../../../commons/helpers/apiCall';

// Connected
export async function fetchListAllOrders(orderDetails) {
  const result = await apiCall({
    endpoint: `orders?customerId=${orderDetails.customerId}&limit=${orderDetails.limit}&currPage=${orderDetails.currentPage}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

// Connected
export async function fetchOrderDetails(orderId) {
  const result = await apiCall({
    endpoint: `order/${orderId}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchOrderStatusDetails(orderIds) {
  const result = await apiCall({
    endpoint: 'orders/shipping-status',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { orderIds: orderIds },
      redirect: 'follow',
    },
  });
  return result;
}

export async function fetchOrderEventsDetails(trackNumber) {
  const result = await apiCall({
    endpoint: `orders/tracking-info/${trackNumber}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    },
  });
  return result;
}
