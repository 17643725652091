// ----------------------------------------------------------------------

export default function Drawer(theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('lg')]: {
            // zIndex: 10004,
          },
          [theme.breakpoints.down('md')]: {
            // zIndex: 10004,
          },
        },
      },
    },
  };
}
