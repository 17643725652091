import { useEffect, useState } from 'react';
import styles from './Backdrop.module.scss';
import { useAppSelector } from '../../../src/app/hooks';
import { getUI } from '../../../src/features/ui/uiState';
import { AtomSpinner, DotsCircular } from './spinner';
import NoScript from './NoScript';

export default function SimpleBackdrop({ manual }) {
  const [open, setOpen] = useState(false);
  const ui = useAppSelector(getUI);

  useEffect(() => {
    setOpen(ui?.awaitingApis > 0);
    toggleScrolling(ui?.awaitingApis > 0);
  }, [ui]);

  const toggleScrolling = (state) => {
    document.body.style.overflow = state ? 'hidden' : 'auto';
  };

  return (
    <>
      <noscript>
        <div className={styles.backdropMain}>
          <NoScript />{' '}
        </div>{' '}
      </noscript>
      {(manual || open) && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={styles.backdropMain}
          open={open}
        >
          <DotsCircular />
        </div>
      )}
    </>
  );
}
