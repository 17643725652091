import React from 'react';
import Head from 'next/head';
import { config } from '../../config';

const Helmet = () => {
  const { environment } = config;
  return (
    <Head>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="format-detection" content="telephone=no"></meta>
      {['prod', 'production'].includes(environment) ? (
        <meta name="robots" content="INDEX,FOLLOW"></meta>
      ) : (
        <meta name="robots" content="noindex,nofollow" />
      )}
      <link
        rel="apple-touch-icon"
        href="../images/apple-touch-icon-iphone-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="../images/apple-touch-icon-ipad-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="../images/apple-touch-icon-iphone-retina-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="../images/apple-touch-icon-ipad-retina-152x152.png"
      />
    </Head>
  );
};

export default Helmet;
