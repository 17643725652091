export const statesWithId = {
  0: {
    value: '',
    title: 'Select',
    alias: 'Select',
    name: '',
    disabled: true,
  },
  1: {
    value: '1',
    title: 'AL - Alabama',
    name: 'Alabama',
    alias: 'AL',
  },
  2: {
    value: '2',
    title: 'AK - Alaska',
    name: 'Alaska',
    alias: 'AK',
  },
  3: {
    value: '3',
    title: 'AS - American Samoa',
    name: 'American Samoa',
    alias: 'AS',
  },
  4: {
    value: '4',
    title: 'AZ - Arizona',
    name: 'Arizona',
    alias: 'AZ',
  },
  5: {
    value: '5',
    title: 'AR - Arkansas',
    name: 'Arkansas',
    alias: 'AR',
  },
  6: {
    value: '6',
    title: 'AF - Armed Forces Africa',
    name: 'Armed Forces Africa',
    alias: 'AF',
  },
  7: {
    value: '7',
    title: 'AA - Armed Forces Americas',
    name: 'Armed Forces Americas',
    alias: 'AA',
  },
  8: {
    value: '8',
    title: 'AC - Armed Forces Canada',
    name: 'Armed Forces Canada',
    alias: 'AC',
  },
  9: {
    value: '9',
    title: 'AE - Armed Forces Europe',
    name: 'Armed Forces Europe',
    alias: 'AE',
  },
  10: {
    value: '10',
    title: 'AM - Armed Forces Mvaluedle East',
    name: 'Armed Forces Mvaluedle East',
    alias: 'AM',
  },
  11: {
    value: '11',
    title: 'AP - Armed Forces Pacific',
    name: 'Armed Forces Pacific',
    alias: 'AP',
  },
  12: {
    value: '12',
    title: 'CA - California',
    name: 'California',
    alias: 'CA',
  },
  13: {
    value: '13',
    title: 'CO - Colorado',
    name: 'Colorado',
    alias: 'CO',
  },
  14: {
    value: '14',
    title: 'CT- Connecticut',
    name: 'Connecticut',
    alias: 'CT',
  },
  15: {
    value: '15',
    title: 'DE - Delaware',
    name: 'Delaware',
    alias: 'DE',
  },
  16: {
    value: '16',
    title: 'DC - District of Columbia',
    name: 'District of Columbia',
    alias: 'DC',
  },
  17: {
    value: '17',
    title: 'FM - Federated States Of Micronesia',
    name: 'Federated States Of Micronesia',
    alias: 'FM',
  },
  18: {
    value: '18',
    title: 'FL - Florida',
    name: 'Florida',
    alias: 'FL',
  },
  19: {
    value: '19',
    title: 'GA - Georgia',
    name: 'Georgia',
    alias: 'GA',
  },
  20: {
    value: '20',
    title: 'GU - Guam',
    name: 'Guam',
    alias: 'GU',
  },
  21: {
    value: '21',
    title: 'HI - Hawaii',
    name: 'Hawaii',
    alias: 'HI',
  },
  22: {
    value: '22',
    title: 'ID - Idaho',
    name: 'Idaho',
    alias: 'ID',
  },
  23: {
    value: '23',
    title: 'IL - Illinois',
    name: 'Illinois',
    alias: 'IL',
  },
  24: {
    value: '24',
    title: 'IN - Indiana',
    name: 'Indiana',
    alias: 'IN',
  },
  25: {
    value: '25',
    title: 'IA - Iowa',
    name: 'Iowa',
    alias: 'IA',
  },
  26: {
    value: '26',
    title: 'KS - Kansas',
    name: 'Kansas',
    alias: 'KS',
  },
  27: {
    value: '27',
    title: 'KY - Kentucky',
    name: 'Kentucky',
    alias: 'KY',
  },
  28: {
    value: '28',
    title: 'LA - Louisiana',
    alias: 'LA',
  },
  29: {
    value: '29',
    title: 'ME - Maine',
    name: 'Maine',
    alias: 'ME',
  },
  30: {
    value: '30',
    title: 'MH - Marshall Islands',
    name: 'Marshall Islands',
    alias: 'MH',
  },
  31: {
    value: '31',
    title: 'MD - Maryland',
    name: 'Maryland',
    alias: 'MD',
  },
  32: {
    value: '32',
    title: 'MA - Massachusetts',
    name: 'Massachusetts',
    alias: 'MA',
  },
  33: {
    value: '33',
    title: 'MI - Michigan',
    name: 'Michigan',
    alias: 'MI',
  },
  34: {
    value: '34',
    title: 'MN - Minnesota',
    name: 'Minnesota',
    alias: 'MN',
  },
  35: {
    value: '35',
    title: 'MS - Mississippi',
    name: 'Mississippi',
    alias: 'MS',
  },
  36: {
    value: '36',
    title: 'MO - Missouri',
    name: 'Missouri',
    alias: 'MO',
  },
  37: {
    value: '37',
    title: 'MT - Montana',
    name: 'Montana',
    alias: 'MT',
  },
  38: {
    value: '38',
    title: 'NE - Nebraska',
    name: 'Nebraska',
    alias: 'NE',
  },
  39: {
    value: '39',
    title: 'NV - Nevada',
    name: 'Nevada',
    alias: 'NV',
  },
  40: {
    value: '40',
    title: 'NH - New Hampshire',
    name: 'New Hampshire',
    alias: 'NH',
  },
  41: {
    value: '41',
    title: 'NJ - New Jersey',
    name: 'New Jersey',
    alias: 'NJ',
  },
  42: {
    value: '42',
    title: 'NM - New Mexico',
    name: 'New Mexico',
    alias: 'NM',
  },
  43: {
    value: '43',
    title: 'NY - New York',
    name: 'New York',
    alias: 'NY',
  },
  44: {
    value: '44',
    title: 'NC - North Carolina',
    name: 'North Carolina',
    alias: 'NC',
  },
  45: {
    value: '45',
    title: 'ND - North Dakota',
    name: 'North Dakota',
    alias: 'ND',
  },
  46: {
    value: '46',
    title: 'MP - Northern Mariana Islands',
    name: 'Northern Mariana Islands',
    alias: 'MP',
  },
  47: {
    value: '47',
    title: 'OH - Ohio',
    name: 'Ohio',
    alias: 'OH',
  },
  48: {
    value: '48',
    title: 'OK - Oklahoma',
    name: 'Oklahoma',
    alias: 'OK',
  },
  49: {
    value: '49',
    title: 'OR - Oregon',
    name: 'Oregon',
    alias: 'OR',
  },
  50: {
    value: '50',
    title: 'PW - Palau',
    name: 'Palau',
    alias: 'PW',
  },
  51: {
    value: '51',
    title: 'PA - Pennsylvania',
    name: 'Pennsylvania',
    alias: 'PA',
  },
  52: {
    value: '52',
    title: 'PR - Puerto Rico',
    name: 'Puerto Rico',
    alias: 'PR',
  },
  53: {
    value: '53',
    title: 'RI - Rhode Island',
    name: 'Rhode Island',
    alias: 'RI',
  },
  54: {
    value: '54',
    title: 'SC - South Carolina',
    name: 'South Carolina',
    alias: 'SC',
  },
  55: {
    value: '55',
    title: 'SD - South Dakota',
    name: 'South Dakota',
    alias: 'SD',
  },
  56: {
    value: '56',
    title: 'TN - Tennessee',
    name: 'Tennessee',
    alias: 'TN',
  },
  57: {
    value: '57',
    title: 'TX - Texas',
    name: 'Texas',
    alias: 'TX',
  },
  58: {
    value: '58',
    title: 'UT - Utah',
    name: 'Utah',
    alias: 'UT',
  },
  59: {
    value: '59',
    title: 'VT - Vermont',
    name: 'Vermont',
    alias: 'VT',
  },
  60: {
    value: '60',
    title: 'VI - Virgin Islands',
    name: 'Virgin Islands',
    alias: 'VI',
  },
  61: {
    value: '61',
    title: 'VA - Virginia',
    name: 'Virginia',
    alias: 'VA',
  },
  62: {
    value: '62',
    title: 'WA - Washington',
    name: 'Washington',
    alias: 'WA',
  },
  63: {
    value: '63',
    title: 'WV - West Virginia',
    name: 'West Virginia',
    alias: 'WV',
  },
  64: {
    value: '64',
    title: 'WI - Wisconsin',
    name: 'Wisconsin',
    alias: 'WI',
  },
  65: {
    value: '65',
    title: 'WY - Wyoming',
    name: 'Wyoming',
    alias: 'WY',
  },
};

export const states = [
  'AL - Alabama',
  'AK - Alaska',
  'AS - American Samoa',
  'AZ - Arizona',
  'AR - Arkansas',
  'AF - Armed Forces Africa',
  'AA - Armed Forces Americas',
  'AE - Armed Forces Europe',
  'AM - Armed Forces Mvaluedle East',
  'AP - Armed Forces Pacific',
  'CA - California',
  'CO - Colorado',
  'CT- Connecticut',
  'DE - Delaware',
  'DC - District of Columbia',
  'FM - Federated States Of Micronesia',
  'FL - Florida',
  'GA - Georgia',
  'GU - Guam',
  'HI - Hawaii',
  'ID - Idaho',
  'IL - Illinois',
  'IN - Indiana',
  'IA - Iowa',
  'KS - Kansas',
  'KY - Kentucky',
  'LA - Louisiana',
  'ME - Maine',
  'MH - Marshall Islands',
  'MD - Maryland',
  'MA - Massachusetts',
  'MI - Michigan',
  'MN - Minnesota',
  'MS - Mississippi',
  'MO - Missouri',
  'MT - Montana',
  'NE - Nebraska',
  'NV - Nevada',
  'NH - New Hampshire',
  'NJ - New Jersey',
  'NM - New Mexico',
  'NY - New York',
  'NC - North Carolina',
  'ND - North Dakota',
  'MP - Northern Mariana Islands',
  'OH - Ohio',
  'OK - Oklahoma',
  'OR - Oregon',
  'PW - Palau',
  'PA - Pennsylvania',
  'PR - Puerto Rico',
  'RI - Rhode Island',
  'SC - South Carolina',
  'SD - South Dakota',
  'TN - Tennessee',
  'TX - Texas',
  'UT - Utah',
  'VT - Vermont',
  'VI - Virgin Islands',
  'VA - Virginia',
  'WA - Washington',
  'WV - West Virginia',
  'WI - Wisconsin',
  'WY - Wyoming',
];

export const cancelOptions = [
  'I did not understand the membership program',
  'I stopped taking Relief Factor',
  'I have not started the Quickstart',
  'Taking product is inconvenient',
  'Medical reasons',
  'I have too much Relief Factor',
  'I prefer to order as needed',
];

export const frequencyOptions = [
  'Every 2 weeks',
  'Every 3 weeks',
  'Every month',
  'Every other month',
  'Once every 3 months',
  'Other',
];

export const months = [
  '1 - January',
  '2 - February',
  '3 - March',
  '4 - April',
  '5 - May',
  '6 - June',
  '7 - July',
  '8 - August',
  '9 - September',
  '10 - October',
  '11 - November',
  '12 - December',
];

export const monthsWithValues = {
  0: {
    value: '0',
    title: 'Month',
    alias: 'Select',
  },
  1: {
    value: '1',
    title: '1 - January',
    alias: 'January',
  },
  2: {
    value: '2',
    title: '2 - February',
    alias: 'February',
  },
  3: {
    value: '3',
    title: '3 - March',
    alias: 'March',
  },
  4: {
    value: '4',
    title: '4 - April',
    alias: 'April',
  },
  5: {
    value: '5',
    title: '5 - May',
    alias: 'May',
  },
  6: {
    value: '6',
    title: '6 - June',
    alias: 'June',
  },
  7: {
    value: '7',
    title: '7 - July',
    alias: 'July',
  },
  8: {
    value: '8',
    title: '8 - August',
    alias: 'August',
  },
  9: {
    value: '9',
    title: '9 - September',
    alias: 'September',
  },
  10: {
    value: '10',
    title: '10 - October',
    alias: 'October',
  },
  11: {
    value: '11',
    title: '11 - November',
    alias: 'November',
  },
  12: {
    value: '12',
    title: '12 - December',
    alias: 'December',
  },
};
export const monthsWithValuesNoSelect = {
  1: {
    value: '1',
    title: '1 - January',
    alias: 'January',
  },
  2: {
    value: '2',
    title: '2 - February',
    alias: 'February',
  },
  3: {
    value: '3',
    title: '3 - March',
    alias: 'March',
  },
  4: {
    value: '4',
    title: '4 - April',
    alias: 'April',
  },
  5: {
    value: '5',
    title: '5 - May',
    alias: 'May',
  },
  6: {
    value: '6',
    title: '6 - June',
    alias: 'June',
  },
  7: {
    value: '7',
    title: '7 - July',
    alias: 'July',
  },
  8: {
    value: '8',
    title: '8 - August',
    alias: 'August',
  },
  9: {
    value: '9',
    title: '9 - September',
    alias: 'September',
  },
  10: {
    value: '10',
    title: '10 - October',
    alias: 'October',
  },
  11: {
    value: '11',
    title: '11 - November',
    alias: 'November',
  },
  12: {
    value: '12',
    title: '12 - December',
    alias: 'December',
  },
};
const currentYear = new Date().getFullYear();

let yearsObject = Array.from(Array(11).keys()).map((n) => {
  let val = (n + currentYear).toString();
  return {
    value: val,
    title: val,
    alias: val,
  };
});

const defaultYear = {
  value: '0',
  title: 'Year',
  alias: 'Select',
};

export const yearsListCurrent = [defaultYear, ...yearsObject];

export const years = Array.from(Array(11).keys()).map((n) => n + currentYear);

export const yearsWithValues = {
  0: {
    value: '0',
    title: 'Year',
    alias: 'Select',
  },
  2021: {
    value: '2021',
    title: '2021',
    alias: '2021',
  },
  2022: {
    value: '2022',
    title: '2022',
    alias: '2022',
  },
  2023: {
    value: '2023',
    title: '2023',
    alias: '2023',
  },
  2024: {
    value: '2024',
    title: '2024',
    alias: '2024',
  },
  2025: {
    value: '2025',
    title: '2025',
    alias: '2025',
  },
  2026: {
    value: '2026',
    title: '2026',
    alias: '2026',
  },
  2027: {
    value: '2027',
    title: '2027',
    alias: '2027',
  },
  2028: {
    value: '2028',
    title: '2028',
    alias: '2028',
  },
  2029: {
    value: '2029',
    title: '2029',
    alias: '2029',
  },
  2030: {
    value: '2030',
    title: '2030',
    alias: '2030',
  },
  2031: {
    value: '2031',
    title: '2031',
    alias: '2031',
  },
};

export const yearsWithValuesNoSelect = {
  2024: {
    value: '2024',
    title: '2024',
    alias: '2024',
  },
  2025: {
    value: '2025',
    title: '2025',
    alias: '2025',
  },
  2026: {
    value: '2026',
    title: '2026',
    alias: '2026',
  },
  2027: {
    value: '2027',
    title: '2027',
    alias: '2027',
  },
  2028: {
    value: '2028',
    title: '2028',
    alias: '2028',
  },
  2029: {
    value: '2029',
    title: '2029',
    alias: '2029',
  },
  2030: {
    value: '2030',
    title: '2030',
    alias: '2030',
  },
  2031: {
    value: '2031',
    title: '2031',
    alias: '2031',
  },
  2032: {
    value: '2032',
    title: '2032',
    alias: '2032',
  },
  2033: {
    value: '2033',
    title: '2033',
    alias: '2033',
  },
  2034: {
    value: '2034',
    title: '2034',
    alias: '2034',
  },
  2035: {
    value: '2035',
    title: '2035',
    alias: '2035',
  },
  2036: {
    value: '2036',
    title: '2036',
    alias: '2036',
  },
  2037: {
    value: '2037',
    title: '2037',
    alias: '2037',
  },
  2038: {
    value: '2038',
    title: '2038',
    alias: '2038',
  },
  2039: {
    value: '2039',
    title: '2039',
    alias: '2039',
  },
  2040: {
    value: '2040',
    title: '2040',
    alias: '2040',
  },
  2041: {
    value: '2041',
    title: '2041',
    alias: '2041',
  },
  2042: {
    value: '2042',
    title: '2042',
    alias: '2042',
  },
  2043: {
    value: '2043',
    title: '2043',
    alias: '2043',
  },
};
export const rfSurveyOptions = [
  'George Noory',
  'Clay Travis & Buck Sexton',
  'Dan Bongino',
  'Dana Loesch',
  'Dennis Prager',
  "Dinesh D'Souza",
  'Fox News',
  'Glenn Beck',
  'Hugh Hewitt',
  'Megyn Kelly',
  'Sean Hannity',
  'Sebastian Gorka',
  'Newsmax',
  'Other',
];

export const defaultSurveyOptions = [
  'Direct Mail',
  'TV',
  'Charlie Kirk',
  'Dennis Prager',
  'The Officer Tatum',
  'Other',
];
export const sleepSurveyOptions = [
  'Larry Elder',
  'Google',
  'George Noory',
  'Facebook',
  'Twitter',
  'Referred by friend',
  'I’m already a Relief Factor customer',
];
