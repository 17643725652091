import styles from './CheckboxComponent.module.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core';

const CHECKBOX_THEME = createTheme({
  palette: {
    secondary: {
      main: '#77b442',
    },
  },
});

const StyledFormControlLabel = withStyles((theme) => ({
  label: {
    lineHeight: (props) => props.styleobj.lineHeight,
    fontSize: (props) => props.styleobj.fontSize,
  },
}))(FormControlLabel);

const CheckboxComponent = ({
  size = 'size',
  name = '',
  onChange = () => {},
  onBlur = () => {},
  children,
  ...rest
}) => {
  return (
    <MuiThemeProvider theme={CHECKBOX_THEME}>
      <StyledFormControlLabel
        styleobj={{
          lineHeight: '16px',
          fontSize: '13px',
        }}
        className={styles.checkboxLabel}
        control={
          <Checkbox
            {...rest}
            size={size}
            name={name}
            className={styles.checkbox}
            onChange={onChange}
            style={{
              color: '#004152',
              cursor: 'pointer',
            }}
            onBlur={onBlur}
          />
        }
        label={<span className={styles.label}>{children}</span>}
      />
    </MuiThemeProvider>
  );
};
export default CheckboxComponent;
