import apiCall from '../../../commons/helpers/apiCall';

export async function newsletter(data) {
  const result = await apiCall({
    endpoint: 'store-email',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      redirect: 'follow',
    },
  });
  return result;
}

export async function contactUs(data) {
  const result = await apiCall({
    endpoint: 'business-inquiry',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
      redirect: 'follow',
    },
  });
  return result;
}
