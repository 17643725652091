import { LOCAL_STORAGE } from '../Constants';
import { isBrowserFeature } from './isBrowserFeature';

var local_token = '';
export const setToken = (token) => {
  if (isBrowserFeature(LOCAL_STORAGE)) {
    localStorage.setItem('user_token', token);
  }

  local_token = token;
};
export const getToken = () => {
  if (!local_token) {
    if (isBrowserFeature(LOCAL_STORAGE))
      local_token = localStorage.getItem('user_token');
  }
  return local_token;
};

export const deleteToken = () => {
  if (isBrowserFeature(LOCAL_STORAGE)) {
    localStorage.removeItem('user_token', '');
  }
  local_token = '';
};
