// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover .MuiCardActionArea-focusHighlight': {
            opacity: 0,
          },
        },
      },
    },
  };
}
