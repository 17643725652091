import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAdBlockerStatus, fetchCountryStatesList } from './appApi';

const GET_ADBLOCKER_STATUS = 'GET_ADBLOCKER_STATUS';
const initialState = {
  isAdBlockerEnabled: undefined,
};

export const getAdBlockerStatus = createAsyncThunk(
  GET_ADBLOCKER_STATUS,
  async () => {
    return await fetchAdBlockerStatus();
  }
);

export const getStatesListByCountry = createAsyncThunk(
  'getStatesListByCountry/app',
  async (country) => {
    return await fetchCountryStatesList(country);
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAdBlockerStatus.pending, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(getAdBlockerStatus.fulfilled, (state, action) => {
        window.isAdBlockerEnabled = action.payload;
        return {
          ...state,
          isAdBlockerEnabled: action.payload,
        };
      })
      .addCase(getAdBlockerStatus.rejected, (state, action) => {
        window.isAdBlockerEnabled = action.payload;
        return {
          ...state,
          isAdBlockerEnabled: action.payload,
        };
      })
      .addCase(getStatesListByCountry.fulfilled, (state, action) => {
        let countryCode = action.payload[0]?.iso2;
        state[countryCode] = action.payload[0]?.states;
      });
  },
});

export const { updateAdBlocker } = appSlice.actions;

export const getIsAdBlocked = (state) => state.app.isAdBlockerEnabled;

export const getAppState = (state) => state.app;

export default appSlice.reducer;
