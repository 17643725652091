import store, { persistor } from '../app/store';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useIsomorphicLayoutEffect } from '../../commons/hooks';
import ThemeProvider from '../styles/v5';
// 3rd Party
import Layout from '../../components/layout/Layout';
import { config } from '../../config';
// CSS
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import Helmet from './Helmet';
import SentrySetup from '../../components/Sentry';
import ThirdPartyScripts from '../../commons/components/scripts';
import { injectRiskified, isAppleDevice } from '../../commons/utils';
import { Head } from './../../components/global';
import { useEffect } from 'react';
import { FBPageView } from '../../commons/helpers/Analytics/fbPixel';
import ErrorBoundary from '../../components/ErrorBoundry';
import { vwoHelper } from '../../commons/helpers/Analytics/vwo/vwoHelper';

function MyApp({ Component, pageProps }) {
  useIsomorphicLayoutEffect(async () => {
    injectRiskified();
    let purgeConfig = JSON.parse(localStorage?.getItem('__rdx_prst_prg'));
    if (
      config.redux.persist.purge &&
      (!purgeConfig?.version ||
        purgeConfig?.version !== config.redux.persist.version)
    ) {
      persistor.pause();
      persistor
        .flush()
        .then(() => persistor.purge().then(() => persistor.persist()));
      localStorage.setItem(
        '__rdx_prst_prg',
        JSON.stringify({
          version: config.redux.persist.version,
        })
      );
    }
  }, []);

  useEffect(() => {
    FBPageView();
    try {
      let bodySelector = document.querySelector('body');
      if (isAppleDevice()) {
        bodySelector.classList.remove('custom-scrollbar');
      } else bodySelector.classList.add('custom-scrollbar');
    } catch (err) {
      console.log(err);
    }
  }, []);

  if (pageProps?.userId) {
    let accountId = config.vwo.accountId;
    let sdkKey = config.vwo.sdkKey;
    const userData = {
      campaignKey: config.vwo.campaignKey,
      userId: pageProps.userId,
    };
    let currentSettingsFile = {};
    vwoHelper
      .getSettingsFile(accountId, sdkKey)
      .then((latestSettingsFile) => {
        currentSettingsFile = latestSettingsFile;
        vwoHelper.currentSettingsFile = latestSettingsFile;
        vwoHelper.initVWOSdk(latestSettingsFile, userData, pageProps);
      })
      .catch((err) => {
        console.error(
          'Something went wrong in fetching account settings.',
          err
        );
      });
  }

  return config.sentry.enabled === true ? (
    <>
      <SentrySetup>
        <Provider store={store}>
          <ThemeProvider>
            <Head
              title={pageProps?.extraMeta?.title}
              description={pageProps?.extraMeta?.description}
              schemaData={pageProps?.extraMeta?.schemaData}
            />
            <Helmet />
            <Layout>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </Layout>
            <PersistGate loading={null} persistor={persistor} />
          </ThemeProvider>
        </Provider>
        <ThirdPartyScripts />
      </SentrySetup>
    </>
  ) : (
    <>
      <Provider store={store}>
        <ThemeProvider>
          <Head
            title={pageProps?.extraMeta?.title}
            description={pageProps?.extraMeta?.description}
            schemaData={pageProps?.extraMeta?.schemaData}
          />
          <Helmet />
          <Layout>
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
          <PersistGate loading={null} persistor={persistor} />
        </ThemeProvider>
      </Provider>
      <ThirdPartyScripts />
    </>
  );
}

export default MyApp;
