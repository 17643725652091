import Link from 'next/link';
import EmptyCartSvg from '../../../images/EmptyCart.svg';
import styles from './cartempty.module.scss';
import { useAppDispatch } from '../../../app/hooks';
import { showCart } from '../../../features/ui/uiState';
export default function CartEmpty() {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <EmptyCartSvg height={220} width={220} />
      <div className={styles.innerContainer}>
        <p className={styles.paraStyle}>
          You have no items in your shopping cart.
        </p>
        <p className={styles.paraStyle}>
          Click{' '}
          <Link href="/#productShowCase">
            <a
              className="phone-link"
              onClick={() => {
                dispatch(showCart(false));
              }}
            >
              here
            </a>
          </Link>{' '}
          to continue shopping.{' '}
        </p>
      </div>
    </div>
  );
}
