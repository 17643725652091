import React from 'react';
import styles from './BrokenCart.module.scss';
import { Typography } from '@mui/material';
import BrokenCartIcon from '../../../../src/images/brokenCart.svg';
import { ContainedButton } from '../../../../commons/hoc';
import { useRouter } from 'next/router';
import { useAppDispatch } from '../../../../src/app/hooks';
import { disableBrokenCart } from '../../../../src/features/ui/uiState';
import { resetCart } from '../../../../src/features/cart/cartState';

const BrokenCart = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const onRefreshHandle = () => {
    router.push('/');
    dispatch(disableBrokenCart(false));
    dispatch(resetCart());
  };
  return (
    <div className={styles.container}>
      <div>
        <BrokenCartIcon />
      </div>
      <div className={styles.cartText}>
        <Typography variant="h4" color="primary">
          Your cart is currently empty. <br /> Please refresh.
        </Typography>
      </div>
      <div>
        <ContainedButton onClick={onRefreshHandle}>Refresh</ContainedButton>
      </div>
    </div>
  );
};

export default BrokenCart;
