import { useRouter } from 'next/dist/client/router';
import React from 'react';
import styles from './NoScript.module.scss';

const NoScript = () => {
  const router = useRouter();
  return (
    <div className={styles.loading}>
      <div className={styles.content}>
        <h2>Javascript Disabled</h2>
      </div>
      <div className={styles.content}>
        Please enable Javascript on your browser to continue using this site.
      </div>
      <div className={styles.content}>
        Directions on enabling javascript for all major browsers:{' '}
        <a
          href="https://www.enable-javascript.com"
          target="_blank"
          className={styles.hyperlink}
        >
          https://www.enable-javascript.com
        </a>
      </div>
      <div className={styles.footer}>
        <a href={router.asPath} className={styles.reloadButton}>
          Reload
        </a>
      </div>
    </div>
  );
};

export default NoScript;
