import { v4 as uuidv4 } from 'uuid';
import { isBrowserFeature } from '.';
import { BEACON_TOKEN_KEY, SESSION_STORAGE } from '../Constants';

const checkIfBeaconTokenExists = () => {
  if (isBrowserFeature(SESSION_STORAGE)) {
    return global.sessionStorage?.getItem(BEACON_TOKEN_KEY) ? true : false;
  } else {
    return global.beaconToken ? true : false;
  }
};

const generateBeaconToken = (needValue = false) => {
  if (!checkIfBeaconTokenExists()) {
    let value = global.beaconToken || uuidv4();
    if (isBrowserFeature(SESSION_STORAGE)) {
      global.sessionStorage?.setItem(BEACON_TOKEN_KEY, value);
    } else {
      global.beaconToken = value;
    }
    if (needValue) return value;
  }
};

const getBeaconToken = () => {
  if (isBrowserFeature(SESSION_STORAGE)) {
    return (
      global.sessionStorage?.getItem(BEACON_TOKEN_KEY) ||
      generateBeaconToken(true)
    );
  } else {
    return global.beaconToken || generateBeaconToken(true, false);
  }
};

export { getBeaconToken, checkIfBeaconTokenExists, generateBeaconToken };
