const cartExtract = (cart, isSubMapRequired = true) => {
  const cartData = {};
  let prodList = [];
  let subMap = {};
  Object.entries(cart).map(([key, item]) => {
    prodList.push(item.key);
    subMap[item.key] = item.isSubscription;
  });
  cartData['productList'] = prodList;
  if (isSubMapRequired) cartData['subMap'] = subMap;
  return cartData;
};

export { cartExtract };
