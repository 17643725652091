import { useEffect, useState } from 'react';
import styles from './ThreeLineAddress.module.scss';
import { toTitleCase } from '../../../commons/utils';
import {
  threeLineAddressObj,
  threeLineAddressObjNew,
  threeLineAddressObjWithType,
} from '../../../commons/utils/threeLineAddressSplitter';
import { useAppSelector } from '../../../src/app/hooks';
import { getAppState } from '../../../src/features/app/appState';

const ThreeLineAddress = ({
  address,
  addressType,
  processText = true,
  rebrand = false,
}) => {
  const appState = useAppSelector(getAppState);
  const [formattedAddress, setFormattedAddress] = useState(null);

  useEffect(() => {
    let temp = null;
    if (rebrand) {
      temp = threeLineAddressObjNew(address);
    } else if (processText) {
      if (!addressType) temp = threeLineAddressObj(address);
      else temp = threeLineAddressObjWithType(address, addressType);
    } else {
      temp = address;
    }
    setFormattedAddress(temp);
    if (address) {
      let temp = { ...address };
      const statesList = appState[address.countryId];
      const state = statesList?.find((item) => item.state_id == address.state);
      temp['state'] = state?.name;
      setFormattedAddress(temp);
    }
  }, [address]);

  return (
    <div className={styles.threeLineAddressContainer}>
      <p>
        <span>{toTitleCase(formattedAddress?.firstName || '')}</span>
        {formattedAddress?.firstName && formattedAddress.lastName ? ' ' : ''}
        <span>{toTitleCase(formattedAddress?.lastName || '')}</span>
      </p>
      <p>
        <span>{toTitleCase(formattedAddress?.address1 || '')}</span>
        {formattedAddress?.address1 && formattedAddress.address2 ? ', ' : ''}
        <span>{toTitleCase(formattedAddress?.address2 || '')}</span>
      </p>
      <p>
        <span>{toTitleCase(formattedAddress?.city || '')}</span>
        {formattedAddress?.city &&
        (formattedAddress?.state || formattedAddress?.zip)
          ? ', '
          : ''}
        <span>{formattedAddress?.state || ''}</span>
        {formattedAddress?.state && formattedAddress?.zip ? ' ' : ''}
        <span>{formattedAddress?.zip?.toUpperCase() || ''}</span>
      </p>
    </div>
  );
};

export default ThreeLineAddress;
