import { useEffect, useState } from 'react';
import { RatingComponent } from '../components/ui';
import {
  bottomLine,
  getBottomLineInfo,
} from '../../src/features/yopto/yoptoState';
import { useAppDispatch, useAppSelector } from '../../src/app/hooks';
import { config, productsKeyConstants } from '../../config';
import { getProducts } from '../utils/productsHelper';
import { nearestTen } from '../utils/nearestTen';

const RatingComponentWithData = ({
  productId = productsKeyConstants.RF_QUICKSTART,
  precision = 0.1,
  preText = 'Over ',
  postText = ' Reviews',
  readOnly = true,
  size = 'small',
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const [mounted, setMounted] = useState(false);
  const products = getProducts();
  const bottomLineData = useAppSelector(bottomLine);
  const [averageScore, setAverageScore] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (productId) {
      let id = products[productId].quoteParams?.productId;
      if (id) dispatch(getBottomLineInfo(id));
    }
  }, [productId]);

  useEffect(() => {
    setAverageScore(bottomLineData?.average_score || 0);
    setTotalReviews(bottomLineData?.total_reviews || 0);
  }, [bottomLineData]);

  return (
    <>
      {mounted ? (
        <RatingComponent
          value={averageScore}
          precision={precision}
          text={`${preText}${nearestTen.floor(totalReviews)}${postText}`}
          readOnly
          size={size}
          {...rest}
        />
      ) : (
        <></>
      )}
      <div style={{ display: 'none' }}>
        <div
          id="yotpo-review-div"
          className={`yotpo yotpo-main-widget`}
          data-product-id={
            products[productsKeyConstants.RF_QUICKSTART].quoteParams?.productId
          }
          data-price="19.95"
          data-currency="USD"
          data-name="3-Week QuickStart"
          data-url={`${config.SiteURL}products/relief-factor`}
          data-image-url="https://m2beta-frontend.relieffactor.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Frf_quickstart_large.133bd35a.png&w=1920&q=75"
          data-autoload="false"
          data-cache-duration="3600"
        ></div>
      </div>
    </>
  );
};
export default RatingComponentWithData;
