import React from 'react';
import styles from './InlineLoader.module.scss';

export default function InlineLoder() {
  return (
    <div className={styles.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
