/**
 * @author Alfred Joseph
 * @since 07/06/2023
 * @desc Takes MySQL Date and converts it to JS Date Object. Handles all invalid date scenarios.
 * @param {String} dbTime
 * @param {Date} defaultDate
 * @returns {Date}
 */

export default (dbTime, defaultDate = false) => {
  try {
    if (dbTime) {
      let date = new Date(dbTime.replace(/[-]/g, '/'));
      if (!isNaN(date.getTime())) return date;
    }
  } catch (err) {}
  if (defaultDate) return defaultDate;
  return new Date();
};
