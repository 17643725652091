export const SUCCESS_MESSAGES = {
  BILLING_NEW: 'New Billing Address added successfully.',
  BILLING_UPDATE: 'Billing address updated successfully.',
  SHIPPING_NEW: 'New Shipping Address added successfully.',
  SHIPPING_UPDATE: 'Shipping address updated successfully.',
  PAYMENT_NEW: 'New Payment Method added successfully.',
  PAYMENT_UPDATE:
    'Payment information updated successfully! Your order will process on your next order date.',
  INTERVAL: 'Recurring Interval updated successfully.',
  NEXT_ORDER_DATE: 'Next order date updated successfully.',
  NEXT_ORDER_DATE_AND_INTERVAL: 'Changes to subscription saved successfully.',
  ORDER_QUANTITY: 'Order quantity updated successfully.',
  // FREE_GIFT_QUANTITY: 'Free gift quantity updated successfully.',
  FREE_GIFT_QUANTITY: 'Order quantity updated successfully.',
  ACCOUNT_INFORMATION: 'Account information saved successfully.',
  CURRENTPASSWORD_MISMATCH:
    "The Current password doesn't match this account. Verify the current password and try again.",
  PASSWORD_MISMATCH:
    "Password doesn't match this account. Please verify the password and try again.",
  SUBCRIPTION_CANCEL: 'Your subscription has been cancelled successfully.',
  ADD_CART: 'Added to cart.',
  PASSWORD_CHANGE: 'Your password has been updated.',
  STATUS_PAUSE: 'Subscription paused.',
  STATUS_PAUSE_UNTIL: 'All orders are paused until selected date successfully.',
  STATUS_RESUME: 'Your subscription has resumed. Welcome back!',
  STATUS_CANCEL:
    "Your subscription has been cancelled. We're sorry to see you go.",
  CONTACT_SUBMIT:
    'Thank you for contacting us. We received your Inquiry and we will respond back in the next 1 - 2 days.',
  DEFAULT: 'Success!',
};

const ERROR_MESSAGES = {
  BILLING_NEW: 'Failed!',
  BILLING_UPDATE: 'Failed!',
  SHIPPING_NEW: 'Failed!',
  SHIPPING_UPDATE: 'Failed!',
  PAYMENT_NEW: 'Failed!',
  PAYMENT_UPDATE: 'Failed!',
  INTERVAL: 'Failed!',
  NEXT_ORDER_DATE: 'Failed!',
  ORDER_QUANTITY: 'Failed!',
  FREE_GIFT_QUANTITY: 'Failed!',
  DEFAULT: 'Error!',
  NEXT_ORDER_DATE_AND_INTERVAL: 'Failed!',
};
const SUBSCRIPTION_STATUS = {
  cancel: 'STATUS_CANCEL',
  pause: 'STATUS_PAUSE',
  pause_until: 'STATUS_PAUSE_UNTIL',
  resume: 'STATUS_RESUME',
};

const lookupFeedbackMsg = ({ prefix = 'DEFAULT', key = '', success }) => {
  if (success) {
    return SUCCESS_MESSAGES[`${prefix}${key}`.toUpperCase()];
  } else {
    return ERROR_MESSAGES[`${prefix}${key}`.toUpperCase()];
  }
};

export const handleUpdateSubFeedback = (payload, success) => {
  if (payload.billingAction) {
    return lookupFeedbackMsg({
      prefix: 'BILLING_',
      key: payload.billingAction,
      success,
    });
  }
  if (payload.shippingAction) {
    return lookupFeedbackMsg({
      prefix: 'SHIPPING_',
      key: payload.shippingAction,
      success,
    });
  }
  if (payload.paymentAction) {
    return lookupFeedbackMsg({
      prefix: 'PAYMENT_',
      key: payload.paymentAction,
      success,
    });
  }
  if (payload.intervalAction) {
    return lookupFeedbackMsg({ prefix: 'INTERVAL', success });
  }
  if (payload.dateAction) {
    return lookupFeedbackMsg({ prefix: 'NEXT_ORDER_DATE', success });
  }
  if (payload.qtyAction) {
    return payload.isFree
      ? lookupFeedbackMsg({ prefix: 'FREE_GIFT_QUANTITY', success })
      : lookupFeedbackMsg({ prefix: 'ORDER_QUANTITY', success });
  }
  if (payload.dateAndIntervalAction) {
    return lookupFeedbackMsg({
      prefix: 'NEXT_ORDER_DATE_AND_INTERVAL',
      success,
    });
  }
  if (payload.statusAction && payload.pauseUntil) {
    return lookupFeedbackMsg({
      success,
      prefix: SUBSCRIPTION_STATUS[payload.statusAction] + '_until',
    });
  }
  if (payload.statusAction) {
    return lookupFeedbackMsg({
      success,
      prefix: SUBSCRIPTION_STATUS[payload.statusAction],
    });
  }
};
