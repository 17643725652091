import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ContainedButton } from '../../../commons/hoc';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../src/app/hooks';
import {
  getOrderId,
  removeOldOrder,
  resetQuote,
  getEmailWarningValue,
} from '../../../src/features/cart/cartState';
import {
  getSurveyStatus,
  insertSurvey,
  resetSurveyState,
  getSurveyOptions,
  getSurveyList,
} from '../../../src/features/survey/surveyState';
import {
  customerInfo,
  resetUserCheckoutProgress,
} from '../../../src/features/user/userState';
import {
  defaultSurveyOptions,
  rfSurveyOptions,
  sleepSurveyOptions,
} from '../support/forms/shared/FormsData';
import styles from './OrderSuccessPage.module.scss';
import SurveyModal from './SurveyModalTemplate/SurveyModal';
import { getoldOrderedConfirmData } from '../../../src/features/survey/surveyState';
import {
  productsKeyConstants,
  config,
  ProductsCategory,
} from '../../../config';
import { getUrlParamValue } from '../../../commons/utils/utils';
import { SUPPORT_PHONENO } from '../../../commons/Constants';
import TWITTER_ICON from '../../../src/images/twitter_x.svg';
import { WarningAccordion } from '../../../commons/hoc/AlertMessage/WarningAccordion';
export default function OrderSuccessPage(props) {
  const router = useRouter();
  const orderId = props.orderId || getUrlParamValue('orderid', router.asPath);
  const dispatch = useAppDispatch();
  const oldOrderedConfirmData = useAppSelector(getoldOrderedConfirmData);
  const incrementId = useAppSelector(getOrderId);
  const isEmailWarning = useAppSelector(getEmailWarningValue);
  const showSurvey = useAppSelector(getSurveyStatus);
  const user = useAppSelector(customerInfo);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [surveyTitle, setSurveyTitle] = useState(
    'How did you hear about Relief Factor?'
  );
  let surveyoption = useAppSelector(getSurveyList);

  useEffect(() => {
    let optionsArr = [];
    let first, last;
    optionsArr = [...surveyoption];
    first = optionsArr.shift();
    last = optionsArr.pop();
    optionsArr = optionsArr.sort(() => Math.random() - 0.5);
    optionsArr.push(last);
    optionsArr.unshift(first);
    setSurveyOptions(optionsArr);
    dispatch(resetUserCheckoutProgress());
    dispatch(resetQuote());
    return () => {
      dispatch(removeOldOrder());
      dispatch(resetSurveyState());
    };
  }, []);

  useEffect(() => {
    if (!orderId || orderId != incrementId) {
      router.push('/');
    }
  }, []);

  const hasRfProduct = () =>
    oldOrderedConfirmData?.some((res) =>
      [
        productsKeyConstants.RF_RELIEF_FACTOR_QS,
        productsKeyConstants.RF_RELIEF_FACTOR_60_CT,
      ].includes(res?.id)
    );

  const hasSleepProduct = () => {
    for (const [key, value] of Object.entries(oldOrderedConfirmData)) {
      if (productsKeyConstants.RF_Z_FACTOR === value.id) return true;
    }
  };

  const handleSubmit = (data) => {
    dispatch(insertSurvey({ orderId, optionsSelected: data }));
  };

  return (
    <div>
      {orderId && orderId == incrementId && (
        <>
          <div className={styles.orderSuccessPageMain}>
            {!isEmailWarning && (
              <div className={styles.warningChildContainer}>
                <WarningAccordion
                  title={'Important Notice: Invalid Email Address'}
                >
                  <div className={styles.WarningAccordionDetailsContent}>
                    <Typography variant="p">
                      The email you provided is invalid. We aren’t able to send
                      you important emails about your order and account. Please
                      email{' '}
                    </Typography>
                    <span className={styles.warningContact}>
                      <Link href="mailto:contactus@relieffactor.com">
                        contactus@relieffactor.com
                      </Link>
                    </span>{' '}
                    <Typography variant="p">or call </Typography>
                    <span className={styles.warningContact}>
                      <Link href={`tel:+1-${SUPPORT_PHONENO}`}>
                        {SUPPORT_PHONENO}
                      </Link>{' '}
                      and we can help you fix the issue.
                    </span>
                  </div>
                </WarningAccordion>
              </div>
            )}
            <div className={styles.orderStatus}>
              <h1 className={`${styles.header} ${styles.blue}`}>
                Thank You for Your Purchase!
              </h1>
            </div>
            <div
              className={`${styles.finishedOrder} ${styles.finishedOrderFirstSec}`}
            >
              <h2
                className={`${styles.header} ${styles.blue} ${styles.noMarginTop}`}
              >
                Order Confirmed!
              </h2>
              <p className={`${styles.noMarginBottom}`}>
                Your order # is: <strong>{orderId || ''}</strong>
              </p>
              <p className={`${styles.noMarginBottom}`}>
                Please check your email for an order confirmation. Once a
                tracking number is generated a 2nd email will be sent with
                details about your order, including a link to track the progress
                of your package.
              </p>
            </div>
            {/* {oldOrderedConfirmData?.length > 0
              ? oldOrderedConfirmData?.map((each) => {
                  return (
                    <div
                      className={`${styles.finishedOrder} ${styles.videoContainerBox}`}
                    >
                      <h2
                        className={`${styles.header} ${styles.blue} ${styles.noMarginTop}`}
                      >
                        {each.heading}
                      </h2>

                      {each.VideoURL && (
                        <div className={styles.videoContainer}>
                          <div className={styles.wrapper}>
                            <iframe
                              className={styles.video}
                              src={each.VideoURL}
                              frameBorder="0"
                              allowFullScreen
                              access-control-allow-origin="*"
                            />
                          </div>
                        </div>
                      )}

                      <p className={`${styles.noMarginBottom}`}>
                        {each.content}
                      </p>
                      <p
                        className={`${styles.noMarginBottom} ${styles.subContent}`}
                      >
                        {each.subContent}
                      </p>
                      {each.id === productsKeyConstants.RF_Z_FACTOR && (
                        <p
                          className={`${styles.noMarginBottom} ${styles.subContent}`}
                        >
                          You may cancel or adjust shipment frequency any time
                          before your next order. Simply
                          <a href={`${config.SiteURL}account`}>
                            {` log in to your account`}
                          </a>
                          , submit a
                          <a href={`${config.SiteURL}support`}>
                            {' '}
                            support request
                          </a>
                          , call us at
                          <a
                            href="tel:+1-833-888-1533"
                            title="tel:833-888-1533"
                          >
                            {' '}
                            833-888-1533
                          </a>
                          , or email us at
                          <a href="mailto:contactus@relieffactor.com">
                            {' '}
                            contactus@relieffactor.com
                          </a>
                          !
                        </p>
                      )}
                    </div>
                  );
                })
              : ' '} */}
            <div className={`${styles.socialContainer}`}>
              <h2 className={`${styles.header} ${styles.blue}`}>
                Share Your Experience!
              </h2>
              <div className={`${styles.buttonsContainer}`}>
                <Link href="https://www.facebook.com/sharer.php?">
                  <a target="_blank">
                    <ContainedButton className={styles.socialMediaButton}>
                      <div className={styles.socialButtonContainer}>
                        <div>Share</div>
                        <div className={styles.socialIconFb}>
                          <svg
                            width="17.45"
                            height="18"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 17.45 34.23"
                          >
                            <g data-name="icon-facebook-icon/social/facebook">
                              <path
                                fillRule="evenodd"
                                fill="#ffffff"
                                d="M11.33,34.23V18.63H16.5l.77-6.1H11.33V8.65c0-1.77.49-3,3-3h3.15V.24A42.05,42.05,0,0,0,12.83,0C8.27,0,5.14,2.84,5.14,8v4.48H0v6.11H5.14v15.6Z"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </ContainedButton>
                  </a>
                </Link>
                <Link href="https://twitter.com/relieffactor">
                  <a target="_blank">
                    <ContainedButton className={styles.socialMediaButton}>
                      <div className={styles.socialButtonContainer}>
                        <div>Post to</div>
                        <div className={styles.socialIcon}>
                          <TWITTER_ICON fill="#ffffff" height="20" />
                        </div>
                      </div>
                    </ContainedButton>
                  </a>
                </Link>
              </div>
            </div>
            <div className={styles.needHelp}>
              <div>
                <div>
                  <h2 className={`${styles.header} ${styles.blue}`}>
                    Need Help?
                  </h2>
                </div>
                <div className={`${styles.contactUsContainer}`}>
                  <p>
                    To make adjustments to your subscription at any time simply
                    login to “My Account” above or contact us directly with any
                    questions or issues:{' '}
                  </p>
                  <p>
                    <span className={styles.contactLinks}>
                      Phone -{' '}
                      <a
                        className={styles.orderNumber}
                        href={`tel:+1-${SUPPORT_PHONENO}`}
                      >
                        {SUPPORT_PHONENO}
                      </a>{' '}
                    </span>
                    <br />
                    <span className={styles.contactLinks}>
                      Email -{' '}
                      <a href="mailto:contactus@relieffactor.com">
                        {' '}
                        contactus@relieffactor.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.continueShoppingContainer}>
              <Link href={'/'}>
                {/* <a className={`${styles.continueShoppingButton}`}>
                  Continue Shopping
                </a> */}
                <ContainedButton className={styles.continueBtn}>
                  Continue Shopping
                </ContainedButton>
              </Link>
            </div>
          </div>
          <SurveyModal
            list={surveyOptions}
            showSurvey={showSurvey}
            submitSelectedOptions={handleSubmit}
            title={surveyTitle}
          />
        </>
      )}
    </div>
  );
}

export const SUCCESSMESSAGE_PRODUCT = [
  {
    id: productsKeyConstants.RF_RELIEF_FACTOR_QS,
    heading: "What's Next?",
    VideoURL: 'https://player.vimeo.com/video/246021584',
    content: `Your QuickStart package should be received in 3-4 days from when your order ships (orders placed on Friday, Saturday & Sunday will ship out Monday).
  
  `,
    subContent:
      '3 Weeks after the QuickStart is ordered your 60 count package of Relief Factor will process and your card will be charged $ 79.95 plus $6.95 S&H.',
    experienceContent: `I just bought Relief Factor, a 100% botanical and fish oil research-based formula, that was created to help our bodies maintain a healthy inflammatory response.`,
    category: ProductsCategory.RELIEF_FACTOR,
  },
  {
    id: productsKeyConstants.RF_RELIEF_FACTOR_60_CT,
    heading: "What's Next?",
    VideoURL: 'https://player.vimeo.com/video/246021584',
    content: `Your QuickStart package should be received in 3-4 days from when your order ships (orders placed on Friday, Saturday & Sunday will ship out Monday).
    `,
    subContent:
      ' 3 Weeks after the QuickStart is ordered your 60 count package of Relief Factor will process and your card will be charged $ 79.95 plus $6.95 S&H',
    experienceContent: ``,
    category: ProductsCategory.RELIEF_FACTOR,
  },
  {
    id: productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE,
    heading: '',
    content: '',
    subContent: '',
    experienceContent: ``,
    category: ProductsCategory.RELIEF_FACTOR,
  },
  {
    id: productsKeyConstants.RF_ZEN_FACTOR,
    heading: "What's Next?",
    content: `Your shipment of Zen Factor should be received 3-4 days after your order ships. Orders placed on Friday, Saturday, or Sunday will ship out on Monday.
    
    `,
    subContent:
      '25 days after your first order of Zen Factor is processed, your subscription will begin, and your card will be charged $21.95.',
    experienceContent: `I just bought Zen Factor, a botanical formula crafted by doctors to help my body manage its stress response.`,
    category: ProductsCategory.ZEN_FACTOR,
  },
  {
    id: productsKeyConstants.RF_GO_FACTOR,
    heading: "What's Next?",
    content: `Your shipment of Go Factor should be received 3-4 days after your order ships. Orders placed on Friday, Saturday, or Sunday will ship out on Monday.`,
    subContent: '',
    experienceContent: `I just bought Go Factor, a formula crafted by doctors to help my body get the energy it needs.`,
    category: ProductsCategory.GO_FACTOR,
  },
  {
    id: productsKeyConstants.RF_Z_FACTOR,
    heading: "What's Next?",
    content: `Once your package ships we will send an email with a link to track your order. `,
    subContent:
      'You will continue to receive a 60 count bottle of Z Factor on a monthly basis at a cost of $29.95 plus applicable sales tax.',
    experienceContent: '',
    category: ProductsCategory.Z_FACTOR,
  },
];
