import { config } from '../../../config';
const resourceApiCall = ({ endpoint, options, version = 'v2' }) => {
  options.headers['Authorization'] = `Basic ${config.resources.token}`;
  return new Promise(function (resolve, reject) {
    fetch(`${config.resources.base_url}/${version}/${endpoint}`, options)
      .then((response) => {
        return response.json().then((result) => {
          // Success
          if (response.ok) resolve(result);
          // Error
          else reject(result);
        });
      })
      .catch((error) => {
        // Error
        reject(error);
      });
  });
};

export default resourceApiCall;
