import React, { useRef, useState, useEffect } from 'react';
import styles from './MiniDrawer.module.scss';
//mui
import { Drawer, Typography, IconButton } from '@mui/material';
import { ContainedButton } from '../../../../commons/hoc';
import CartProductCard from '../../../../commons/components/ui/Cards/CartProductCard/CartProductCard';
import CloseIcon from './CloseIcon';
import { useOutsideClick } from '../../../../commons/hooks';
import { useAppDispatch, useAppSelector } from '../../../../src/app/hooks';
import {
  addItemToCart,
  getCartItems,
  getSubtotal,
  removeItemFromCart,
} from '../../../../src/features/cart/cartState';
import {
  productMappedCategory,
  productUpgradeMapping,
} from '../../../../config/products';
import { productImages } from '../../../../src/images';
import CartEmpty from '../../../../src/pages/checkout/cartempty';
import BrokenCart from '../BrokenCart/BrokenCart';

import {
  getUI,
  isCartOpen,
  showCart,
} from '../../../../src/features/ui/uiState';
import Router from 'next/router';
import { getProducts } from '../../../../commons/utils/productsHelper';
import {
  GA4MiniCart,
  GA4RemoveCart,
} from '../../../../commons/helpers/Analytics/googleAnalytics';
import { FBAddToCart } from '../../../../commons/helpers/Analytics/fbPixel';
import { vwoHelper } from '../../../../commons/helpers/Analytics/vwo/vwoHelper';
import cookie from 'cookie';
import { config } from '../../../../config';

export default function MiniCartDrawer() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const cartItems = useAppSelector(getCartItems);
  const subTotal = useAppSelector(getSubtotal);
  const [showQuantityInfo, setShowQuantityInfo] = useState({
    id: null,
    status: false,
  });
  const CounterButtonRef = useRef(null);
  const UpgradeModalRef = useRef(null);
  const cartOpen = useAppSelector(isCartOpen);
  const { cartLoading, brokenCart, quantityWarning } = useAppSelector(getUI);
  const [infoProduct, setInfoProduct] = useState(null);
  const products = getProducts();
  useEffect(() => {
    if (cartOpen) {
      document.body.classList.add('overflowHidden');
    } else {
      document.body.classList.remove('overflowHidden');
    }
  }, [cartOpen]);

  useOutsideClick(UpgradeModalRef, () => {
    if (open) setOpen(false);
  });

  useEffect(() => {
    setShowQuantityInfo(quantityWarning);
  }, [quantityWarning]);

  const updateQuantity = (item, id, increase = true) => {
    const payload = {
      productId: id,
      data: {
        ...item,
        quantity: increase ? item.quantity + 1 : item.quantity - 1,
      },
    };

    dispatch(addItemToCart(payload));

    var productKey = item.key;
    var selectedProduct = products[productKey];
    const isSubscription = selectedProduct.isSubscription;
    var qtyUpdate = increase ? item.quantity + 1 : item.quantity - 1;
    var qtyChange = increase ? 'addQty' : 'subQty';
    var flag = false;
    if (selectedProduct.category == 'ZFA') {
      flag = true;
    }
    GA4MiniCart(0, selectedProduct, qtyUpdate, qtyChange);
    FBAddToCart(selectedProduct, isSubscription, qtyUpdate, flag);
  };

  const handleUpgradeClick = (item, id) => {
    let upgradedProduct = productUpgradeMapping[item.key];
    const payload = {
      productId: id,
      data: {
        ...item,
        ...productUpgradeMapping[item.key],
        quantity:
          item.quantity > products[upgradedProduct.key].maxQty
            ? products[upgradedProduct.key].maxQty
            : item.quantity,
      },
    };
    dispatch(addItemToCart(payload));

    var selectedProduct = products[upgradedProduct.key];
    const isSubscription = selectedProduct.isSubscription;
    var qtyUpdate = 0;
    var flag = false;
    if (selectedProduct.category == 'ZFA') {
      flag = true;
    }
    GA4MiniCart(0, selectedProduct, qtyUpdate, 'upgradeClick');
    FBAddToCart(selectedProduct, isSubscription, qtyUpdate, flag);
  };

  const handleRemoveClick = (item) => {
    var productKey = item.key;
    var removedProduct = products[productKey];
    const isSubscription = removedProduct.isSubscription;
    GA4RemoveCart(0, removedProduct, isSubscription);
    dispatch(
      removeItemFromCart({
        itemId: item.id,
        productId: item.productId,
      })
    );
  };

  const handleInfoClicked = (item) => {
    setInfoProduct(item.key);
    setOpen(true);
  };

  const lastCheck = (i) => {
    if (i == Object.keys(cartItems).length - 1) {
      return true;
    }
    return false;
  };

  const checkCategory = (category) => {
    return productMappedCategory[category];
  };

  const isShowQuantityInfo = (key, qty) => {
    if (products[key]?.maxQty == qty) {
      return true;
    }
    return false;
  };

  const closeDrawer = () => {
    dispatch(showCart(false));
  };

  const onCheckoutHandler = () => {
    try {
      const userId = cookie.parse(document.cookie).vwo_uid;
      Object.values(cartItems).forEach((item) => {
        if (item.key.includes('REL')) {
          const selectedProduct = products[item.key];
          const options = {
            eventProperties: {
              'product-id': selectedProduct.productId,
              'product-name': selectedProduct.productName,
              'sku': selectedProduct.sku,
              'price': selectedProduct.recurringPrice,
              'quantity': item.quantity,
            },
          };
          vwoHelper.vwoClientInstance.track(
            config.vwo.campaignKey,
            userId,
            'checkout-button-clicked',
            options
          );
        }
      });
    } catch (err) {
      console.log('VWO Error', err);
    }
    dispatch(showCart(false));
    Router.push('/checkout');
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={cartOpen}
        onClose={() => closeDrawer()}
        ModalProps={{
          className: 'miniCartModal',
        }}
        sx={(theme) => ({
          [`& .MuiBackdrop-root`]: {
            backgroundColor: 'rgb(0, 65, 82, 0.3)',
          },
        })}
      >
        {/* Upgrade info modal */}
        {open && (
          <div className={styles.upgradeModalWrapper}>
            <div className={styles.upgradeModalContent}>
              <div
                className={styles.upgradeModalMainContent}
                ref={UpgradeModalRef}
              >
                <div className={styles.upgradeModalTitle}>
                  <div className={styles.upgradeTitle}>
                    SUBSCRIPTION TERMS & CONDITIONS
                  </div>
                  <div className={styles.upgradeModalClose}>
                    <IconButton
                      className={styles.upgradeModalCloseIcon}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className={styles.upgradeContent}>
                  <div className={styles.upgradeContentTitle}>
                    Monthly Subscription Plan:
                  </div>
                  <div
                    className={styles.upgradeContentDesc}
                    dangerouslySetInnerHTML={{
                      __html: products[infoProduct]?.disclaimer,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.cartBgWrapper}>
          <div className={styles.cartWrapper}>
            <div className={styles.cartMainContainer}>
              <div className={styles.cartTitleSection}>
                <div>
                  <Typography variant="h3" color="primary">
                    Your Cart
                  </Typography>
                </div>
                <div>
                  <IconButton onClick={() => closeDrawer()}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              {brokenCart ? (
                <div className={styles.emptyCartContainer}>
                  <BrokenCart />
                </div>
              ) : Object.keys(cartItems)?.length > 0 ? (
                <div className={styles.cartProductListWrapper}>
                  <div className={styles.cartProductListContainer}>
                    {Object.entries(cartItems)?.map(([id, item], i) => {
                      let key = item.key;
                      return (
                        <div className={styles.cartPoductCard} key={key}>
                          <CartProductCard
                            item={item}
                            counterRef={CounterButtonRef}
                            handleIncrementCounter={() =>
                              updateQuantity(item, id)
                            }
                            handleDecrementCounter={() =>
                              updateQuantity(item, id, false)
                            }
                            handleUpgrade={() => handleUpgradeClick(item, id)}
                            handleRemove={() => handleRemoveClick(item)}
                            category={checkCategory(item.category)}
                            handleInfo={() => handleInfoClicked(item)}
                            isLast={lastCheck(i)}
                            imgData={productImages[key]}
                            productName={products[key]?.productName}
                            showQuantityInfo={showQuantityInfo}
                            quantityInfoMsg={products[key]?.quantityInfoMsg}
                            incrementDisabled={isShowQuantityInfo(
                              key,
                              item.quantity
                            )}
                            decrementDisabled={false}
                            qty={item.quantity}
                            product={products[item.key]}
                            cartLoading={cartLoading}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className={styles.emptyCartContainer}>
                  <CartEmpty />
                </div>
              )}
            </div>
            {Object.keys(cartItems)?.length > 0 && !brokenCart && (
              <div className={styles.cartBtnWrapper}>
                <div className={styles.cartBtnContainer}>
                  <div className={styles.cartItemsTotal}>
                    <div>
                      <Typography variant="h5" color="primary.black">
                        Subtotal
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h5" color="primary.black">
                        ${subTotal}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <ContainedButton
                      className={styles.cartCheckoutBtn}
                      onClick={() => {
                        onCheckoutHandler();
                      }}
                      disabled={cartLoading}
                      isLoading={cartLoading}
                    >
                      Checkout
                    </ContainedButton>
                  </div>
                  <div className={styles.cartCheckoutInfo}>
                    Shipping + taxes calculated at checkout
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}
