export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    '@media (min-width:320px)': {
      fontSize: pxToRem(xs),
    },
    '@media (min-width:375px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:768px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1024px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = ['Whitney Semibold', 'Helvetica'].join(',');
const FONT_SECONDARY = ['Whitney', 'Helvetica'].join(',');
const FONT_BOOK = 'Whitney Book';
const typography = {
  'h1': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 32, sm: 40, md: 40, lg: 55 }),
  },
  'h2': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 26, sm: 32, md: 32, lg: 38 }),
  },
  'h3': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 16, sm: 20, md: 20, lg: 26 }),
  },
  'h4': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '125%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 14, sm: 17, md: 17, lg: 20 }),
  },
  'h5': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '125%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 12, sm: 15, md: 15, lg: 15 }),
  },
  'p1': {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 18, sm: 18, md: 18, lg: 18 }),
  },
  'p1-book': {
    fontFamily: FONT_BOOK,
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 18, sm: 18, md: 18, lg: 18 }),
  },
  'p2': {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 18, sm: 18, md: 18, lg: 18 }),
  },
  'p2-book': {
    fontFamily: FONT_BOOK,
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 18, sm: 18, md: 18, lg: 18 }),
  },
  'p3': {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 16, sm: 16, md: 16, lg: 16 }),
  },
  'article': {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: '140%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 13, sm: 17, md: 17, lg: 20 }),
  },
  'button': {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: '125%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 12, sm: 15, md: 15, lg: 15 }),
  },
  'button1': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '125%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 12, sm: 15, md: 15, lg: 15 }),
  },
  'label': {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '125%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 12, sm: 15, md: 15, lg: 15 }),
  },
  'alert': {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: '125%',
    letterSpacing: 0,
    ...responsiveFontSizes({ xs: 13, sm: 13, md: 13, lg: 13 }),
  },
};

export default typography;
