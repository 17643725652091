import styles from './Rating.module.scss';
import { Box } from '@material-ui/core';
import { Rating } from '@material-ui/lab/';

const RatingComponent = ({ text, count, ...rest }) => {
  return (
    <Box component="div" className={styles.ratingContainer}>
      <Rating {...rest} /> {count && !text && <span>{'(' + count + ')'}</span>}
      {text && <p className={styles.ratingText}>{text}</p>}
    </Box>
  );
};

export default RatingComponent;
