import styles from './Accordin.module.scss';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: (props) =>
      props?.heading?.fontSize
        ? props?.heading?.fontSize
        : theme.typography.pxToRem(15),
    fontWeight: 'bold',
    padding: (props) =>
      props?.heading?.padding ? props?.heading?.padding : '0px',
  },
  details: {
    backgroundColor: '#fff8ea',
    padding: '2rem',
  },
}));

const Accordin = ({ data = [], defaultExpanded = 'panel0', ...props }) => {
  const classes = useStyles(props);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return isMounted ? (
    <div className={classes.root}>
      {data.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          {...item?.props}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography color="primary" className={classes.heading}>
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography>{item.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  ) : (
    <></>
  );
};

export default Accordin;
