import NextLink from 'next/link';

const Link = ({
  children,
  className = '',
  onClickHandler = () => {},
  ...rest
}) => {
  return (
    <NextLink {...rest}>
      <a onClick={onClickHandler} className={className}>
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
